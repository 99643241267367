import React, { useContext, useEffect, useState } from "react";
import { DataLoaded } from "../../../app/App";
import Connection from "../Connection/connection";
import ChatRoom from "./ChatRoom";
import Chatuser from "./Chatuser";
import moment from "moment";
import "./Faq.scss";
import NoAnswer from "../../../_metronic/_assets/Images/no-answer.svg";
import Loader from "react-loader-spinner";

import { useHistory, useLocation } from "react-router-dom";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";

function Messages() {
  const { answerData, RoomData, IdAnswer, DataFun } = useContext(DataLoaded);
  const [answer, setAnswer] = answerData;
  const [ChatRoomData, setChatRoomData] = RoomData;
  const [idForAnswer, setIdForAnswer] = IdAnswer;
  const [DateFunctions, setDateFunctions] = DataFun;
  const [clientId, setClientId] = useState("");
  const [ClientData, setClientData] = useState("");

  // search
  const [searchValue, setSearchValue] = useState();
  const [items, setItems] = useState([]);
  const [userReport, setUserReport] = useState();
  const [answerRooms, setAnswerRooms] = useState([]);
  const [roomId, setRoomId] = useState();
  // connection Data
  const [answers, setAnswers] = useState([]);
  const [messageloader, setMessageLoader] = useState(false);
  const [answerloader, setAnswerLoader] = useState(false);
  const history = useHistory();
  const chatWindowRef = React.useRef(null);
  const QuestionData = useLocation();
  console.log("QuestionData", QuestionData);
  // const {
  //   // answerRooms,
  //   // answers,
  //   // setAnswers,
  //   // setAnswerRooms,
  //   sendAnswer,
  //   answerloader,
  //   messageloader,
  // } = Connection(idForAnswer, DateFunctions, setUserReport);
  const HandleChanges = (data) => {
    console.log("HandleChanges", data);
    setRoomId(data);
    setClientData(data);
    setIdForAnswer(data?._id);
    setClientId(data?.createdBy?._id);
  };

  const GetAnswerByRoom = async () => {
    setMessageLoader(true);
    await ApiGet(
      `answer/answer-room-admin/id=${QuestionData?.state?.QuestionId}`
    )
      .then((res) => {
        console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS", res.data.payload.room);
        // setAnswer(res?.data?.data);
        setAnswerRooms(res.data.payload.room);
        setMessageLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  console.log("QuestionData", QuestionData);

  const GetAnswer = async () => {
    setAnswerLoader(true);
    const Data = {
      roomId: roomId?._id,
      isGroup: roomId?.isGroup,
    };
    await ApiPost(
      `answer/get-answer-by-admin/id=${QuestionData?.state?.QuestionId}`,
      Data
    )
      .then((res) => {
        console.log("FFFFFFFDDDDDDDDDDDDDDDDD", res);
        setAnswers(res?.data.payload.answer.reverse());
        setAnswerLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (QuestionData?.state?.QuestionId) {
      GetAnswerByRoom();
    }
  }, [QuestionData?.state?.QuestionId]);

  useEffect(() => {
    if (roomId) {
      GetAnswer();
    }
  }, [roomId]);

  const scrollToBottom = () => {
    if (chatWindowRef.current && answerloader == false) {
      setTimeout(() => {
        chatWindowRef.current.scrollTop = chatWindowRef?.current?.scrollHeight;
      }, 500);
      //   // chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [answers, !answerloader]);

  const [Answervalue, setAnswervalue] = useState("");

  let userData = JSON.parse(localStorage.getItem("userinfo"));

  const handleSearch = (e) => {
    console.log("eeeee", userReport);
    var value = e.target.value.toLowerCase();
    setSearchValue(value);
    setAnswerRooms(() =>
      userReport?.filter(
        (item) => item?.createdBy?.currentRole.toLowerCase().includes(value)
        // item?.status?.toLowerCase().includes(value)
        // item?.response?.toLowerCase().includes(value) ||
        // item?.view?.toLowerCase().includes(value) ||
        // item?.createdBy?.name?.toLowerCase().includes(value)
        // item?.phone?.toLowerCase().includes(value)
      )
    );
  };

  const PushOrigin = () => {
    history.push("/faq");
  };

  const handleScroll = (e) => {
    console.log("e", e.target.scrollTop);
    if (e.target.scrollTop == 0) {
      console.log("scrolling");
    }
  };

  return (
    <>
      <div className="back-left" onClick={() => PushOrigin()}>
        Back
      </div>
      <div className="full-sw">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card chat-app">
              <div id="plist" className="people-list">
                {/* <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                  />
                </div> */}
                <ul className="list-unstyled chat-list mt-2 mb-0">
                  {messageloader && answerRooms.length != 0 && (
                    <div className="d-flex justify-content-center">
                      <Loader
                        type="Puff"
                        color="#334D52"
                        height={30}
                        width={30}
                      />
                    </div>
                  )}
                  {/* {answerRooms.length && !messageloader   && ( 
                      <h3>dsfsfdfdsfdsaf</h3>

                 )} */}

                  {console.log("answerRooms", answerRooms)}

                  {answerRooms?.length != 0 &&
                    answerRooms?.map((data) => {
                      let FilterData;
                      if (data?.isGroup == false) {
                        FilterData = data?.participateIds
                          ?.map((item) => {
                            if (
                              item?._id?._id !=
                                QuestionData?.state?.QuestionId &&
                              item?._id?._id !=
                                QuestionData?.state?.QuestionData?.createdBy._id
                            ) {
                              return item;
                            }
                          })
                          .filter(
                            (item) =>
                              item != undefined &&
                              item != null &&
                              item?._id?.subject
                          );

                        console.log("FilterDataFilterData", FilterData);
                      }

                      return (
                        <>
                          <li
                            onClick={() => HandleChanges(data)}
                            className={`${
                              data?._id === ClientData?._id
                                ? "clearfix active"
                                : "clearfix"
                            }`}
                          >
                            {data?.isGroup ? (
                              <>
                                <div className="three-image-content-new">
                                  <div className="two-image">
                                    <img
                                      src={
                                        data.participateIds[0]?._id
                                          ?.profileImage
                                      }
                                      alt="avatar"
                                    />
                                    <img
                                      src={
                                        data.participateIds[1]?._id
                                          ?.profileImage
                                      }
                                      alt="avatar"
                                    />
                                  </div>
                                  <div className="one-image">
                                    <img
                                      src={
                                        data.participateIds[2]?._id
                                          ?.profileImage
                                      }
                                      alt="avatar"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <img
                                  src={FilterData[0]?._id?.profileImage}
                                  alt="avatar"
                                />
                              </>
                            )}

                            <div className="about">
                              <div className="name">
                                {/* {data.createdBy.currentRole} */}
                                {data?.isGroup ? (
                                  <>{data?.createdBy?.currentRole}</>
                                ) : (
                                  <>{FilterData[0]?._id?.currentRole}</>
                                )}

                                {/* {data.createdBy?.subject &&
                                data.createdBy?.subject[1]} */}
                                {/* ... */}
                              </div>
                              <div className="status">
                                {" "}
                                {/* TOFDO */}
                                {data?.lastMessage?.answer?.substring(0, 15)}
                                {"..."}
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    })}
                </ul>
              </div>

              <div className="chat">
                <div className="chat-header clearfix">
                  <div className="row">
                    <div className="d-flex col-lg-10">
                      {ClientData && (
                        <>
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#view_info"
                          >
                            {console.log(
                              "ClientDataClientDataClientData",
                              ClientData
                            )}

                            {ClientData?.isGroup ? (
                              <>
                                <div className="three-image-content-new">
                                  <div className="two-image">
                                    <img
                                      src={
                                        ClientData?.participateIds[0]?._id
                                          ?.profileImage
                                      }
                                      alt="avatar"
                                    />
                                    <img
                                      src={
                                        ClientData?.participateIds[1]?._id
                                          ?.profileImage
                                      }
                                      alt="avatar"
                                    />
                                  </div>
                                  <div className="one-image">
                                    <img
                                      src={
                                        ClientData?.participateIds[2]?._id
                                          ?.profileImage
                                      }
                                      alt="avatar"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    ClientData?.participateIds
                                      ?.map((item) => {
                                        if (
                                          item?._id?._id !=
                                            QuestionData?.state?.QuestionId &&
                                          item?._id?._id !=
                                            QuestionData?.state?.QuestionData
                                              ?.createdBy._id
                                        ) {
                                          return item;
                                        }
                                      })
                                      .filter(
                                        (item) =>
                                          item != undefined &&
                                          item != null &&
                                          item?._id?.subject
                                      )[0]?._id?.profileImage
                                  }
                                  alt="avatar"
                                />
                              </>
                            )}
                          </a>

                          <div className="chat-about mt-3">
                            <h6 className="">
                              {ClientData?.isGroup ? (
                                <> {ClientData?.createdBy.currentRole}</>
                              ) : (
                                <>
                                  {
                                    ClientData?.participateIds
                                      ?.map((item) => {
                                        if (
                                          item?._id?._id !=
                                            QuestionData?.state?.QuestionId &&
                                          item?._id?._id !=
                                            QuestionData?.state?.QuestionData
                                              ?.createdBy._id
                                        ) {
                                          return item;
                                        }
                                      })
                                      .filter(
                                        (item) =>
                                          item != undefined &&
                                          item != null &&
                                          item?._id?.subject
                                      )[0]?._id?.currentRole
                                  }
                                </>
                              )}

                              {/* {ClientData?.subject && ClientData?.currentRole},{" "}
                            {ClientData?.subject && ClientData?.subject[1]},{" "}
                            {ClientData?.subject && ClientData?.subject[2]},{" "}
                            {ClientData?.subject && ClientData?.subject[3]} */}
                            </h6>
                            {/* <small>Last seen: 2 hours ago</small> */}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {ClientData ? (
                  <>
                    {answerloader && answers?.length != 0 ? (
                      <div className="d-flex justify-content-center">
                        <Loader
                          type="Puff"
                          color="#334D52"
                          height={30}
                          width={30}
                        />
                      </div>
                    ) : (
                      <div
                        className="chat-history-alignment"
                        ref={chatWindowRef}
                        onScroll={handleScroll}
                      >
                        {answers?.length != 0 ? (
                          answers?.map((item, index) => {
                            if (
                              item?.createdBy?._id !=
                              QuestionData?.state.QuestionData.createdBy._id
                            ) {
                              return (
                                <>
                                  <div className="message-box-alignment flex-start">
                                    <div className="message-data text-right">
                                      <span className="message-data-time">
                                        {moment(item?.createdAt)
                                          .utc()
                                          .fromNow()}
                                      </span>
                                    </div>
                                    <div className="message other-message float-right">
                                      {item?.answer}.
                                    </div>
                                  </div>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <div className="message-box-alignment flex-end">
                                    <div className="message-data text-right">
                                      <span className="message-data-time">
                                        {moment(item?.createdAt)
                                          .utc()
                                          .fromNow()}
                                      </span>
                                    </div>
                                    <div className="message other-message float-right">
                                      {item?.answer}.
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          })
                        ) : (
                          <div className="center-align-image-no-data">
                            <div className="no-answer-alignment">
                              <div className="no-answer-image-center">
                                <img src={NoAnswer} alt="img" />
                              </div>
                              <p>You have not received any responses</p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="center-align-image-no-data">
                    <div className="no-answer-alignment">
                      <div className="no-answer-image-center">
                        <img src={NoAnswer} alt="img" />
                      </div>
                      <p>You have not received any responses</p>
                    </div>
                  </div>
                )}

                {/* {ClientData && (
                <div className="chat-message clearfix">
                  <div className="input-group mb-0">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-send"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter text here..."
                      value={Answervalue}
                      onChange={(e) => setAnswervalue(e.target.value)}
                      // onKeyPress={(e) => {
                      //   if (e.key == "Enter") {
                      //     if (Answervalue) {
                      //       sendAnswer(Answervalue);
                      //       setAnswervalue("");
                      //     }
                      //   }
                      // }}
                      disabled
                    />
                  </div>
                </div>
              )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Messages;
