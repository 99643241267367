import React, { useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DataTable, { defaultThemes } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import { Modal } from "react-bootstrap";
import { Dialog } from "@material-ui/core";
import { List } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Slide } from "@material-ui/core";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import moment from "moment";
import Profile from "./Profile";
import ReactTooltip from "react-tooltip";
import CsvDownload from "react-json-to-csv";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const organigationRoleList = [
  "Assistant Director",
  "Assistant Vice President",
  "Associate Vice President",
  "Chief Executive Officer",
  "Chief Financial Officer",
  "Chief Operating Officer",
  "Co-Founder",
  "Director",
  "Executive Vice President",
  "Founder",
  "Manager",
  "Partner",
  "President",
  "Product Manager",
  "Senior Director",
  "Senior Vice President",
  "Vice President",
  "Other",
];

// Initial state values

// Initial state values

const Role = (props) => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [pid, setPid] = useState();
  const [updateProduct, setUpdateProduct] = useState(false);
  const [submitValue, setSubmitValue] = useState(false);
  const [initData, setInitdata] = useState();
  const [pwdErr, setPwdErr] = useState("");
  const [status, setstatus] = useState();
  const [statusid, setstatusid] = useState();
  const [data, setData] = useState({});

  const [roleName, setRoleName] = useState();
  const [description, setDescription] = useState();
  const [active, setactive] = useState(true);

  const [roleNameUpdate, setRoleNameUpdate] = useState();
  const [roleUpdate, setRoleupdate] = useState();
  const [descriptionUpdate, setDescriptionUpdate] = useState();
  const [msg, setMsg] = useState("");

  const [updateDetails, setUpdateDetails] = useState(false);

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [Persondata, setPersondata] = useState();

  // const [errors] = useState([]);
  const [userReport, setUserReport] = useState();
  const [filteredReport, setFilteredReport] = useState();
  const [UserEmail, setUserEmail] = useState();
  const [errors, setErrors] = useState({});
  let userInfo = getUserInfo();
  // console.log(userInfo.user.id);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [donationCsvAdmin, setDonationCsvAdmin] = useState([]);
  const [dataCSVAdmin, setDataCSVAdmin] = useState([]);
  const [userId, setUserId] = useState();
  const [Status, setStatus] = useState();

  // AzureId: 39
  // himanshu@johnbehrgroup.com
  const toBeDeletedUserDetailsInit = {
    userId: null,
    // loading state for the loading spinner
    userDeleteState: null,
    showUserDeleteModal: false,
    deleteUserByIdHandler,
    closeUserDeleteModal,
  };

  const [toBeDeletedUserDetails, setToBeDeletedUserDetails] = useState(
    toBeDeletedUserDetailsInit
  );
  // AzureId: 39
  // himanshu@johnbehrgroup.com

  useEffect(() => {
    if (donationCsvAdmin) {
      donationCsvAdmin.map((donation) => {
        let data = {
          Email: donation?.email,
          Name: donation?.name,
          Date: moment(donation?.createdAt).utc().format("MMM Do YYYY, h:mm a"),
          Role: donation?.currentRole,
          QuestionCount: donation?.questionCount,
          AnswerCount: donation?.answerCount,
          Gender: donation?.gender,
          HowDidFind: donation?.howDidFind,
          LinkedinProfile: donation?.linkedinProfile,
          OrganizationName: donation?.organizationName,
          OrganizationWebsite: donation?.organizationWebsite,
          ProfileImage: donation?.profileImage,
          Region: donation?.region,
          // subject: donation.subject,

          LastModified: moment(donation?.updatedAt)
            .utc()
            .local()
            .format("MMM Do YYYY, h:mm a"),
          Status:
            donation?.status == true
              ? "Active"
                ? donation?.status == false
                : "Deactive"
              : "",
        };
        setDataCSVAdmin((currVal) => [...currVal, data]);
      });
    }
    console.log("UsertCsvReport", donationCsvAdmin);
  }, [donationCsvAdmin]);

  const handleClickOpen = () => {
    setOpen(true);
    setSubmitValue(false);
    setUpdateProduct(true);
  };

  const handleClose1 = () => {
    setOpen(false);
    setErrors({});
  };

  const [profile, setProfile] = useState(false);

  const handleProfileClose = () => {
    setProfile(false);
    setErrors({});
  };

  const handleClose = () => {
    setShow(false);
    setErrors({});
  };

  const handleClose3 = () => {
    setShow1(false);
    setStatus();
    setUserId();
    setErrors({});
  };

  const handleShow = (id) => {
    setShow(true);
    setPid(id);
  };

  useEffect(() => {
    rolegetApi();
  }, [dataChange]);

  const rolegetApi = async () => {
    setIsLoaderVisible(true);
    await ApiGet("user/all-users")
      .then((res) => {
        setRoleData(res.data.payload?.users);
        setFilteredReport(res.data.payload?.users);
        setUserReport(res.data.payload?.users);
        setDonationCsvAdmin(res.data.payload.users);
        setIsLoaderVisible(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoaderVisible(false);
      });
  };

  const handleDeleteManagerInfo = async () => {
    await ApiDelete(`role/id=${pid}`)
      .then((res) => {
        setDataChange([...dataChange, "Payment delete"]);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const handleForUpdateManager = async (id) => {
    setOpen(true);
    setPid(id);
    setSubmitValue(true);
    let temp = roleData.filter((data) => {
      return data._id === id;
    });
    setRoleNameUpdate(temp[0].roleName);
    setDescriptionUpdate(temp[0].description);
  };

  const handelUpdateDetails = async () => {
    if (!formUpdateValidator()) {
      console.log("ERROR PUT", errors);
      return;
    }

    setLoading(true);

    let data = {
      name: roleNameUpdate,
      phone: descriptionUpdate,
      email: UserEmail,
      currentRole: roleUpdate,
    };

    await ApiPut(`user`, data)
      .then((res) => {
        console.log("dd", res.data.payload.updateByAdmin);
        setData(res.data.payload.updateByAdmin);
        setOpen(false);
        setErrors({});
        setUpdateProduct(false);
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        toast.success("User updated successfully");

        // toast.success("Role Updated Successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handlealtermessage = (id, status) => {
    setShow(true);
    setstatus(status);
    setstatusid(id);
  };

  const [profileData, setProfileData] = useState();

  const ProfileFuntions = (data) => {
    console.log("DataLeader-Bridge", data?.message);
    if (data && data) {
      setProfileData(data);
      setProfile(true);
    }
  };

  const HandleFilter = (id, data) => {
    setShow1(true);
    setUserId(id);
    setStatus(data);
  };

  const handelUpdatestatusDetails = async () => {
    setLoading(true);
    await ApiPut(`user/update-status/id=${statusid}&status=${status}`)
      .then((res) => {
        setOpen(false);
        setErrors({});
        setUpdateProduct(false);
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        setShow(false);
        console.log(res.data.payload.updateUser.status, "payloaduser");
        toast.success(
          `User ${
            res.data.payload.updateUser.status == true
              ? "Activate"
              : "Deactivate"
          }d`
        );

        // toast.success("Role Updated Successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);

        console.log(err);
      });
  };

  const HandleChangeStaus = async () => {
    setLoading(true);
    await ApiPut(
      `user/verification-status-check-update/type=${Status}/id=${userId}`
    )
      .then((res) => {
        setErrors({});

        setLoading(false);
        setStatus();
        setUserId();
        setShow1(false);
        rolegetApi();

        // toast.success("Role Updated Successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);

        console.log(err);
      });
  };

  // AzureId: 39
  // himanshu@johnbehrgroup.com
  const deleteUserByIdHandler = async () => {
    if (toBeDeletedUserDetails?.userId) {
      setToBeDeletedUserDetails((toBeDeletedUserDetails) => {
        return {
          ...toBeDeletedUserDetails,
          userDeleteState: "loading",
        };
      });

      const deleteUserRes = await ApiDelete(
        `user/delete/id=${toBeDeletedUserDetails?.userId}`
      );

      if (deleteUserRes?.status === 200) {
        setFilteredReport((filteredReport) => {
          return filteredReport?.filter((user) => {
            return user?._id !== toBeDeletedUserDetails?.userId;
          });
        });
        toast.success(`User deleted successfully!`, { theme: "colored" });
      } else {
        toast.error(deleteUserRes?.message || `Some error occured`, {
          theme: "colored",
        });
      }

      setToBeDeletedUserDetails(() => {
        return {
          ...toBeDeletedUserDetailsInit,
          userDeleteState: "complete",
        };
      });
      console.log("deleteUserRes", deleteUserRes);
    }
  };

  const setupToBeDeletedUser = (userId) => {
    setToBeDeletedUserDetails((toBeDeletedUserDetails) => {
      return {
        ...toBeDeletedUserDetails,
        userId,
        showUserDeleteModal: true,
      };
    });
  };

  const closeUserDeleteModal = async () => {
    setToBeDeletedUserDetails(toBeDeletedUserDetailsInit);
  };
  // AzureId: 39
  // himanshu@johnbehrgroup.com

  let i = 0;
  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "5%",
    },
    {
      name: "Date",
      selector: "createdAt",
      cell: (row) => {
        // let d = new Date(row.createdAt);
        // let month = [
        //   "Jan",
        //   "Feb",
        //   "Mar",
        //   "Apr",
        //   "May",
        //   "Jun",
        //   "Jul",
        //   "Aug",
        //   "Sep",
        //   "Oct",
        //   "Nov",
        //   "Dec",
        // ];
        // let updatedate = ` ${
        //   month[d.getMonth()]
        // } ${d.getDate()},${d.getFullYear()} `;
        // return <div>{updatedate}</div>;
        return (
          <div>{moment(row.createdAt).utc().format("MMM Do YYYY, h:mm a")}</div>
        );
      },
      width: "210px",
      sortable: true,
    },
    {
      name: "Full Name",
      selector: "name",
      sortable: true,
      width: "140px",
    },
    {
      name: "Email",
      selector: "email",
      width: "300px",
      sortable: true,
    },
    {
      name: "Role",
      // selector: "currentRole",
      cell: (row) => {
        console.log("AAAAAAAAAAAAA", row);
        return (
          <>
            <div>{row?.currentRole ? row?.currentRole : "-"}</div>
          </>
        );
      },
      sortable: true,
      width: "200px",
    },

    {
      name: "Associate with",
      cell: (row) => {
        return (
          <>
            <div>
              {row?.user?.organizationName ? row?.user?.organizationName : "-"}
            </div>
          </>
        );
      },
      sortable: true,
      width: "160px",
    },

    {
      name: "Document",
      // style: (row) => ({
      //   backgroundColor:
      //     row?.verification[0]?.accept == true ? "#00FF00" : "#FF0000",
      // }),

      cell: (row) => {
        return (
          <>
            <div>
              {row?.verification?.length != 0 &&
                (row?.verification[0]?.accept == true
                  ? "Verified"
                  : "Not Verified")}

              {row?.verification?.length == 0 && "-"}
            </div>
          </>
        );
      },

      conditionalCellStyles: [
        {
          when: (row) => row?.verification[0]?.accept == true,
          style: {
            backgroundColor: "#098f09",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },

        {
          when: (row) => row?.verification[0]?.accept == false,
          style: {
            backgroundColor: "#d11717",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },

        // {
        //   when: (row) => row.calories < 600,
        //   style: (row) => ({
        //     backgroundColor: row.isSpecial ? "pink" : "inerit",
        //   }),
        // },
      ],

      sortable: true,
      width: "160px",
    },

    {
      name: "Accelerator Verification",
      // style: (row) => ({
      //   backgroundColor:
      //     row?.verification[0]?.accept == true ? "#00FF00" : "#FF0000",
      // }),

      cell: (row) => {
        return (
          <>
            <div>{row?.accelerator == true ? "Verified" : "Not Verified"}</div>
          </>
        );
      },

      conditionalCellStyles: [
        {
          when: (row) => row?.accelerator == true,
          style: {
            backgroundColor: "#098f09",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },

        {
          when: (row) => row?.accelerator == false,
          style: {
            backgroundColor: "#d11717",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },

        // {
        //   when: (row) => row.calories < 600,
        //   style: (row) => ({
        //     backgroundColor: row.isSpecial ? "pink" : "inerit",
        //   }),
        // },
      ],

      sortable: true,
      width: "160px",
    },

    {
      name: "Payment Verification",
      // style: (row) => ({
      //   backgroundColor:
      //     row?.verification[0]?.accept == true ? "#00FF00" : "#FF0000",
      // }),

      cell: (row) => {
        return (
          <>
            <div>{row?.payment?.length != 0 ? "Verified" : "Not Verified"}</div>
          </>
        );
      },

      conditionalCellStyles: [
        {
          when: (row) => row?.payment?.length != 0,
          style: {
            backgroundColor: "#098f09",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },

        {
          when: (row) => row?.payment?.length == 0,
          style: {
            backgroundColor: "#d11717",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },

        // {
        //   when: (row) => row.calories < 600,
        //   style: (row) => ({
        //     backgroundColor: row.isSpecial ? "pink" : "inerit",
        //   }),
        // },
      ],

      sortable: true,
      width: "160px",
    },
    {
      name: "Last Modified",
      cell: (row) => {
        return (
          <>
            <div>
              {moment(row.updatedAt)
                .utc()
                .local()
                .format("MMM Do YYYY, h:mm a")}
            </div>
          </>
        );
      },
      selector: (row) => row.lastLogin,
      width: "210px",
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row?.status == false && "Deactive"}

            {row?.status == true && "Active"}
          </>
        );
      },
      selector: (row) => row.status,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => row?.status == true,
          style: {
            backgroundColor: "#098f09",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },

        {
          when: (row) => row?.status == false,
          style: {
            backgroundColor: "#d11717",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },

        // {
        //   when: (row) => row.calories < 600,
        //   style: (row) => ({
        //     backgroundColor: row.isSpecial ? "pink" : "inerit",
        //   }),
        // },
      ],
      width: "120px",
    },

    // {
    //   name: "Mailbox",
    //   cell: (row) => {
    //     // console.log("row@@@@@@", row?.isSubmit);
    //     return (
    //       <>
    //         {row?.isSubmit == "No" && "NO"}

    //         {row?.isSubmit == "Yes" && "YES"}

    //         {row?.isSubmit == false && "Mail Not send"}
    //       </>
    //     );
    //   },
    //   selector: (row) => row.isSubmit,
    //   sortable: true,
    //   width: "100px",
    // },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <ReactTooltip id="registerTip" place="top" effect="solid">
                  User profile
                </ReactTooltip>
                <h4
                  style={{ cursor: "pointer" }}
                  className="mb-0 mt-3 mr-4"
                  onClick={() => ProfileFuntions(row)}
                >
                  <i data-tip data-for="registerTip" class="fas fa-user"></i>
                </h4>
                <div
                  className="cursor-pointer mr-0 mt-3"
                  onClick={() => {
                    setUpdateProduct(false);
                    setUpdateDetails(true);
                    handleForUpdateManager(row._id);
                    setDescriptionUpdate(row.phone);
                    setRoleNameUpdate(row.name);
                    setUserEmail(row.email);
                    setRoleupdate(row.currentRole);
                  }}
                >
                  <CreateIcon />
                </div>
              </div>
              {row.status == false && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    className="btn btn-success"
                    // style={{ color: "#007bff" }}
                    onClick={() => {
                      handlealtermessage(row._id, true);
                    }}
                  >
                    <span
                    // style={{
                    //   border: "none",
                    //   display: "flex",
                    //   whiteSpace: "nowrap",
                    //   // height: "35px",
                    //   // paddingLeft: "21px",
                    //   // paddingRight: "21px",
                    // }}
                    >
                      Active
                    </span>
                  </button>
                </div>
              )}

              {row.status == true && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    className="btn-danger btn"
                    onClick={() => {
                      handlealtermessage(row._id, false);
                    }}
                    style={{
                      border: "none",
                      display: "flex",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Deactive
                  </button>
                </div>
              )}

              <div className="cursor-pointer ml-5 mt-1">
                <button
                  className="btn-danger btn"
                  onClick={() => {
                    setupToBeDeletedUser(row?._id);
                  }}
                  style={{
                    border: "none",
                    display: "flex",
                    whiteSpace: "nowrap",
                  }}
                >
                  Delete user
                </button>
              </div>
            </div>
          </>
        );
      },
      wrap: true,
      width: "290px",

      sortable: true,
    },

    {
      name: "Verification Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              {row?.verification[0]?.accept == false && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    className="btn btn-success"
                    style={{
                      border: "none",
                      display: "flex",
                      whiteSpace: "nowrap",
                      width: "90px",
                      height: "38px",
                    }}
                    // style={{ color: "#007bff" }}
                    onClick={() => {
                      HandleFilter(row._id, "accept");
                    }}
                  >
                    <span
                      style={{
                        border: "none",
                        display: "flex",
                        whiteSpace: "nowrap",
                        padding: "0px 10px",
                        // height: "35px",
                        // paddingLeft: "21px",
                        // paddingRight: "21px",
                      }}
                    >
                      Accept
                    </span>
                  </button>
                </div>
              )}

              {row?.verification[0]?.accept == true && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    className="btn-danger btn"
                    style={{
                      border: "none",
                      display: "flex",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Accepted
                  </button>
                </div>
              )}

              {row?.verification[0]?.reject == false && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    className="btn btn-success"
                    style={{
                      border: "none",
                      display: "flex",
                      whiteSpace: "nowrap",
                      width: "90px",
                    }}
                    // style={{ color: "#007bff" }}
                    onClick={() => {
                      HandleFilter(row._id, "reject");
                    }}
                  >
                    <span
                      style={{
                        border: "none",
                        display: "flex",
                        whiteSpace: "nowrap",
                        padding: "0px 10px",
                        // height: "35px",
                        // paddingLeft: "21px",
                        // paddingRight: "21px",
                      }}
                    >
                      Reject
                    </span>
                  </button>
                </div>
              )}

              {row?.verification[0]?.reject == true && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    className="btn-danger btn"
                    style={{
                      border: "none",
                      display: "flex",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Rejected
                  </button>
                </div>
              )}

              {row?.verification?.length == 0 && "-"}
            </div>
          </>
        );
      },
      wrap: true,
      width: "300px",

      sortable: true,
    },

    {
      name: "Accelerator Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              {row?.accelerator == false && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    className="btn btn-success"
                    style={{
                      border: "none",
                      display: "flex",
                      whiteSpace: "nowrap",
                      // width: "90px",
                    }}
                    // style={{ color: "#007bff" }}
                    onClick={() => {
                      HandleFilter(row._id, "accelerator-accept");
                    }}
                  >
                    <span
                      style={{
                        border: "none",
                        display: "flex",
                        whiteSpace: "nowrap",
                        padding: "0px 10px",
                        // height: "35px",
                        // paddingLeft: "21px",
                        // paddingRight: "21px",
                      }}
                    >
                      Accelerator-accept
                    </span>
                  </button>
                </div>
              )}

              {row?.accelerator == true && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    className="btn-danger btn"
                    style={{
                      border: "none",
                      display: "flex",
                      whiteSpace: "nowrap",
                      // width: "90px",
                    }}
                    onClick={() => {
                      HandleFilter(row._id, "accelerator-reject");
                    }}
                  >
                    <span
                      style={{
                        border: "none",
                        display: "flex",
                        whiteSpace: "nowrap",
                        padding: "0px 10px",
                        // height: "35px",
                        // paddingLeft: "21px",
                        // paddingRight: "21px",
                      }}
                    >
                      Accelerator-reject
                    </span>
                  </button>
                </div>
              )}

              {/* {row?.accelerator?.length == 0 && "-"} */}
            </div>
          </>
        );
      },
      wrap: true,
      width: "300px",

      sortable: true,
    },

    {
      name: "Payment Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              {row?.payment?.length == 0 && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    className="btn btn-success"
                    style={{
                      border: "none",
                      display: "flex",
                      whiteSpace: "nowrap",
                      // width: "90px",
                    }}
                    onClick={() => {
                      HandleFilter(row._id, "payment-accept");
                    }}
                  >
                    <span
                      style={{
                        border: "none",
                        display: "flex",
                        whiteSpace: "nowrap",
                        padding: "0px 10px",
                        // height: "35px",
                        // paddingLeft: "21px",
                        // paddingRight: "21px",
                      }}
                    >
                      Verified Payment
                    </span>
                  </button>
                </div>
              )}

              {row?.payment?.length != 0 && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    className="btn-danger btn"
                    style={{
                      border: "none",
                      display: "flex",
                      whiteSpace: "nowrap",
                      // width: "90px",
                    }}
                    // onClick={() => {
                    //   HandleFilter(row._id, "accelerator-reject");
                    // }}
                  >
                    <span
                      style={{
                        border: "none",
                        display: "flex",
                        whiteSpace: "nowrap",
                        padding: "0px 10px",
                        // height: "35px",
                        // paddingLeft: "21px",
                        // paddingRight: "21px",
                      }}
                    >
                      Not Verified
                    </span>
                  </button>
                </div>
              )}

              {/* {row?.accelerator?.length == 0 && "-"} */}
            </div>
          </>
        );
      },
      wrap: true,
      width: "300px",

      sortable: true,
    },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const [createrData, setCreaterdata] = useState({});

  const handleChange = (e) => {
    let { name, value } = e.target;

    setCreaterdata((prevState) => ({
      ...prevState,

      [name]: value,
    }));

    setErrors({ ...errors, [name]: "" });
  };

  const formValidator = () => {
    let isValid = true;

    if (!createrData.name) {
      setErrors((cv) => {
        return { ...cv, name: "Please Enter  Name" };
      });
      isValid = false;
    }

    if (!createrData.email) {
      setErrors((cv) => {
        return { ...cv, email: "Please Enter email" };
      });
      isValid = false;
    } else if (
      createrData.email.trim() &&
      !createrData.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrors((cv) => {
        return { ...cv, email: "Please enter valid email" };
      });
      isValid = false;
    }

    // if (!createrData.phone) {
    //   setErrors((cv) => {
    //     return { ...cv, phone: "Please Enter phonenumber" };
    //   });
    //   isValid = false;
    // }

    if (!createrData.password) {
      setErrors((cv) => {
        return { ...cv, password: "Please Enter password" };
      });
      isValid = false;
    }

    return isValid;
  };

  const formUpdateValidator = () => {
    let isValid = true;

    if (!roleNameUpdate) {
      setErrors((cv) => {
        return { ...cv, name: "Please Enter  Name" };
      });
      isValid = false;
    }

    // if (!descriptionUpdate) {
    //   setErrors((cv) => {
    //     return { ...cv, phone: "Please Enter phonenumber" };
    //   });
    //   isValid = false;
    // }

    return isValid;
  };

  const submitDetails = async () => {
    if (!formValidator()) {
      console.log("ERROR POST", errors);
      return;
    }

    setLoading(true);

    console.log("createrData", createrData);

    const Data = {
      ...createrData,
      admin: "admin",
      passwordShow: createrData.password,
    };

    await ApiPost(`user/registration`, Data)
      .then(async (res) => {
        if (res.data && res.data.payload && res.data.payload) {
          setUpdateProduct(false);
          setLoading(false);
          setDataChange([...dataChange, "Details Added"]);
          setRoleName();
          setDescription();
          setCreaterdata({});
          setOpen(false);
          toast.success(res.data.message);
        } else {
          setMsg(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error.response);
        toast.error(error.response.data.message);
      });
  };

  const handleSearch = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setFilteredReport(() =>
      userReport.filter(
        (item) =>
          value == "active"
            ? item?.status == true
            : value == "deactive"
            ? item?.status == false
            : item?.name?.toLowerCase().includes(value) ||
              item?.email?.toLowerCase().includes(value) ||
              item?.user?.organizationName?.toLowerCase().includes(value)
        // item?.phone?.toLowerCase().includes(value)
      )
    );
  };

  // console.log('filteredReport', filteredReport)

  return (
    <>
      <div>
        {/* <div className="card p-1">
          <div className="p-2 mb-2">
            <div class="p-2 d-flex bd-highlight">
              <div class="flex-grow-1 bd-highlight">
                <div className="pl-2 pt-2 headerTitle">User Reports</div>
              </div>
              <div class="bd-highlight col-4">
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="title"
                  placeholder="Search user"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
              <div class="bd-highlight">
                <button
                  className="btn btnColor"
                  onClick={() => {
                    setUpdateDetails(false);
                    handleClickOpen();
                  }}
                >
                  Add User
                </button>
              </div>
            </div>

            
          </div>
        </div> */}

        <div className="card p-1">
          <div className="p-2 mb-2">
            <div className="row mb-4 pr-3">
              <div className="col d-flex justify-content-between">
                <h2 className="pl-3 pt-2">User management</h2>
              </div>
              <div className="col">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    placeholder="Search user"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              <div className="cus-medium-button-style button-height">
                <button
                  className="btn btnColor"
                  onClick={() => {
                    setUpdateDetails(false);
                    handleClickOpen();
                  }}
                >
                  Add User
                </button>
                {/* <div className="col d-flex justify-content-end "> */}
                <CsvDownload
                  className={``}
                  data={dataCSVAdmin}
                  filename="user.csv"
                  style={{
                    //pass other props, like styles
                    backgroundColor: "#F64E60",
                    borderRadius: "6px",
                    border: "1px solid #fff",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "#FFFFFF",
                    fontSize: "12px",
                    padding: "10px 18px",
                    textDecoration: "none",
                    position: "right",
                  }}
                >
                  Export to Csv
                </CsvDownload>
                {/* </div> */}
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={filteredReport}
            pagination
            customStyles={customStyles}
            // conditionalRowStyles={conditionalRowStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={
              <Loader type="Puff" color="#334D52" height={30} width={30} />
            }
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {status == true ? "Active" : "Deactive"} this
          User?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="danger" onClick={() => handelUpdatestatusDetails()}>
            {status == true && !loading ? "Active" : "Deactive"}
            {loading && <span className="mx-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* {AzureId: 39} */}
      {/* {himanshu@johnbehrgroup.com} */}

      <Modal
        show={toBeDeletedUserDetails?.showUserDeleteModal}
        onHide={closeUserDeleteModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeUserDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => deleteUserByIdHandler()}>
            {toBeDeletedUserDetails?.userDeleteState === "loading" ? (
              <span className="mx-3 spinner spinner-white"></span>
            ) : (
              "Confirm"
            )}
            {/* {status == true && !loading ? "Active" : "Deactive"}
            {loading && <span className="mx-3 spinner spinner-white"></span>} */}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* {AzureId: 39} */}
      {/* {himanshu@johnbehrgroup.com} */}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          {updateProduct && updateProduct === true ? (
            <div className="form ml-30 ">
              {/* Account Number */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Full Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Full name"
                      className={`form-control form-control-lg form-control-solid `}
                      name="name"
                      value={roleName}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Email
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Email"
                      className={`form-control form-control-lg form-control-solid `}
                      name="email"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["email"]}
                  </span>
                </div>
              </div>

              {/* <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Phone number
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="number"
                      autoComplete="off"
                      placeholder="Phone Number"
                      className={`form-control form-control-lg form-control-solid `}
                      name="phone"

                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["phone"]}
                  </span>
                </div>
              </div> */}

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  password
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="password"
                      autoComplete="off"
                      placeholder="password"
                      className={`form-control form-control-lg form-control-solid `}
                      name="password"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["password"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Organization Code
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Organization Code"
                      className={`form-control form-control-lg form-control-solid `}
                      name="organizationId"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["email"]}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    !loading && submitDetails(e);
                  }}
                  className="btn btnColor mr-2"
                >
                  <span>Submit Details</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          ) : null}
        </List>
        <List>
          {updateDetails && updateDetails === true ? (
            <div className="form ml-30 ">
              {/* First Name */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Full name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="name"
                      value={roleNameUpdate}
                      onChange={(e) => {
                        setRoleNameUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleNameUpdate: "" };
                        });
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Current Role
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      name="currentRole"
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        setRoleupdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleUpdate: "" };
                        });
                      }}
                    >
                      <option value={roleUpdate}>
                        {roleUpdate ? roleUpdate : "Select Organization Role"}
                      </option>
                      {organigationRoleList.map((item, index) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                    {/* <span style={{ color: "red" }}>
                      {verificationError["currentRole"]}
                    </span> */}
                    {/* <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="name"
                      value={roleUpdate}
                      onChange={(e) => {
                        setRoleupdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleUpdate: "" };
                        });
                      }}
                    /> */}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>
              {/* swift code */}
              {/* <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  phone
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="number"

                      min="10"
                      className={`form-control form-control-lg form-control-solid `}
                      name="phone"
                      value={descriptionUpdate}
                      onChange={(e) => {
                        setDescriptionUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, descriptionUpdate: "" }
                        })
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["phone"]}
                  </span>
                </div>
              </div> */}

              <div className="d-flex align-items-center justify-content-center">
                {submitValue && submitValue === true ? (
                  <button
                    onClick={(e) => {
                      handelUpdateDetails();
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Update Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      submitDetails(e);
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Submit Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </List>
      </Dialog>

      {/* user Profile  */}

      {/* user Profile end */}

      <Dialog
        fullScreen
        open={profile}
        onClose={handleProfileClose}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleProfileClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          <Profile
            profile={profileData}
            rolegetApi={rolegetApi}
            handleProfileClose={handleProfileClose}
          />
        </List>
      </Dialog>

      <Modal show={show1} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to {Status} this User?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            cancel
          </Button>
          <Button variant="danger" onClick={() => HandleChangeStaus()}>
            {Status}
            {loading && <span className="mx-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Role;
