import React, { useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DataTable, { defaultThemes } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import { Modal } from "react-bootstrap";
import { Dialog } from "@material-ui/core";
import { List } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Slide } from "@material-ui/core";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { Tooltip } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DropDown = () => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [pid, setPid] = useState();
  const [updateProduct, setUpdateProduct] = useState(false);
  const [submitValue, setSubmitValue] = useState(false);
  const [roleName, setRoleName] = useState();
  const [description, setDescription] = useState();
  const [titleUpdate, setTitleUpdate] = useState();
  const [roleUpdate, setRoleupdate] = useState();
  const [typeUpdate, setTypeUpdate] = useState();
  const [msg, setMsg] = useState("");
  const [updateDetails, setUpdateDetails] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [idForEditType, setIdForEditType] = useState();
  const [dropData, setDropData] = useState();
  const [filteredReport, setFilteredReport] = useState();
  const [UserEmail, setUserEmail] = useState();
  const [errors, setErrors] = useState({});
  let userInfo = getUserInfo();
  const [createrData, setCreaterdata] = useState({});
  const [costUpdate, setCostUpdate] = useState();
  const [validatityUpdate, setValidityUpdate] = useState();
  const [show, setShow] = useState();
  const [idforDelete, setIdForDelete] = useState();
  const [showStatus, setShowStatus] = useState(false);
  const [idforActive, setIdforActive] = useState();
  const [statusDisplay, setstatusDiaplay] = useState();

  const handleStatusModal = (_id, status) => {
    setShowStatus(true);
    setIdforActive(_id);
    setstatusDiaplay(status);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setSubmitValue(false);
    setUpdateProduct(true);
  };

  const handleClose1 = () => {
    setOpen(false);
    setErrors({});
    setShow(false);
    setShowStatus(false);
  };

  useEffect(() => {
    GetDrop();
  }, [dataChange]);

  const GetDrop = async () => {
    setIsLoaderVisible(true);
    await ApiGet("dropdown/getdropdown?type=drop")
      .then((res) => {
        console.log("get plan", res);
        setRoleData(res.data.payload?.dropdown);
        setFilteredReport(res.data.payload?.dropdown);
        setDropData(res.data.payload?.dropdown);
        console.log("roleadd", res.data);
        setIsLoaderVisible(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoaderVisible(false);
      });
  };

  const DeleteDrop = async () => {
    await ApiDelete(`dropdown/delete-dropdown?downId=${idforDelete}`)
      .then((res) => {
        setShow(false);
        toast.success("Drop Deleted successfully");
        GetDrop();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const ActiveDeactiveDrop = async () => {
    const body = {
      isActive: statusDisplay,
    };
    await ApiPut(
      `dropdown/update-dropdown?dropId=${idforActive}&isActive=${statusDisplay}`,
      body
    )
      .then((res) => {
        setShowStatus(false);
        toast.success("Drop Deleted successfully");
        GetDrop();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleDeleteManagerInfo = async () => {
    await ApiDelete(`role/id=${pid}`)
      .then((res) => {
        setDataChange([...dataChange, "Payment delete"]);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const handleForUpdateManager = async (id) => {
    setOpen(true);
    setPid(id);
    setSubmitValue(true);
    let temp = roleData.filter((data) => {
      return data._id === id;
    });
    setTitleUpdate(temp[0].dropdown);
    // setTypeUpdate(temp[0].type);
  };

  const handelUpdateDetails = async () => {
    if (!formUpdateValidator()) {
      console.log("ERROR PUT", errors);
      return;
    }

    setLoading(true);

    let data = {
      dropdown: titleUpdate,
      //   type: typeUpdate,
    };

    await ApiPut(`dropdown/update-dropdown?dropId=${idForEditType}`, data)
      .then((res) => {
        setOpen(false);
        setErrors({});
        setUpdateProduct(false);
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        toast.success("Drop updated successfully");
        console.log("new dataaa", dataChange);

        // toast.success("Role Updated Successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  let i = 0;
  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "10%",
    },
    {
      name: "Title",
      cell: (row) => row?.dropdown,
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => row?.type,
      sortable: true,
    },
    {
      name: "Current Status",
      cell: (row) => row?.status,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <div
              className="cursor-pointer mr-0 mt-3"
              onClick={() => {
                setUpdateProduct(false);
                setUpdateDetails(true);
                handleForUpdateManager(row._id);
                setIdForEditType(row?._id);
                console.log("row", createrData);
                setTitleUpdate(row.dropdown);
                // setTypeUpdate(row.type);
              }}
            >
              <CreateIcon />
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    console.log("delete", e.target);
                    setIdForDelete(row?._id);
                    setShow(true);
                  }}
                >
                  <Tooltip title="Erinnerung löschen" arrow>
                    <DeleteIcon />
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        );
      },
      wrap: true,

      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row.status == "active" && (
              <div className="cursor-pointer ml-5 mt-1">
                <button
                  className="btn-danger btn"
                  onClick={() => {
                    handleStatusModal(row._id, "deactive");
                  }}
                  style={{
                    border: "none",
                    display: "flex",
                    whiteSpace: "nowrap",
                  }}
                >
                  Deactive
                </button>
              </div>
            )}
            {row.status == "deactive" && (
              <div className="cursor-pointer ml-5 mt-1">
                <button
                  className="btn-danger btn"
                  onClick={() => {
                    handleStatusModal(row._id, "active");
                  }}
                  style={{
                    border: "none",
                    display: "flex",
                    whiteSpace: "nowrap",
                  }}
                >
                  active
                </button>
              </div>
            )}
          </>
        );
      },
      selector: (row) => row.status,
      sortable: true,
    },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setCreaterdata((prevState) => ({
      ...prevState,

      [name]: value,
    }));

    setErrors({ ...errors, [name]: "" });
  };

  const formValidator = () => {
    let isValid = true;

    if (!createrData.title) {
      setErrors((cv) => {
        return { ...cv, title: "Please Enter Title" };
      });
      isValid = false;
    }
    return isValid;
  };

  const formUpdateValidator = () => {
    let isValid = true;

    if (!titleUpdate) {
      setErrors((cv) => {
        return { ...cv, name: "Please Enter  title" };
      });
      isValid = false;
    }

    return isValid;
  };

  const submitDetails = async () => {
    console.log("on submitttttttttt");
    if (!formValidator()) {
      console.log("ERROR POST", errors);
      return;
    }

    setLoading(true);

    console.log("createrData", createrData);
    createrData.type = "drop";

    await ApiPost(`dropdown/adddropdown`, createrData)
      .then(async (res) => {
        console.log("add plan", res);

        if (res.data && res.data.payload && res.data.payload) {
          setUpdateProduct(false);
          setLoading(false);
          setDataChange([...dataChange, "Details Added"]);
          setRoleName();
          setDescription();
          setCreaterdata({});
          setOpen(false);
          toast.success(res.data.message);
        } else {
          setMsg(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const handleSearch = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setFilteredReport(() =>
      dropData.filter(
        (item) =>
          item?.dropdown?.toLowerCase().includes(value) ||
          item?.type?.toLowerCase().includes(value) ||
          item?.status?.toLowerCase().includes(value)
      )
    );
  };

  return (
    <>
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">DropDown</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="title"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                className="btn btnColor"
                onClick={() => {
                  setUpdateDetails(false);
                  handleClickOpen();
                }}
              >
                Add Drop
              </button>
            </div>
          </div>
        </div>

        {/* Active Deactive open modal for drop     */}
        <Modal show={showStatus} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to{" "}
            {statusDisplay == true ? "Active" : "Deactive"} this Drop?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              cancel
            </Button>
            <Button variant="danger" onClick={() => ActiveDeactiveDrop()}>
              {statusDisplay == "active" && !loading ? "Active" : "Deactive"}
              {loading && <span className="mx-3 spinner spinner-white"></span>}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Active Deactive close modal for drop     */}

        <DataTable
          columns={columns}
          data={filteredReport}
          pagination
          customStyles={customStyles}
          style={{
            marginTop: "-3rem",
          }}
          progressPending={isLoaderVisible}
          progressComponent={
            <Loader type="Puff" color="#334D52" height={30} width={30} />
          }
        />
      </div>

      {/* delete model */}
      <Modal show={show} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            {/*Warnung!*/}Warning!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you Sure delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              DeleteDrop();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* end delete model */}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          {updateProduct && updateProduct === true ? (
            <div className="form ml-30 ">
              {/* Account Number */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Title:
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Title"
                      className={`form-control form-control-lg form-control-solid `}
                      name="title"
                      value={roleName}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["title"]}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={() => {
                    !loading && submitDetails();
                  }}
                  className="btn btnColor mr-2"
                >
                  <span>Submit Details</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          ) : null}
        </List>
        <List>
          {updateDetails && updateDetails === true ? (
            <div className="form ml-30 ">
              {/* First Name */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Title
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="dropdown"
                      value={titleUpdate}
                      onChange={(e) => {
                        setTitleUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleUpdate: "" };
                        });
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["dropdown"]}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                {submitValue && submitValue === true ? (
                  <button
                    onClick={(e) => {
                      handelUpdateDetails();
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Update Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      submitDetails(e);
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Submit Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </List>
      </Dialog>
    </>
  );
};

export default DropDown;
