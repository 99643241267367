import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
    ApiDelete,
    ApiGet,
    ApiPost,
    ApiPut,
} from "../../../../helpers/API/ApiData";
import { toast } from "react-toastify";

const Courses = ({ getCourses }) => {
    const myCourses = [
        {
            id: uuidv4(),
            menuName: "",
            roleId: [],
        },
    ];

    const [courses, setCourses] = useState([...myCourses]);

    useEffect(() => {
        getCourses(courses);
        console.log(courses, "courses");
    }, [courses]);



    const addCourse = () => {
        setCourses((prevState) => {
            const initialCourse = {
                id: uuidv4(),
                menuName: "",
                roleId: [],
            };

            return [...prevState, initialCourse];
        });
    };

    const handleCourseChange = (courseId, value, name, checked) => {
        console.log("value", value);
        setCourses((prevState) => {
            const course = prevState[courseId];
            if (name === "roleId") {
                let roleIds = course.roleId || []
                if (checked === true) {
                    roleIds.push(value)

                } else {
                    let index = roleIds.indexOf(value)
                    if (index > -1) {
                        roleIds.splice(index, 1)
                    }
                }
                course[name] = roleIds;

            } else {
                course[name] = value;
            }

            return [...prevState];
        });
    };

    // const handleVideo = (videoId, courseId, value, name) => {
    //     console.log("name", name);
    //     setCourses((prevState) => {
    //         const course = prevState[courseId];
    //         const video = course.roleId[videoId];
    //         video[name] = value;
    //         return [...prevState];
    //     });
    // };

    const removeCourse = (courseId) => {
        setCourses((prevState) => {
            const newCourses = prevState.filter((course, i) => i != courseId);
            return [...newCourses];
        });
    };

    return (
        <div className="App">
            <h2> Courses </h2>{" "}
            <button
                className="mt-8 mb-8 p-5 bg-gray-300 rounded-md plus-color"
                onClick={addCourse}
            >
                +
            </button>
            {courses.map((course, i) => {
                return (
                    <RenderCourse
                        course={course}
                        key={i}
                        handleCourseChange={handleCourseChange}
                        // addVideo={addVideo}
                        // handleVideo={handleVideo}
                        // removeVideos={removeVideos}
                        removeCourse={removeCourse}
                        courseId={i}
                    />
                );
            })}
        </div>
    );
};

const RenderCourse = ({
    course,
    courseId,
    // addVideo,
    handleCourseChange,
    // handleVideo,
    // removeVideos,
    removeCourse,
}) => {

    // useEffect(() => {
    //     console.log("roleID", roleID);
    // }, [roleID])
    const [roleData, setRoleData] = useState([]);
    // const [roleID, setRoleId] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await ApiGet("role")
                .then((res) => {
                    if (res.data && res.data.payload && res.data.payload.allRole) {
                        setRoleData(res.data.payload.allRole);
                        console.log("roleadd", res.data.payload.allRole);
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        }
        fetchData()
    }, []);



    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "roleId") {
            handleCourseChange(courseId, value, name, e.target.checked);
        } else {
            handleCourseChange(courseId, value, name);
        }



    };



    return (
        <div className="box">
            <p className="border-t-2 mb-5"></p>
            <button
                className="p-3 bg-gray-300 rounded-md color-button plus-color"
                onClick={() => removeCourse(courseId)}
            >
                Remove Menu
            </button>
            <input
                name="menuName"
                placeholder="Enter Menu Name"
                value={course.title}
                onChange={handleChange}
            />
            <div>
                {roleData.map((item, i) => {
                    return (
                        <>
                            <input
                                type="checkbox"
                                id={item._id}
                                name="roleId"
                                value={item._id}
                                onChange={handleChange}
                            />
                            <label for="roleId"> {item?.roleName}</label>
                        </>
                    );
                })}
            </div>
        </div>
    );
};

export default Courses;
