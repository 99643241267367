import React, { useState } from "react";
import useChat from "../../../helpers/useChat/usechat";

import moment from "moment";
function ChatMessage({ roomId, activeUser }) {
  const { messages, sendMessage } = useChat(roomId);
  const [sendMsg, setSendMsg] = useState();
  const [writting, setWritting] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));
  console.log(userInfo, "userinfo");

  const handleSendMessage = () => {
    sendMsg && sendMessage(sendMsg);
    setSendMsg("");
  };

  return (
    <div>
      <div className="char-grid-items">
        <div className="chat-box-messgae-align">
          <div className="profile-typing-grid ">
            <div className="profile-typing-grid-items">
              <div className="user-grid">
                <div className="user-grid-items">
                  <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBob3RvfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80" />
                </div>
                <div className="user-grid-items">
                  <h5>{activeUser.name}</h5>
                  {writting && <i>writing...</i>}
                </div>
              </div>
            </div>
            <div className="profile-typing-grid-items">
              <div className="option-alignment">
                {/* <div className="option-design">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-phone"
                  >
                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                  </svg>
                </div> */}
                {/* <div className="option-design">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-video"
                  >
                    <polygon points="23 7 16 12 23 17 23 7"></polygon>
                    <rect
                      x="1"
                      y="5"
                      width="15"
                      height="14"
                      rx="2"
                      ry="2"
                    ></rect>
                  </svg>
                </div> */}
                {/* <div className="option-design">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-more-horizontal"
                  >
                    <circle cx="12" cy="12" r="1"></circle>
                    <circle cx="19" cy="12" r="1"></circle>
                    <circle cx="5" cy="12" r="1"></circle>
                  </svg>
                </div> */}
              </div>
            </div>
          </div>
          <div className="chat-box-height-main">
            {messages &&
              messages.length > 0 &&
              messages.map((message) => {
                console.log(message, "datdtadtatdt");
                if (message?.sender === userInfo.user?._id) {
                  return (
                    <div className="left-side-message-end">
                      <div className="left-side-message">
                        <p>{message?.message}</p>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="left-side-message-start">
                      <div className="left-side-message">
                        <p>{message?.message}</p>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          <div className="chat-typing-input">
            <div className="input-grid">
              <div className="input-grid-items">
                <input
                  type="text"
                  value={sendMsg}
                  placeholder="Typing......"
                  onChange={(e) => {
                    setWritting(true);
                    setTimeout(() => {
                      setWritting(false);
                    }, 3000);
                    setSendMsg(e.target.value);
                  }}
                  onKeyPress={(event) =>
                    event.key === "Enter" ? handleSendMessage() : null
                  }
                />
              </div>
              <div className="input-grid-items">
                <button type="submit" onClick={handleSendMessage}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatMessage;
