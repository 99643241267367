import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ApiPostNoAuth } from "../../../../helpers/API/ApiData";
import * as authUtil from "../../../../utils/auth.util";
import * as userUtil from "../../../../utils/user.util";
import { Link, useHistory } from "react-router-dom";
    
const initialValues = {
  artistname: "",
  realname: "",
  desc: "",
  email: "",
  password: "",
};

export default function Registration() {
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    artistname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("artistname is Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    realname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Realname is Required"),
    desc: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Description is Required"),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is Required"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is Required"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const history = useHistory();
  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      //setSubmitting(true);
      enableLoading();

      const role = values.role;

      let data = {};

      if (role === "artist") {
        data = {
          artistName: values.artistname,
          realName: values.realname,
          password: values.password,
          email: values.email,
          desc: values.desc,
        };
      } else {
        data = {
          firstName: values.artistname,
          lastName: values.realname,
          password: values.password,
          email: values.email,
          desc: values.desc,
        };
      }

      console.log("newrole", role);
      console.log("DATA", data);
      await ApiPostNoAuth(`${role}/signup`, data)
        .then((res) => {
          try {
            if (res.data.data !== "Email has been already registered.") {
              if (parseInt(res.data.status / 100) === 2) {
                localStorage.clear();
                authUtil.setToken(res.data.data.token);
                userUtil.setUserInfo(res.data.data);
                history.push("/dashboard");
                window.location.reload();
                setLoading(false);
                setSubmitting(false);
              } else {
                setLoading(false);
                setSubmitting(false);
                setStatus("Signup Credentials are incorrect.");
              }
            } else {
              setStatus(res.data.data);
            }
          } catch (err) {
            console.log("Error : ", err);
            setLoading(false);
            setSubmitting(false);
            setStatus("Error connecting to network.");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      const gender = values.gender;
      console.log("DATA", data);
      await ApiPostNoAuth(`${role}/signup`, data)
        .then((res) => {
          try {
            if (res.data.data !== "Email has been already registered.") {
              if (parseInt(res.data.status / 100) === 2) {
                localStorage.clear();
                authUtil.setToken(res.data.data.token);
                userUtil.setUserInfo(res.data.data);
                history.push("/dashboard");
                window.location.reload();
                setLoading(false);
                setSubmitting(false);
              } else {
                setLoading(false);
                setSubmitting(false);
                setStatus("Login Credentials are incorrect.");
              }
            } else {
              setStatus(res.data.data);
            }
          } catch (err) {
            console.log("Error : ", err);
            setLoading(false);
            setSubmitting(false);
            setStatus("Error connecting to network.");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
          REGISTER
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Artist Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "artistname"
            )}`}
            name="artistname"
            {...formik.getFieldProps("artistname")}
          />
          {formik.touched.artistname && formik.errors.artistname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.artistname}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}
        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Real Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "realname"
            )}`}
            name="realname"
            {...formik.getFieldProps("realname")}
          />
          {formik.touched.realname && formik.errors.realname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.realname}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}
        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Description"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "desc"
            )}`}
            name="desc"
            {...formik.getFieldProps("desc")}
          />
          {formik.touched.desc && formik.errors.desc ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.desc}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <select
            className={`form-control form-control-lg form-control-solid ${getInputClasses(
              "role"
            )}`}
            name="role"
            {...formik.getFieldProps("role")}
          >
            <option>Select Role...</option>
            <option value="artist">Artist</option>
            <option value="partner">Affiliates</option>
            {/* <option value="manager">Manager</option> */}
          </select>
          {formik.touched.role && formik.errors.role ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.role}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <select
            className={`form-control form-control-lg form-control-solid ${getInputClasses(
              "gender"
            )}`}
            name="gender"
            {...formik.getFieldProps("gender")}
          >
            <option>Gender</option>
            <option value="gender">Male</option>
            <option value="gender">Female</option>
            <option value="gender">non-binary</option>
            <option value="gender">I prefer not to disclose</option>
          </select>
          {formik.touched.gender && formik.errors.gender ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.gender}</div>
            </div>
          ) : null}
        </div>

        {/* end: Password */}

        {/* begin: Confirm Password */}
        {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div> */}
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        {/* <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              I agree the Terms & Conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
        <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
          <span className="font-weight-bold text-dark-50">
            already have an account yet?
          </span>
          <Link
            to="/auth/login"
            className="font-weight-bold ml-2"
            id="kt_login_signup"
          >
            Sign In!
          </Link>
        </div>
      </form>
    </div>
  );
}
