import React, { useEffect, useState } from "react";
import "./chat.scss";
import ChatMessage from "./ChatMessage";
import { ApiPost, ApiGet } from "../../../helpers/API/ApiData";

function Chat({ personId = "" }) {
  const [roomId, setRoomId] = useState();
  const [connections, setConnections] = useState([]);
  const [activeUser, setActiveUser] = useState();

  console.log("connections", connections);

  useEffect(() => {
    activeUser && initiatChat(activeUser?._id);
  }, [activeUser]);

  const getConnections = () => {
    ApiGet("connection/conected").then((res) => {
      const connections = res?.data?.payload?.connection || [];
      console.log(connections, "connections");
      setConnections(connections);
      if (personId) {
        setActiveUser([...connections].filter((con) => con?._id === personId));
      } else {
        setActiveUser(connections?.[0]);
      }
    });
  };

  const initiatChat = (id) => {
    const body = {
      id: id,
    };

    ApiPost("chat/initiate-chat", body)
      .then((res) => {
        const roomId = res?.data?.payload?.chatRoom?._id;
        console.log(roomId, "roomId");
        setRoomId(roomId);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getConnections();
  }, []);

  console.log("roomId", roomId);
  return (
    <div style={{ width: "100%" }}>
      <div className="chat-section-alignment">
        <div className="chat-grid">
          <div className="char-grid-items">
            <div className="input-search-relative">
              <input type="search" placeholder="Search Chat" />
            </div>
            <div className="chat-profile-height">
              {connections?.length == 0
                ? ""
                : connections?.length > 0 &&
                  connections.map((conection) => {
                    console.log("conection147", conection);
                    return (
                      <div
                        style={{
                          backgroundColor:
                            conection?._id === activeUser?._id
                              ? "#ffffff"
                              : "inherit",
                        }}
                        className="profile-grid "
                        onClick={() => setActiveUser(conection)}
                      >
                        <div className="profile-grid-items">
                          <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBob3RvfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80" />
                        </div>
                        <div className="profile-grid-items">
                          <p>{conection?.name}</p>
                          {/* <span>What's up, how are you?</span> */}
                        </div>
                        {/* <div className="profile-grid-items">
                        <div className="counter-alignment">
                          <div className="counter-design">3</div>
                        </div>
                        <div className="time-right-side">
                          <small>03:41 PM</small>
                        </div>
                      </div> */}
                      </div>
                    );
                  })}
            </div>
          </div>

          {/* chat message code start */}

          {roomId && <ChatMessage roomId={roomId} activeUser={activeUser} />}

          {/* chat message code end  */}

          <div className="char-grid-items">
            <div className="profile-close-alignment">
              <p>Profile</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>

            <div className="profile-information">
              <p>Mirabelle Tow</p>
              <span>
                <i>Last seen: Today</i>
              </span>
            </div>
            <div className="full-information">
              <p>
                Lorem ipsum is a pseudo-Latin text used in web design,
                typography, layout, and printing in place of English to
                emphasise design elements over content. It's also called
                placeholder (or filler) text. It's a convenient tool for
                mock-ups.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
