import React, { useContext, useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { getUserInfo } from "../../../utils/user.util";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import renderHTML from "react-render-html";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router";
import Chat from "../chat-app/Chat";
import "./Faq.scss";
import LoaderScroll from "./Loader";
import EndMsg from "./Endmessag";
import InfiniteScroll from "react-infinite-scroll-component";
import ReadMoreReact from "read-more-react";
import ChatRoom from "./ChatRoom";
import Chatuser from "./Chatuser";
import connection from "../Connection/connection";
import { DataLoaded } from "../../../app/App";
import CsvDownload from "react-json-to-csv";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FAQ = () => {
  const { answerData, RoomData, IdAnswer, DataFun } = useContext(DataLoaded);
  const [answer, setAnswer] = answerData;
  const [ChatRoomData, setChatRoomData] = RoomData;
  const [idForAnswer, setIdForAnswer] = IdAnswer;
  const [DateFunctions, setDateFunctions] = DataFun;
  let userInfo = getUserInfo();
  const [openCreate, setOpenCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allFaq, setAllFaq] = useState([]);
  const [errors] = useState([]);
  const [show, setShow] = useState(false);
  const [handleOption, sethandleOption] = useState(false);
  const [faqId, setFaqId] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState("");
  const [dataChange, setDataChange] = useState([]);
  const [openUpdate, setOpenUpdate] = useState();
  const [status, setstatus] = useState();
  const [statusid, setstatusid] = useState();
  const [faqShow, setfaqShow] = useState(false);
  const [titleupdate, settitleupdate] = useState("");
  const [descriptionupdate, setdescriptionupdate] = useState("");
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  // const [idForAnswer, setIdForAnswer] = useState("");
  // // socket connection data
  // const [DateFunctions, setDateFunctions] = useState();

  // const { answerRooms, answers } = connection(
  //   idForAnswer,
  //   DateFunctions,
  //   openUpdate
  // );

  // socket connection data

  const [items, setItems] = useState([]);

  const [hasMore, sethasMore] = useState(true);

  const [page, setpage] = useState(2);

  const history = useHistory();
  const handleCloseCreate = () => {
    setOpenCreate(false);
    settitleupdate("");
    setdescriptionupdate("");
    setgetByUser("");
    setquestionData();
    setChatRoomData();
    // AllAnswer();
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    getFaqData();
  }, [dataChange]);

  const handleUpdateClose = () => {
    settitleupdate("");
    setdescriptionupdate("");
    setOpenUpdate(false);
  };

  //////////////////////////////////////////////////////////////////  get FAQ data//////////////////////////////////////////////////

  const getFaqData = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`question/get-question-admin?page=1&limit=20`)
      .then((res) => {
        console.log("getQuestion", res?.data?.payload?.questions);
        const data = res?.data?.payload?.questions;

        setAllFaq(res?.data?.payload?.questions);
        setUserReport(res?.data?.payload?.questions);

        setItems(res?.data?.payload?.questions);
        console.log("pppppppp", res);
        if (res?.data) {
          setIsLoaderVisible(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoaderVisible(false);
      });
  };

  // const GetQuestionData = async () => {
  //   setIsLoaderVisible(true);
  //   await ApiGet(`question/get-question?page=${page}&limit=20`)
  //     .then((res) => {
  //       console.log("getQuestion", res?.data?.payload?.questions);
  //       setAllFaq(res?.data?.payload?.questions);
  //       setUserReport(res?.data?.payload?.questions);
  //       setItems(res?.data?.payload?.questions);
  //       console.log("pppppppp", res);
  //       setIsLoaderVisible(false);
  //       const data = res?.data?.payload?.questions;
  //       return data;
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       setIsLoaderVisible(false);
  //     });
  // };

  const fetchComments = async () => {
    const res = await ApiGet(
      `question/get-question-admin?page=${page}&limit=20`
    );

    const data = await res?.data?.payload?.questions;
    console.log("getQuestion", res?.data?.payload?.questions);
    // setUserReport(res?.data?.payload?.questions);
    console.log("pppppppp", res);
    if (data) {
      setIsLoaderVisible(false);
    }

    return data;
  };

  ////////////////////////////////////////////////////////////////// delete Policy data///////////////////////////////////////////////

  const handleDataDelete = async () => {
    let FaqData = {
      id: faqId,
    };
    await ApiDelete("faq/delete-faq-content", FaqData)
      .then((res) => {
        handleClose();
        getFaqData();
      })
      .catch((err) => {
        console.log("err");
      });
  };

  //////////////////////////////////////////////////////////////////  add Policy data//////////////////////////////////////////////////  check and uncomment all the function to add update delete

  const handleAddFaq = async (id) => {
    setLoading(true);
    setIsLoaderVisible(true);

    if (title === "" && description == "") {
      settitleupdate(" please Enter Question ");
      setdescriptionupdate(" please Enter Answer ");
      setLoading(false);
    } else if (title === "") {
      settitleupdate("  please Enter Question  ");
      setLoading(false);
    } else if (description === "") {
      setdescriptionupdate(" please Enter Answer ");
      setLoading(false);
    } else {
      let faqId = allFaq && allFaq[0] && allFaq[0]["_id"] && allFaq[0]["_id"];
      let FaqData = {
        id: faqId,
        content: {
          title: title,
          description: description,
        },
      };
      await ApiPost("faq/add-faq-content ", FaqData, {
        "Content-Type": "multipart/form-data",
      })
        .then((res) => {
          console.log("policy", res);
          setLoading(false);
          setTitle("");
          setDescription("");
          setOpenCreate(false);
          setDataChange([...dataChange, "FAQ add"]);
          toast.success("Genre Added Successfully");
          setIsLoaderVisible(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setIsLoaderVisible(false);
        });
    }
  };

  //////////////////////////////////////////////////////////////////  update Policy data//////////////////////////////////////////////// check and uncomment all the function to add update delete

  const handleForUpdateFaq = async (data) => {
    console.log(data);
    setTitle(data.title);
    setDescription(data.description);
    setFaqId(data._id);
  };
  const handleUpdateFaq = async () => {
    setIsLoaderVisible(true);
    setLoading(true);
    if (title === "" && description == "") {
      settitleupdate(" please Enter Question ");
      setdescriptionupdate(" please Enter Answer  ");
      setLoading(false);
    } else if (title === "") {
      settitleupdate(" please Enter Question ");
      setLoading(false);
    } else if (description === "") {
      setdescriptionupdate("please Enter Answer");
      setLoading(false);
    } else {
      const FaqData = {
        title: title,
        description: description,
        id: faqId,
      };
      await ApiPut("faq/update-faq-content", FaqData)
        .then((res) => {
          setLoading(false);
          setOpenUpdate(false);
          getFaqData();
          toast.success(res.data.message);
          setIsLoaderVisible(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.success(err);
          console.log(err);
          setIsLoaderVisible(false);
        });
    }
  };

  const handlealtermessage = (id, status) => {
    setShow(true);
    setstatus(status);
    setstatusid(id);
  };

  const handelUpdateDetails = async () => {
    // if (!formUpdateValidator()) {
    //   console.log("ERROR PUT", errors);
    //   return;
    // }

    setLoading(true);
    setIsLoaderVisible(true);

    await ApiPut(
      `question/update-question?questionId=${statusid}&status=${status}`
    )
      .then((res) => {
        //   window.location.reload();
        console.log(res.data.payload.question.status, "qwafkjhaehjefjkhed");
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        setShow(false);

        toast.success(
          `${
            res.data.payload.question.status == "active" ? "Active" : "Deactive"
          } SuccessFully`
        );
        setIsLoaderVisible(false);
      })
      .catch((err) => {
        setShow(false);
        setLoading(false);
        toast.error(err);

        console.log(err);
        setIsLoaderVisible(false);
      });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // model on off

  const [Response, setResponse] = useState();
  const handleClickOpen = (e, data) => {
    setDateFunctions(data._id);
    setResponse(data);
    if (data._id) {
      AllAnswer(data._id);
      setOpenCreate(true);
    }

    // window.reload();
    history.push({
      pathname: "/messages",
      // state: { detail: updateAnswerLater },
      state: {
        QuestionId: data?._id,
        QuestionData: data,
      },
    });
    e.preventDefault();
    // history.push("/chat");
  };

  const [questionData, setquestionData] = useState();
  // const [answer, setAnswer] = useState();

  const AllAnswer = async (id) => {
    setIsLoaderVisible(true);
    if (id) {
      await ApiGet(`answer/${id}`)
        .then((res) => {
          setAnswer(res.data.payload.answer);

          console.log("00000000000000000000000", res.data);

          // // setquestionData(res?.data?.payload?.answer);
          // // setAllFaq(res.data.payload.questionDetais);
          // // console.log("pppppppp", res);
          // // setIsLoaderVisible(false);
          // const passengerList = [];
          // for (let i = 0; i < res.data.payload.answer.length; i++) {
          //   const element = res.data.payload.answer;

          //   if (element[i].createdBy.email == element[i].createdBy.email) {
          //     passengerList.push(element[i].createdBy.email);
          //   }
          // }
          // return passengerList;
          setIsLoaderVisible(false);
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoaderVisible(false);
        });
    } else {
      setIsLoaderVisible(true);
    }
  };

  useEffect(() => {
    AllAnswer();
  }, []);

  const [getByUser, setgetByUser] = useState();
  // const [ChatRoomData, setChatRoomData] = useState();

  const AllGetData = (data) => {
    setChatRoomData(data);
    // setChatRoom(data);
    // setgetByUser(data);
    console.log("///////", data);
  };

  console.log(handleOption, "handleOption");
  const [userReport, setUserReport] = useState();
  const [filteredReport, setFilteredReport] = useState();

  const [searchValue, setSearchValue] = useState();

  // useEffect(() => {
  //   searchQus();
  // }, []);
  // const searchQus = async (e) => {
  //   e.preventDefault();
  //   setIsLoaderVisible(true);

  //   await ApiGet("question/how")
  //     .then((res) => {
  //       console.log("search", res);
  //       // setIsLoaderVisible(false);
  //       // setUserReport(res.data.payload.question);
  //     })
  //     .catch((err) => {
  //       setIsLoaderVisible(false);
  //       console.log(err);
  //     });
  // };

  console.log("userReportuserReportuserReport", userReport);

  const handleSearch = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setinputValue(value);
    setSearchValue(value);
    setItems(() =>
      userReport?.filter(
        (item) =>
          item?.question?.toLowerCase().includes(value) ||
          item?.status?.toLowerCase().includes(value) ||
          item?.createdBy?.name?.toLowerCase().includes(value)
        // item?.response?.toLowerCase().includes(value) ||
        // item?.view?.toLowerCase().includes(value) ||
        // item?.createdBy?.name?.toLowerCase().includes(value)
        // item?.phone?.toLowerCase().includes(value)
      )
    );
  };

  const [inputValue, setinputValue] = useState("");

  const debouncedSearchTerm = useDebounce(inputValue, 500);

  // Hook
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  // useEffect(() => {
  //   if (debouncedSearchTerm) {
  //     setIsLoaderVisible(true);
  //     setpage(2);
  //     sethasMore(true);
  //     searchQuestionData();
  //   } else {
  //     fetchData();
  //   }
  // }, [debouncedSearchTerm]);

  const [donationCsvAdmin, setDonationCsvAdmin] = useState([]);
  const [dataCSVAdmin, setDataCSVAdmin] = useState([]);

  // useEffect(() => {
  //   if (donationCsvAdmin) {
  //     const data = donationCsvAdmin?.map((donation) => {
  //       let data = {
  //         Question: donation?.question,
  //         DisplayProfile: donation?.displayProfile,
  //         Dropdown: donation?.dropdown,
  //         Experience: donation?.experience,
  //         ReportAbuse: donation?.reportAbuse,
  //         Response: donation?.response,
  //         Status: donation?.status,
  //         UserName: donation?.createdBy?.name,
  //         UserProfile: donation?.createdBy?.profileImage,
  //         Date: moment(donation?.createdAt).utc().format("MMM Do YYYY, h:mm a"),
  //         UserRole: donation?.createdBy?.currentRole,
  //         LastModified: moment(donation?.updatedAt)
  //           .utc()
  //           .local()
  //           .format("MMM Do YYYY, h:mm a"),
  //         // Status:
  //         //   donation?.status == true
  //         //     ? "Active"
  //         //       ? donation?.status == false
  //         //       : "Deactive"
  //         //     : "",
  //       };
  //       setDataCSVAdmin((currVal) => [...currVal, data]);
  //     });
  //   }
  // }, [donationCsvAdmin]);

  const searchQuestionData = () => {
    if (inputValue) {
      ApiGet(`question/search-question?search=${inputValue}`)
        .then((res) => {
          // console.log(res.data.payload, "TTTTTTT");
          if (res?.data?.payload && res?.status === 200) {
            console.log("QQQQQQQQQQQQQ", res);
            setItems(res?.data?.payload);
            setIsLoaderVisible(false);
            // console.log(res.data.payload, "res.data.payload");
          }
        })
        .catch((err) => {
          toast.error(err?.message, { theme: "colored" });
          setIsLoaderVisible(false);
        });
    } else {
      fetchData();
    }
  };

  const fetchData = async () => {
    const commentsFormServer = await fetchComments();

    console.log(commentsFormServer, "commentsFormServer");
    setItems([...items, ...commentsFormServer]);
    setUserReport([...items, ...commentsFormServer]);
    if (commentsFormServer.length === 0 || commentsFormServer.length < 20) {
      sethasMore(false);
    }
    setpage(page + 1);
  };

  useEffect(() => {
    (async () => {
      await ApiGet(`question/get-question-admin-all`)
        .then((res) => {
          // console.log(res.data.payload, "TTTTTTT");
          if (res?.data?.payload && res?.status === 200) {
            console.log("AAASSSSSDDDFFF", res);
            // setItems(res?.data?.payload);
            setDonationCsvAdmin(res?.data?.payload?.questions);
            // setDataCSVAdmin(res?.data?.payload?.questions);
            setIsLoaderVisible(false);
            // console.log(res.data.payload, "res.data.payload");
          }
        })
        .catch((err) => {
          toast.error(err?.message, { theme: "colored" });
          setIsLoaderVisible(false);
        });
    })();
  }, []);

  // search Api

  return (
    <>
      <ToastContainer />
      <ReactTooltip />

      <InfiniteScroll
        dataLength={items?.length} //This is important field to render the next data
        next={fetchData}
        hasMore={hasMore}
        loader={!isLoaderVisible && <LoaderScroll />}
        // endMessage={<EndMsg />}
      >
        <div>
          <div className="card p-5">
            <div className="mb-5">
              <div className="d-flex justify-content-between">
                <h3>Questions Details</h3>

                {/* <div>
                  <Button
                    className="bg-primary"
                    onClick={() => {
                      setTitle("");
                      setDescription("");
                      setOpenCreate(true);
                    }}
                  >
                    Add New F.A.Q.
                  </Button>
                </div> */}
                <div className="col-5 ">
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    placeholder="Search Question"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
                <CsvDownload
                  className={``}
                  data={dataCSVAdmin}
                  filename="question.csv"
                  style={{
                    //pass other props, like styles
                    backgroundColor: "#F64E60",
                    borderRadius: "6px",
                    border: "1px solid #fff",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "#FFFFFF",
                    fontSize: "12px",
                    padding: "10px 18px",
                    textDecoration: "none",
                    position: "right",
                  }}
                >
                  Export to Csv
                </CsvDownload>
              </div>

              <Loader
                style={{ display: "flex", justifyContent: "center" }}
                type="Puff"
                color="#334D52"
                height={30}
                width={30}
                visible={isLoaderVisible}
              />

              <div className="pt-10">
                {/* //////////////////////////////////////////////// Component ////////////////////////////////////////////////*/}

                <div className=" ">
                  <div className="d-flex justify-content-center"></div>

                  {items?.length > 0 ? (
                    items?.map((data, key) => {
                      return (
                        <div className="pb-5 pt-2 " key={key}>
                          <div className="card body pt-5 pl-8 pr-8 pb-4 shadow p-3 mb-5 bg-white rounded">
                            <div className="row row-cols-2 m-1 h3">
                              <strong>Question : {++key}</strong>
                              <div className="d-flex justify-content-end">
                                <div className="d-flex ">
                                  {/*  */}

                                  <div className="cursor-pointer">
                                    <div className="cursor-pointer ml-5 mt-1">
                                      <Button
                                        onClick={(e) => {
                                          // history.push({
                                          //   pathname: "/messages",
                                          //   // state: { detail: updateAnswerLater },
                                          //   state: {
                                          //     QuestionId: data?._id,
                                          //   },
                                          // });
                                          // history.push("/messages");
                                          handleClickOpen(e, data);
                                        }}
                                        className=""
                                        style={{
                                          border: "none",
                                          backgroundColor: "#383434",
                                          height: "35px",
                                          marginBottom: "10px",
                                          paddingLeft: "21px",
                                          paddingRight: "21px",
                                        }}
                                      >
                                        View Answer
                                      </Button>
                                    </div>
                                  </div>

                                  {/*  */}

                                  <div className="cursor-pointer">
                                    {data?.status === "inactive" && (
                                      <div className="cursor-pointer ml-5 mt-1">
                                        <button
                                          onClick={() => {
                                            handlealtermessage(
                                              data._id,
                                              "active"
                                            );
                                          }}
                                          className="btn btn-success"
                                          style={{
                                            border: "none",
                                            height: "35px",
                                            marginBottom: "10px",
                                            paddingLeft: "21px",
                                            paddingRight: "21px",
                                          }}
                                        >
                                          Active
                                        </button>
                                      </div>
                                    )}

                                    {data.status === "active" && (
                                      <div className="cursor-pointer ml-5 mt-1">
                                        <Button
                                          onClick={() => {
                                            handlealtermessage(
                                              data._id,
                                              "inactive"
                                            );
                                          }}
                                          style={{
                                            backgroundColor: "red",
                                            border: "none",
                                            height: "35px",
                                          }}
                                        >
                                          Deactive
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div>
                              <div className="row row-cols-2 m-1">
                                <div className="col-2">
                                  <strong>Question</strong>
                                </div>
                                <div className="col-10">{data?.question}</div>
                              </div>
                              {/* <div className="row row-cols-2 m-1">
                                <div className="col-2">
                                  <strong>Created By</strong>
                                </div>
                                {/* <div className="col-10">
                                  {renderHTML(data?.createdBy.name)
                                    ? renderHTML(data?.createdBy.name)
                                    : ""}
                                  {data?.username}
                                </div> */}
                              {/* </div> */}
                              <div className="row row-cols-2 m-1">
                                <div className="col-2">
                                  <strong>Created On</strong>
                                </div>
                                <div className="col-10">
                                  {moment(data?.createdAt).format(
                                    " Do MMMM  YYYY, h:mm:ss a"
                                  )}
                                </div>
                              </div>
                              <div className="row row-cols-2 m-1">
                                <div className="col-2">
                                  <strong>Reach</strong>
                                </div>
                                <div className="col-10">{data?.reach}</div>
                              </div>
                              <div className="row row-cols-2 m-1">
                                <div className="col-2">
                                  <strong>Responses</strong>
                                </div>
                                <div className="col-10">{data?.response}</div>
                              </div>
                              <div className="row row-cols-2 m-1">
                                <div className="col-2">
                                  <strong>Status</strong>
                                </div>
                                <div className="col-10">{data?.status}</div>
                              </div>

                              <div className="row row-cols-2 m-1">
                                <div className="col-2">
                                  <strong>CreatedBy</strong>
                                </div>
                                <div className="col-10">
                                  {data?.createdBy?.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      {isLoaderVisible === "ture" && (
                        <p>No question details found</p>
                      )}
                    </>
                  )}
                </div>
                {/* //////////////////////////////////////////////// Component end ////////////////////////////////////////////////*/}
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to{" "}
            {status == "active" ? "Active" : "Deactive"} this Question?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => handelUpdateDetails()}>
              {status == true && !loading ? "Active" : "Deactive"}
              {loading && <span className="mx-3 spinner spinner-white"></span>}
            </Button>
          </Modal.Footer>
        </Modal>

        <Dialog
          fullScreen
          open={openUpdate}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleUpdateClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>

          <List>
            <div className="form ml-30 ">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Question
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />

                    <div></div>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["title"]}
                    <h5 className="text-danger pt-2">{titleupdate}</h5>
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Answer
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) => {
                        setDescription(editor.getData());
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["description"]}
                    <h5 className="text-danger pt-2">{descriptionupdate}</h5>
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={() => {
                    handleUpdateFaq();
                  }}
                  className="btn btn-success mr-2"
                >
                  <span>Update F.A.Q.</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          </List>
        </Dialog>
      </InfiniteScroll>
    </>
  );
};

export default FAQ;
