import React, { useContext, useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";

import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { getUserInfo } from "../../../utils/user.util";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import renderHTML from "react-render-html";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router";
// import Chat from "../chat-app/Chat";
// import "../FAQ/FAQ.scss";
// import LoaderScroll from "./Loader";
// import EndMsg from "./Endmessag";
import InfiniteScroll from "react-infinite-scroll-component";
import ReadMoreReact from "read-more-react";
// import ChatRoom from "./ChatRoom";
// import Chatuser from "./Chatuser";
// import connection from "../Connection/connection";
import { DataLoaded } from "../../../app/App";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FAQ = () => {
  const { answerData, RoomData, IdAnswer, DataFun } = useContext(DataLoaded);
  const [answer, setAnswer] = answerData;
  const [ChatRoomData, setChatRoomData] = RoomData;
  const [idForAnswer, setIdForAnswer] = IdAnswer;
  const [DateFunctions, setDateFunctions] = DataFun;
  let userInfo = getUserInfo();
  const [openCreate, setOpenCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allFaq, setAllFaq] = useState([]);
  const [errors] = useState([]);
  const [show, setShow] = useState(false);
  const [handleOption, sethandleOption] = useState(false);
  const [faqId, setFaqId] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState("");
  const [dataChange, setDataChange] = useState([]);
  const [openUpdate, setOpenUpdate] = useState();
  const [status, setstatus] = useState();
  const [statusid, setstatusid] = useState();
  const [faqShow, setfaqShow] = useState(false);
  const [titleupdate, settitleupdate] = useState("");
  const [descriptionupdate, setdescriptionupdate] = useState("");
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isLoaderVisibleData, SetIsLoaderVisibleData] = useState(false);
  const [typeFilter, setTypeFilter] = useState(false);
  const [abouseQuestionData, setAbouseQuestionData] = useState();

  // const [idForAnswer, setIdForAnswer] = useState("");
  // // socket connection data
  // const [DateFunctions, setDateFunctions] = useState();

  // const { answerRooms, answers } = connection(
  //   idForAnswer,
  //   DateFunctions,
  //   openUpdate
  // );

  // socket connection data

  const [items, setItems] = useState([]);

  const [hasMore, sethasMore] = useState(true);

  const [page, setpage] = useState(2);

  const history = useHistory();
  const handleCloseCreate = () => {
    setOpenCreate(false);
    settitleupdate("");
    setdescriptionupdate("");
    setgetByUser("");
    setquestionData();
    setChatRoomData();
    setStatusAnswer();
    setUserId();
    setAnswerId();
    // AllAnswer();
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    getFaqData();
  }, [dataChange]);

  const handleUpdateClose = () => {
    settitleupdate("");
    setdescriptionupdate("");
    setOpenUpdate(false);
  };

  //////////////////////////////////////////////////////////////////  get FAQ data//////////////////////////////////////////////////

  const getFaqData = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`answer/get-abuse-question`)
      .then((res) => {
        const data = res?.data?.payload;

        setAllFaq(res?.data?.payload?.getReportAbuse);
        setUserReport(res?.data?.payload?.getReportAbuse);

        setItems(res?.data?.payload?.getReportAbuse);
        console.log("pppppppp", res);
        if (res?.data) {
          setIsLoaderVisible(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoaderVisible(false);
      });
  };

  const getAbuseQuestionsById = async (row) => {
    console.log("roww@@@@@@@@@@@@", row);
    SetIsLoaderVisibleData(true);

    await ApiGet(
      `answer/get-abuse-question?questionId=${row?.abuseAnswer?.answerId?._id}`
    )
      .then((res) => {
        console.log("dfsdfsdfdsfds", res?.data?.payload?.users);
        setAbouseQuestionData(res?.data?.payload?.users);
        setUserReportData(res?.data?.payload?.users);
        SetIsLoaderVisibleData(false);
      })
      .catch((err) => {
        console.log("err", err);
        SetIsLoaderVisibleData(false);

        // setAbouseQuestionData(err)
        // setIsLoaderVisible(false);
      });
  };

  const fetchComments = async () => {
    const res = await ApiGet(
      `question/get-question-admin?page=${page}&limit=20`
    );

    const data = await res?.data?.payload?.questions;
    console.log("getQuestion", res?.data?.payload?.questions);
    // setUserReport(res?.data?.payload?.questions);
    console.log("pppppppp", res);
    if (data) {
      setIsLoaderVisible(false);
    }

    return data;
  };

  const handelUpdateDetails = async () => {
    // if (!formUpdateValidator()) {
    //   console.log("ERROR PUT", errors);
    //   return;
    // }

    setLoading(true);
    setIsLoaderVisible(true);

    await ApiPut(
      `question/update-question?questionId=${statusid}&status=${status}`
    )
      .then((res) => {
        //   window.location.reload();
        console.log(res.data.payload.question.status, "qwafkjhaehjefjkhed");
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        setShow(false);

        toast.success(
          `${
            res.data.payload.question.status == "active" ? "Active" : "Deactive"
          } SuccessFully`
        );
        setIsLoaderVisible(false);
      })
      .catch((err) => {
        setShow(false);
        setLoading(false);
        toast.error(err);

        console.log(err);
        setIsLoaderVisible(false);
      });
  };

  const DeleteAnswer = async (id, userId) => {
    setIsLoaderVisible(true);
    await ApiDelete(`answer/delete-answer/id=${id}/userId=${userId}`)
      .then((res) => {
        setDataChange([...dataChange, "Role Updated"]);
        setIsLoaderVisible(false);
        setStatusAnswer();
        setUserId();
        setAnswerId();
        setOpenCreate(false);
        toast.success("Delete SuccessFully");
      })
      .catch((err) => {
        // setIsLoaderVisible(false);
        toast.error(err);
      });
  };

  const RejectAnswer = async (id, userId) => {
    setIsLoaderVisible(true);
    await ApiDelete(`answer/reject-answer/id=${id}/userId=${userId}`)
      .then((res) => {
        setDataChange([...dataChange, "Role Updated"]);
        setIsLoaderVisible(false);
        setStatusAnswer();
        setUserId();
        setAnswerId();
        setOpenCreate(false);
        toast.success("Reject SuccessFully");
      })
      .catch((err) => {
        // setIsLoaderVisible(false);
        toast.error(err);
      });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // model on off

  const [Response, setResponse] = useState();
  const handleClickOpen = (e, data) => {
    console.log("@@@@@@@@@@@@@", data);
    e.preventDefault();
    setDateFunctions(data.questionId._id);
    setOpenCreate(true);
    setResponse(data?.questionId);
    // if (data._id) {
    //   AllAnswer(data._id);
    //
    // }
    // history.push("/chat");
  };

  const [questionData, setquestionData] = useState();
  // const [answer, setAnswer] = useState();

  const AllAnswer = async () => {
    setIsLoaderVisible(true);
    // if (id) {
    await ApiPost(
      `question/accept-reported-question?questionId=${DateFunctions}&status=${
        Response?.status == "active" ? "inactive" : "active"
      }`
    )
      .then((res) => {
        // setAnswer(res.data.payload.answer);

        setOpenCreate(false);
        getFaqData();
        console.log("00000000000000000000000", res.data);

        // // setquestionData(res?.data?.payload?.answer);
        // // setAllFaq(res.data.payload.questionDetais);
        // // console.log("pppppppp", res);
        // // setIsLoaderVisible(false);
        // const passengerList = [];
        // for (let i = 0; i < res.data.payload.answer.length; i++) {
        //   const element = res.data.payload.answer;

        //   if (element[i].createdBy.email == element[i].createdBy.email) {
        //     passengerList.push(element[i].createdBy.email);
        //   }
        // }
        // return passengerList;

        setIsLoaderVisible(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoaderVisible(false);
      });
    // } else {
    //   setIsLoaderVisible(true);
    // }
  };

  let pathname = window.location.pathname;
  useEffect(() => {
    if (pathname == "/abuseanswer/1") {
      setTypeFilter(false);
    }
  }, [pathname == "/abuseanswer/1"]);

  const [getByUser, setgetByUser] = useState();
  // const [ChatRoomData, setChatRoomData] = useState();

  console.log(handleOption, "handleOption");
  const [userReport, setUserReport] = useState();
  const [userReportData, setUserReportData] = useState();
  const [filteredReport, setFilteredReport] = useState();
  const [ItemData, setItemData] = useState();

  const [searchValue, setSearchValue] = useState();
  const [searchValueData, setSearchValueData] = useState();
  const [answerId, setAnswerId] = useState();
  const [userId, setUserId] = useState();
  const [statusAnswer, setStatusAnswer] = useState();

  const handleSearch = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setSearchValue(value);
    console.log("userReportuserReportuserReport", userReport);
    setItems(() =>
      userReport?.filter(
        (item) =>
          item?.abuseAnswer?.answerId?.answer?.toLowerCase()?.includes(value) ||
          item?.abuseAnswer?.answerId?.question?.question
            .toLowerCase()
            ?.includes(value) ||
          item?._id[0]?.name?.toLowerCase()?.includes(value) ||
          // item?.answerId?.question?.response.toLowerCase()?.includes(value) ||
          item?.abuseAnswer?.reason.toLowerCase()?.includes(value)
      )
    );
  };

  const handleClickOpenModel = (e, answerId, useId, status) => {
    e.preventDefault();
    setStatusAnswer(status);
    setUserId(useId);
    setAnswerId(answerId);

    setOpenCreate(true);
  };

  const handleSearchAbuse = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setSearchValueData(value);
    setAbouseQuestionData(() =>
      userReportData?.filter(
        (item) =>
          item?.email?.toLowerCase()?.includes(value) ||
          item?.name?.toLowerCase()?.includes(value) ||
          item?.region?.toLowerCase()?.includes(value)
      )
    );
  };

  const fetchData = async () => {
    const commentsFormServer = await fetchComments();

    console.log(commentsFormServer, "commentsFormServer");
    setItems([...items, ...commentsFormServer]);
    setUserReport([...items, ...commentsFormServer]);
    if (commentsFormServer.length === 0 || commentsFormServer.length < 20) {
      sethasMore(false);
    }
    setpage(page + 1);
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "5%",
    },
    // {
    //   name: "Create by",
    //   selector: (row) => row?.questionId?.createdBy?.name,
    //   sortable: true,
    //   width: "10%",
    // },

    {
      name: "Date",
      selector: (row) => row?.createdAt,
      cell: (row) => {
        return (
          <div className="showmore-class text-justify  p-2">
            {moment(row?.createdAt).utc().local().format("MMM Do YYYY, h:mm a")}
          </div>
        );
      },

      sortable: true,
      // width: "16%",
    },

    {
      name: "Created by",
      selector: (row) => row?.createdAt,
      cell: (row) => {
        return (
          <div className="showmore-class text-justify  p-2">
            {console.log("row@@@@@@@@@@@@", row)}
            {row?._id[0]?.name}
          </div>
        );
      },

      sortable: true,
      // width: "16%",
    },

    {
      name: "Role",
      selector: (row) => row?.createdAt,
      cell: (row) => {
        return (
          <div className="showmore-class text-justify  p-2">
            {row?._id[0]?.currentRole}
          </div>
        );
      },

      sortable: true,
      // width: "16%",
    },

    {
      name: "Question",
      selector: (row) => row?.abuseAnswer.answerId?.question?.question,
      cell: (row) => {
        return (
          <div className="showmore-class text-justify  p-2">
            {!row?.abuseAnswer?.answerId?.question?.question
              ? "-"
              : row?.abuseAnswer?.answerId?.question?.question}
            {console.log("row@@@@@@@@@@@@", row?.answerId?.question?.question)}
          </div>
        );
      },
      width: "50%",
    },

    {
      name: "Answer",
      selector: (row) => row?.abuseAnswer?.answerId?.answer,
      // width: "8%",
    },

    {
      name: "Response",
      selector: (row) => row?.abuseAnswer?.answerId?.question?.response,
      // width: "8%",
    },
    {
      name: "Reason",
      selector: (row) => row?.abuseAnswer?.reason,
      cell: (row) => {
        return (
          <div className="showmore-class text-justify  p-2">
            {!row?.abuseAnswer?.reason ? "-" : row?.abuseAnswer?.reason}
          </div>
        );
      },
      sortable: true,
      // width: "20%",
    },
    {
      name: "Actions",
      width: "12%",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              {console.log("row@@@@@@@@@@@@@@", row)}
              {/* <div className="d-flex justify-content-between">
                <Button
                  style={{ color: "white" }}
                  className="btn btn-danger"
                  onClick={(e) => {
                    history.push(`/abuse/${row?.answerId?.question?._id}`);
                    getAbuseQuestionsById(row);
                    setTypeFilter(true);
                  }}
                ></Button>
              </div> */}

              {/* <div className="d-flex justify-content-between">
                <Button
                  style={{ color: "white" }}
                  className="btn btn-danger"
                  onClick={(e) => {
                    // history.push(`/abuse/${row?.answerId?.question?._id}`);
                    // getAbuseQuestionsById(row);
                    DeleteAnswer(row?.abuseAnswer?.answerId?._id);
                    // setTypeFilter(true);
                  }}
                >
                  Accept
                </Button>
              </div> */}

              {/* <div className="cursor-pointer ml-5 mt-1">
                <ReactTooltip id="registerTip" place="top" effect="solid">
                  Reported by
                </ReactTooltip>
                <i
                  className="fa fa-info-circle  fa-lg mt-3 mr-2"
                  data-tip
                  data-for="registerTip"
                  style={{ color: "black" }}
                  onClick={(e) => {
                    history.push(`/abuseanswer/${row?.answerId?._id}`);
                    getAbuseQuestionsById(row);
                    setTypeFilter(true);
                  }}
                ></i>
              </div> */}
              {/* {row?.questionId?.status == "active" ? (
                <Button
                  className="btn btn-success"
                  style={{ width: "80px" }}
                  onClick={(e) => handleClickOpen(e, row)}
                >
                  {"Accept"}
                </Button>
              ) : (
                <Button
                  className="btn btn-danger"
                  style={{ width: "80px" }}
                  onClick={(e) => handleClickOpen(e, row)}
                >
                  {"Reject"}
                </Button>
              )} */}

              {/* <button
                className="btn btn-success"
                onClick={(e) => {
                  handleClickOpen(e, row);
                }}
              >
                {row?.questionId?.status == "active" ? "Accept" : "Reject"}
              </button> */}
            </div>

            <div
              className="d-flex justify-content-between"
              style={{ gap: "10px" }}
            >
              <Button
                style={{ color: "white" }}
                className="btn btn-success"
                onClick={(e) => {
                  // history.push(`/abuse/${row?.answerId?.question?._id}`);
                  // getAbuseQuestionsById(row);
                  // DeleteAnswer(
                  //   row?.abuseAnswer?.answerId?._id,
                  //   row?._id[0]?._id
                  // );

                  handleClickOpenModel(
                    e,
                    row?.abuseAnswer?.answerId?._id,
                    row?._id[0]?._id,
                    "accept"
                  );
                  // setTypeFilter(true);
                }}
              >
                Accept
              </Button>

              <Button
                style={{ color: "white" }}
                className="btn btn-danger"
                onClick={(e) => {
                  // history.push(`/abuse/${row?.answerId?.question?._id}`);
                  // getAbuseQuestionsById(row);
                  // RejectAnswer(
                  //   row?.abuseAnswer?.answerId?._id,
                  //   row?._id[0]?._id
                  // );

                  handleClickOpenModel(
                    e,
                    row?.abuseAnswer?.answerId?._id,
                    row?._id[0]?._id,
                    "reject"
                  );
                  // setTypeFilter(true);
                }}
              >
                Reject
              </Button>
            </div>
          </>
        );
      },
      sortable: true,
    },
  ];
  const columnsData = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      // selector: (row) => row?.name,
      cell: (row) => {
        return (
          <div className="showmore-class text-justify  p-2">
            {!row?.name ? "-" : row?.name}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "email",
      // selector: (row) => row?.name,
      cell: (row) => {
        return (
          <div className="showmore-class text-justify  p-2">
            {!row?.email ? "-" : row?.email}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Region",
      cell: (row) => {
        return (
          <div className="showmore-class text-justify  p-2">
            {!row?.region ? "-" : row?.region}
          </div>
        );
      },
      sortable: true,
    },
  ];

  return (
    <>
      <ToastContainer />
      <ReactTooltip />

      {!typeFilter ? (
        <div className="card p-1">
          <div className="p-2 mb-2">
            <div className="row mb-4 pr-3">
              <div className="col d-flex justify-content-between">
                <h2 className="pl-3 pt-2">Reported Answers</h2>
              </div>
              <div className="col">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    placeholder="Reported Answers"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          {console.log("SSSSSSSSSSSASSSSSSSSS", items)}
          <DataTable
            columns={columns}
            data={items}
            pagination
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={
              <Loader type="Puff" color="#334D52" height={30} width={30} />
            }
          />
        </div>
      ) : (
        <div className="card p-1">
          <div className="p-2 mb-2">
            <div className="row mb-4 pr-3">
              <div className="col d-flex justify-content-between">
                <h2 className="pl-3 pt-2">Reported Answers by users</h2>
              </div>
              <div className="col">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    placeholder="Reported Questions"
                    onChange={(e) => handleSearchAbuse(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <DataTable
            columns={columnsData}
            data={abouseQuestionData}
            pagination
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisibleData}
            progressComponent={
              <Loader type="Puff" color="#334D52" height={30} width={30} />
            }
          />
        </div>
      )}
      {/* sfjksfksdfhsdkjfkd */}

      <Modal show={openCreate} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to{" "}
          {statusAnswer == "accept" ? "Accept" : "Reject"} this question as
          abuse ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreate}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={(e) => {
              if (statusAnswer == "accept") {
                DeleteAnswer(answerId, userId);
              } else {
                RejectAnswer(answerId, userId);
              }
            }}
            disabled={isLoaderVisible}
          >
            {statusAnswer == "accept" ? "Accept" : "Reject"}
            {isLoaderVisible && (
              <span className="mx-3 spinner spinner-white"></span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FAQ;
