import React from 'react'
import './Sitesetting.scss'
import { CKEditor } from "@ckeditor/ckeditor5-react";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function Sitesetting() {
    return (
        <div>
            <div className="site-setting-card-alignment">
                <div className="form-grid">
                    <div className="form-grid-items">
                        <label>Site Name<span>*</span></label>
                    </div>
                    <div className="form-grid-items">
                        <input type="text" placeholder="Enter Site name.."/>
                    </div>
                </div>
                <div className="form-grid">
                    <div className="form-grid-items">
                        <label>Subscription Price<span>*</span></label>
                    </div>
                    <div className="form-grid-items">
                        <input type="text" placeholder="Enter Subscription Price.."/>
                    </div>
                </div>
                <div className="form-grid">
                    <div className="form-grid-items">
                        <label>Homepage top description<span>*</span></label>
                    </div>
                    <div className="form-grid-items">
                    <textarea id="w3review" name="w3review"></textarea>
                    </div>
                </div>
                <div className="form-grid">
                    <div className="form-grid-items">
                        <label>Homepage second description<span>*</span></label>
                    </div>
                    <div className="form-grid-items">
                        <CKEditor editor={ClassicEditor}/>
                    </div>
                </div>
                <div className="form-grid">
                    <div className="form-grid-items">
                        <label>Homepage pricing description<span>*</span></label>
                    </div>
                    <div className="form-grid-items">
                    <textarea id="w3review" name="w3review"></textarea>
                    </div>
                </div>
                <div className="form-grid">
                    <div className="form-grid-items">
                        <label>Homepage pricing description<span>*</span></label>
                    </div>
                    <div className="form-grid-items">
                        <div className="button-alignment">
                            <button className="submit-button-style">Submit</button>
                            <button className="cancel-button-style">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>

            <div className="site-setting-card-alignment">
                <div className="cus-table-grid">
                    <div className="cus-table-grid-items">
                        <p>Hello world</p>
                    </div>
                    <div className="cus-table-grid-items">
                    <p>Hello world</p>
                    </div>
                </div>
            </div>
             
      </div>
    )
}

export default Sitesetting
