export const STORAGEKEY = {
  token: "token",
  authData: "authData",
  userData: "userData",
  layoutData: "layoutData",
};

export const AppSettings = {
  defaultNavigation: "/dashboard",
};
