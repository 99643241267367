import React, { useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DataTable, { defaultThemes } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import { Modal } from "react-bootstrap";
import { Dialog } from "@material-ui/core";
import { List } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Slide } from "@material-ui/core";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import moment from "moment";
import Profile from "../User/Profile";
import ReactTooltip from "react-tooltip";
import CsvDownload from "react-json-to-csv";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const organigationRoleList = [
  "Assistant Director",
  "Assistant Vice President",
  "Associate Vice President",
  "Chief Executive Officer",
  "Chief Financial Officer",
  "Chief Operating Officer",
  "Co-Founder",
  "Director",
  "Executive Vice President",
  "Founder",
  "Manager",
  "Partner",
  "President",
  "Product Manager",
  "Senior Director",
  "Senior Vice President",
  "Vice President",
  "Other",
];

const VerificationStatus = (props) => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [pid, setPid] = useState();
  const [updateProduct, setUpdateProduct] = useState(false);
  const [submitValue, setSubmitValue] = useState(false);
  const [initData, setInitdata] = useState();
  const [pwdErr, setPwdErr] = useState("");
  const [status, setstatus] = useState();
  const [statusid, setstatusid] = useState();
  const [data, setData] = useState({});

  const [roleName, setRoleName] = useState();
  const [description, setDescription] = useState();
  const [active, setactive] = useState(true);

  const [roleNameUpdate, setRoleNameUpdate] = useState();
  const [roleUpdate, setRoleupdate] = useState();
  const [descriptionUpdate, setDescriptionUpdate] = useState();
  const [msg, setMsg] = useState("");

  const [updateDetails, setUpdateDetails] = useState(false);

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [Persondata, setPersondata] = useState();

  // const [errors] = useState([]);
  const [userReport, setUserReport] = useState();
  const [filteredReport, setFilteredReport] = useState();
  const [UserEmail, setUserEmail] = useState();
  const [errors, setErrors] = useState({});
  let userInfo = getUserInfo();
  // console.log(userInfo.user.id);
  const [show, setShow] = useState(false);

  const [donationCsvAdmin, setDonationCsvAdmin] = useState([]);
  const [dataCSVAdmin, setDataCSVAdmin] = useState([]);

  useEffect(() => {
    (async () => {
      if (donationCsvAdmin) {
        await donationCsvAdmin.map((donation) => {
          let data = {
            Email: donation?.email,
            Name: donation?.name,
            Date: moment(donation?.createdAt)
              .utc()
              .format("MMM Do YYYY, h:mm a"),
            Role: donation?.currentRole,
            QuestionCount: donation?.questionCount,
            AnswerCount: donation?.answerCount,
            Gender: donation?.gender,
            HowDidFind: donation?.howDidFind,
            LinkedinProfile: donation?.linkedinProfile,
            OrganizationName: donation?.organizationName,
            OrganizationWebsite: donation?.organizationWebsite,
            ProfileImage: donation?.profileImage,
            Region: donation?.region,
            // subject: donation.subject,

            LastModified: moment(donation?.updatedAt)
              .utc()
              .local()
              .format("MMM Do YYYY, h:mm a"),
            Status:
              donation?.status == true
                ? "Active"
                  ? donation?.status == false
                  : "Deactive"
                : "",
          };
          setDataCSVAdmin((currVal) => [...currVal, data]);
        });
      }
    })();

    console.log("UsertCsvReport", donationCsvAdmin);
  }, [donationCsvAdmin]);

  const handleClickOpen = () => {
    setOpen(true);
    setSubmitValue(false);
    setUpdateProduct(true);
  };

  const handleClose1 = () => {
    setOpen(false);
    setErrors({});
  };

  const [profile, setProfile] = useState(false);

  const handleProfileClose = () => {
    setProfile(false);
    setErrors({});
  };

  const handleClose = () => {
    setShow(false);
    setErrors({});
  };

  const handleShow = (id) => {
    setShow(true);
    setPid(id);
  };

  useEffect(() => {
    rolegetApi();
  }, [dataChange]);

  const rolegetApi = async () => {
    setIsLoaderVisible(true);
    await ApiGet("user/get-verification-status")
      .then((res) => {
        console.log("AAAAAAAAAAAAAAAAAAAA", res);
        setRoleData(res.data.payload);
        setFilteredReport(res.data.payload);
        setUserReport(res.data.payload);
        setDonationCsvAdmin(res.data.payload);
        setIsLoaderVisible(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoaderVisible(false);
      });
  };

  const handleDeleteManagerInfo = async () => {
    await ApiDelete(`role/id=${pid}`)
      .then((res) => {
        setDataChange([...dataChange, "Payment delete"]);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const handleForUpdateManager = async (id) => {
    setOpen(true);
    setPid(id);
    setSubmitValue(true);
    let temp = roleData.filter((data) => {
      return data._id === id;
    });
    setRoleNameUpdate(temp[0].roleName);
    setDescriptionUpdate(temp[0].description);
  };

  const handelUpdateDetails = async () => {
    if (!formUpdateValidator()) {
      console.log("ERROR PUT", errors);
      return;
    }

    setLoading(true);

    let data = {
      name: roleNameUpdate,
      phone: descriptionUpdate,
      email: UserEmail,
      currentRole: roleUpdate,
    };

    await ApiPut(`user`, data)
      .then((res) => {
        console.log("dd", res.data.payload.updateByAdmin);
        setData(res.data.payload.updateByAdmin);
        setOpen(false);
        setErrors({});
        setUpdateProduct(false);
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        toast.success("User updated successfully");

        // toast.success("Role Updated Successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handlealtermessage = (id, status) => {
    setShow(true);
    setstatus(status);
    setstatusid(id);
  };

  const [profileData, setProfileData] = useState();

  const ProfileFuntions = (data) => {
    console.log("DataLeader-Bridge", data?.message);
    if (data && data) {
      setProfileData(data);
      setProfile(true);
    }
  };

  const handelUpdatestatusDetails = async () => {
    setLoading(true);
    await ApiPut(`user/update-status/id=${statusid}&status=${status}`)
      .then((res) => {
        setOpen(false);
        setErrors({});
        setUpdateProduct(false);
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        setShow(false);
        console.log(res.data.payload.updateUser.status, "payloaduser");
        toast.success(
          `User ${
            res.data.payload.updateUser.status == true
              ? "Activate"
              : "Deactivate"
          }d`
        );

        // toast.success("Role Updated Successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);

        console.log(err);
      });
  };

  let i = 0;
  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "5%",
    },
    {
      name: "Date",
      selector: "createdAt",
      cell: (row) => {
        // let d = new Date(row.createdAt);
        // let month = [
        //   "Jan",
        //   "Feb",
        //   "Mar",
        //   "Apr",
        //   "May",
        //   "Jun",
        //   "Jul",
        //   "Aug",
        //   "Sep",
        //   "Oct",
        //   "Nov",
        //   "Dec",
        // ];
        // let updatedate = ` ${
        //   month[d.getMonth()]
        // } ${d.getDate()},${d.getFullYear()} `;
        // return <div>{updatedate}</div>;
        return (
          <div>
            {moment(row?.createdAt).utc().format("MMM Do YYYY, h:mm a")}
          </div>
        );
      },
      width: "210px",
      sortable: true,
    },
    {
      name: "Full Name",
      cell: (row) => {
        return row?.customer_case_id?.name ? row?.customer_case_id?.name : "-";
      },
      width: "140px",
    },
    {
      name: "Email",
      cell: (row) => {
        return row?.customer_case_id?.email
          ? row?.customer_case_id?.email
          : "-";
      },
      width: "300px",
      sortable: true,
    },
    {
      name: "Role",
      // selector: "currentRole",
      cell: (row) => {
        return row?.customer_case_id?.currentRole
          ? row?.customer_case_id?.currentRole
          : "-";
      },
      sortable: true,
      width: "200px",
    },

    {
      name: "Verification Status",
      selector: "status",
      cell: (row) => {
        if (row?.status == "data-retrieved-status") {
          return (
            <>
              <ReactTooltip
                id="data-retrieved-status"
                place="top"
                effect="solid"
              >
                <span>
                  Fully-Verified successfully retrieved verification data at
                  customer_data_url
                </span>
              </ReactTooltip>
              <span data-tip data-for="data-retrieved-status">
                Data-Retrieved-Status
              </span>
            </>
          );
        } else if (row.status == "queued-status") {
          return (
            <>
              <ReactTooltip id="queued-status" place="top" effect="solid">
                <span>Verification case was queued</span>
              </ReactTooltip>
              <span data-tip data-for="queued-status">
                Queued-Status
              </span>
            </>
          );
        } else if (row.status == "collecting_data_started") {
          return (
            <>
              <ReactTooltip
                id="collecting_data_started"
                place="top"
                effect="solid"
              >
                <span>
                  Verification process of Self-Verify verification case started
                </span>
              </ReactTooltip>
              <span data-tip data-for="collecting_data_started">
                Collecting_data_started
              </span>
            </>
          );
        } else if (row.status == "collecting_data_finished") {
          return (
            <>
              <ReactTooltip
                id="collecting_data_finished"
                place="top"
                effect="solid"
              >
                <span>
                  Verification process of Self-Verify verification case has been
                  completed
                </span>
              </ReactTooltip>
              <span data-tip data-for="collecting_data_finished">
                collecting_data_finished
              </span>
            </>
          );
        } else if (row.status == "started-status") {
          return (
            <>
              <ReactTooltip id="started-status" place="top" effect="solid">
                <span>
                  Verification process of Live-Verify verification case started
                </span>
              </ReactTooltip>
              <span data-tip data-for="started-status">
                Started-status
              </span>
            </>
          );
        } else if (row.status == "verified-status") {
          return (
            <>
              <ReactTooltip id="verified-status" place="top" effect="solid">
                <span>Verification completed successfully</span>
              </ReactTooltip>
              <span data-tip data-for="verified-status">
                Verified-status
              </span>
            </>
          );
        } else if (row.status == "rejected-status") {
          return (
            <>
              <ReactTooltip id="rejected-status" place="top" effect="solid">
                <span>Verification completed with negative result</span>
              </ReactTooltip>
              <span data-tip data-for="rejected-status">
                Rejected-Status
              </span>
            </>
          );
        } else if (row.status == "unverified-status") {
          return (
            <>
              <ReactTooltip id="unverified-status" place="top" effect="solid">
                <span>Verification completed with negative result</span>
              </ReactTooltip>
              <span data-tip data-for="unverified-status">
                Unverified-status
              </span>
            </>
          );
        } else if (row.status == "media-ordered") {
          return (
            <>
              <ReactTooltip id="media-ordered" place="top" effect="solid">
                <span>Video processing started</span>
              </ReactTooltip>
              <span data-tip data-for="media-ordered">
                Media-ordered
              </span>
            </>
          );
        } else if (row.status == "media-remote-in-progress") {
          return (
            <>
              <ReactTooltip
                id="media-remote-in-progress"
                place="top"
                effect="solid"
              >
                <span>Preparing video in progress</span>
              </ReactTooltip>
              <span data-tip data-for="media-remote-in-progress">
                Media-remote-in-progress
              </span>
            </>
          );
        } else if (row.status == "media-remote-ready") {
          return (
            <>
              <ReactTooltip id="media-remote-ready" place="top" effect="solid">
                <span>Video ready to download</span>
              </ReactTooltip>
              <span data-tip data-for="media-remote-ready">
                Media-remote-ready
              </span>
            </>
          );
        } else if (row.status == "media-remote-failed") {
          return (
            <>
              <ReactTooltip id="media-remote-failed" place="top" effect="solid">
                <span>Error preparing video</span>
              </ReactTooltip>
              <span data-tip data-for="media-remote-failed">
                Media-remote-failed
              </span>
            </>
          );
        } else if (row.status == "data_received_from_pep_and_sanctions") {
          return (
            <>
              <ReactTooltip
                id="data_received_from_pep_and_sanctions"
                place="top"
                effect="solid"
              >
                <span>
                  Only if integration with PEP and Sanctions is enabled;
                  PEP/Sanctions potential matches are collected
                </span>
              </ReactTooltip>
              <span data-tip data-for="data_received_from_pep_and_sanctions">
                Data_received_from_pep_and_sanctions
              </span>
            </>
          );
        } else {
          return (
            <>
              <span>{row.status}</span>
            </>
          );
        }
      },
      sortable: true,
      width: "190px",
    },

    {
      name: "Last Modified",
      cell: (row) => {
        return (
          <>
            <div>
              {moment(row.updatedAt)
                .utc()
                .local()
                .format("MMM Do YYYY, h:mm a")}
            </div>
          </>
        );
      },
      selector: (row) => row.lastLogin,
      width: "210px",
      sortable: true,
    },

    // {
    //   name: "to_confirm",
    //   selector: "to_confirm",
    //   sortable: true,
    //   width: "120px",
    // },

    // {
    //   name: "Actions",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <div className="d-flex justify-content-between">
    //           {/* <div className="d-flex align-items-center">
    //             <ReactTooltip id="registerTip" place="top" effect="solid">
    //               User profile
    //             </ReactTooltip>
    //             <h4
    //               style={{ cursor: "pointer" }}
    //               className="mb-0 mt-3 mr-4"
    //               onClick={() => ProfileFuntions(row)}
    //             >
    //               <i data-tip data-for="registerTip" class="fas fa-user"></i>
    //             </h4>
    //             <div
    //               className="cursor-pointer mr-0 mt-3"
    //               onClick={() => {
    //                 setUpdateProduct(false);
    //                 setUpdateDetails(true);
    //                 handleForUpdateManager(row._id);
    //                 setDescriptionUpdate(row.phone);
    //                 setRoleNameUpdate(row.name);
    //                 setUserEmail(row.email);
    //                 setRoleupdate(row.currentRole);
    //               }}
    //             >
    //               <CreateIcon />
    //             </div>
    //           </div> */}
    //           {console.log("AAAASSSSSDDDDD", row.status)}
    //           {row.status == false && (
    //             <div className="cursor-pointer ml-5 mt-1">
    //               <button
    //                 className="btn btn-success"
    //                 // style={{ color: "#007bff" }}
    //                 onClick={() => {
    //                   handlealtermessage(row._id, true);
    //                 }}
    //               >
    //                 <span
    //                 // style={{
    //                 //   border: "none",
    //                 //   display: "flex",
    //                 //   whiteSpace: "nowrap",
    //                 //   // height: "35px",
    //                 //   // paddingLeft: "21px",
    //                 //   // paddingRight: "21px",
    //                 // }}
    //                 >
    //                   Active
    //                 </span>
    //               </button>
    //             </div>
    //           )}

    //           {row.status == true && (
    //             <div className="cursor-pointer ml-5 mt-1">
    //               <button
    //                 className="btn-danger btn"
    //                 onClick={() => {
    //                   handlealtermessage(row._id, false);
    //                 }}
    //                 style={{
    //                   border: "none",
    //                   display: "flex",
    //                   whiteSpace: "nowrap",
    //                 }}
    //               >
    //                 Deactive
    //               </button>
    //             </div>
    //           )}
    //         </div>
    //       </>
    //     );
    //   },
    //   wrap: true,
    //   width: "210px",

    //   sortable: true,
    // },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const [createrData, setCreaterdata] = useState({});

  const handleChange = (e) => {
    let { name, value } = e.target;

    setCreaterdata((prevState) => ({
      ...prevState,

      [name]: value,
    }));

    setErrors({ ...errors, [name]: "" });
  };

  const formValidator = () => {
    let isValid = true;

    if (!createrData.name) {
      setErrors((cv) => {
        return { ...cv, name: "Please Enter  Name" };
      });
      isValid = false;
    }

    if (!createrData.email) {
      setErrors((cv) => {
        return { ...cv, email: "Please Enter email" };
      });
      isValid = false;
    } else if (
      createrData.email.trim() &&
      !createrData.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrors((cv) => {
        return { ...cv, email: "Please enter valid email" };
      });
      isValid = false;
    }

    // if (!createrData.phone) {
    //   setErrors((cv) => {
    //     return { ...cv, phone: "Please Enter phonenumber" };
    //   });
    //   isValid = false;
    // }

    if (!createrData.password) {
      setErrors((cv) => {
        return { ...cv, password: "Please Enter password" };
      });
      isValid = false;
    }

    return isValid;
  };

  const formUpdateValidator = () => {
    let isValid = true;

    if (!roleNameUpdate) {
      setErrors((cv) => {
        return { ...cv, name: "Please Enter  Name" };
      });
      isValid = false;
    }

    // if (!descriptionUpdate) {
    //   setErrors((cv) => {
    //     return { ...cv, phone: "Please Enter phonenumber" };
    //   });
    //   isValid = false;
    // }

    return isValid;
  };

  const submitDetails = async () => {
    if (!formValidator()) {
      console.log("ERROR POST", errors);
      return;
    }

    setLoading(true);

    console.log("createrData", createrData);

    await ApiPost(`user/registration`, createrData)
      .then(async (res) => {
        if (res.data && res.data.payload && res.data.payload) {
          setUpdateProduct(false);
          setLoading(false);
          setDataChange([...dataChange, "Details Added"]);
          setRoleName();
          setDescription();
          setCreaterdata({});
          setOpen(false);
          toast.success(res.data.message);
        } else {
          setMsg(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error.response);
        toast.error(error.response.data.message);
      });
  };

  const handleSearch = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setFilteredReport(() =>
      userReport.filter(
        (item) =>
          item?.customer_case_id.name?.toLowerCase().includes(value) ||
          item?.status?.toLowerCase().includes(value) ||
          item?.customer_case_id.currentRole?.toLowerCase().includes(value)

        // item?.phone?.toLowerCase().includes(value)
      )
    );
  };

  return (
    <>
      <div>
        {/* <div className="card p-1">
          <div className="p-2 mb-2">
            <div class="p-2 d-flex bd-highlight">
              <div class="flex-grow-1 bd-highlight">
                <div className="pl-2 pt-2 headerTitle">User Reports</div>
              </div>
              <div class="bd-highlight col-4">
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="title"
                  placeholder="Search user"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
              <div class="bd-highlight">
                <button
                  className="btn btnColor"
                  onClick={() => {
                    setUpdateDetails(false);
                    handleClickOpen();
                  }}
                >
                  Add User
                </button>
              </div>
            </div>

            
          </div>
        </div> */}

        <div className="card p-1">
          <div className="p-2 mb-2">
            <div className="row mb-4 pr-3">
              <div className="col d-flex justify-content-between">
                <h2 className="pl-3 pt-2">Verification-status</h2>
              </div>
              <div className="col">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    placeholder="Search user"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              {/* <div className="cus-medium-button-style button-height">
                <button
                  className="btn btnColor"
                  onClick={() => {
                    setUpdateDetails(false);
                    handleClickOpen();
                  }}
                >
                  Add User
                </button>
                <CsvDownload
                  className={``}
                  data={dataCSVAdmin}
                  filename="user.csv"
                  style={{
                    backgroundColor: "#F64E60",
                    borderRadius: "6px",
                    border: "1px solid #fff",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "#FFFFFF",
                    fontSize: "12px",
                    padding: "10px 18px",
                    textDecoration: "none",
                    position: "right",
                  }}
                >
                  Export to Csv
                </CsvDownload>
              </div> */}
            </div>
          </div>
          <DataTable
            columns={columns}
            data={filteredReport}
            pagination
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={
              <Loader type="Puff" color="#334D52" height={30} width={30} />
            }
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {status == true ? "Active" : "Deactive"} this
          User?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="danger" onClick={() => handelUpdatestatusDetails()}>
            {status == true && !loading ? "Active" : "Deactive"}
            {loading && <span className="mx-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          {updateProduct && updateProduct === true ? (
            <div className="form ml-30 ">
              {/* Account Number */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Full Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Full name"
                      className={`form-control form-control-lg form-control-solid `}
                      name="name"
                      value={roleName}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Email
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Email"
                      className={`form-control form-control-lg form-control-solid `}
                      name="email"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["email"]}
                  </span>
                </div>
              </div>

              {/* <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Phone number
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="number"
                      autoComplete="off"
                      placeholder="Phone Number"
                      className={`form-control form-control-lg form-control-solid `}
                      name="phone"

                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["phone"]}
                  </span>
                </div>
              </div> */}

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  password
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="password"
                      autoComplete="off"
                      placeholder="password"
                      className={`form-control form-control-lg form-control-solid `}
                      name="password"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["password"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Organization Code
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Organization Code"
                      className={`form-control form-control-lg form-control-solid `}
                      name="organizationId"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["email"]}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    !loading && submitDetails(e);
                  }}
                  className="btn btnColor mr-2"
                >
                  <span>Submit Details</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          ) : null}
        </List>
        <List>
          {updateDetails && updateDetails === true ? (
            <div className="form ml-30 ">
              {/* First Name */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Full name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="name"
                      value={roleNameUpdate}
                      onChange={(e) => {
                        setRoleNameUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleNameUpdate: "" };
                        });
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Current Role
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      name="currentRole"
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        setRoleupdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleUpdate: "" };
                        });
                      }}
                    >
                      <option value={roleUpdate}>
                        {roleUpdate ? roleUpdate : "Select Organization Role"}
                      </option>
                      {organigationRoleList.map((item, index) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                    {/* <span style={{ color: "red" }}>
                      {verificationError["currentRole"]}
                    </span> */}
                    {/* <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="name"
                      value={roleUpdate}
                      onChange={(e) => {
                        setRoleupdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleUpdate: "" };
                        });
                      }}
                    /> */}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>
              {/* swift code */}
              {/* <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  phone
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="number"

                      min="10"
                      className={`form-control form-control-lg form-control-solid `}
                      name="phone"
                      value={descriptionUpdate}
                      onChange={(e) => {
                        setDescriptionUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, descriptionUpdate: "" }
                        })
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["phone"]}
                  </span>
                </div>
              </div> */}

              <div className="d-flex align-items-center justify-content-center">
                {submitValue && submitValue === true ? (
                  <button
                    onClick={(e) => {
                      handelUpdateDetails();
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Update Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      submitDetails(e);
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Submit Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </List>
      </Dialog>

      {/* user Profile  */}

      {/* user Profile end */}

      <Dialog
        fullScreen
        open={profile}
        onClose={handleProfileClose}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleProfileClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          <Profile profile={profileData} />
        </List>
      </Dialog>
    </>
  );
};

export default VerificationStatus;
