/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../../helpers/API/ApiData";
// } from "../../../helpers/API/ApiData";

// import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { getUserInfo } from "../../../../utils/user.util";
import { Form, Input, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import Menu from "./Menu";
import Menu2 from "./Menu2";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const subheader = useSubheader();
  const [show, setShow] = useState(false);
  const [name, setName] = useState();
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputList, setInputList] = useState([{ menuName: "" }]);
  const [roleData, setRoleData] = useState([]);
  const [roleID, setRoleId] = useState([]);
  const [menu, setMenu] = useState();

  const userInfo = getUserInfo();
  useEffect(() => {
    console.log("roleID", roleID);
  }, [roleID]);

  const handleClose1 = () => {
    setOpen(false);
    setInputList([{ menuName: "" }]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs?.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title?.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  //For Dynamic Form Feild

  function MultipleCourse() {
    // handle input change
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
      setInputList([...inputList, { menuName: "" }]);
    };

    // useEffect(() => {
    //   console.log("name", name);
    // }, [name]);

    useEffect(async () => {
      await ApiGet("role")
        .then((res) => {
          if (res.data && res.data.payload && res.data.payload.allRole) {
            setRoleData(res.data.payload.allRole);
            console.log("roleadd", res.data.payload.allRole);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }, []);

    useEffect(() => {
      console.log("roleData", roleData);
    }, [roleData]);

    return (
      <div className="App">
        {inputList.map((x, i) => {
          return (
            <div className="box">
              <p>
                --------------------------------------------------------------------------------------------
              </p>
              <input
                className="ml10"
                name="menuName"
                placeholder="Enter menuName"
                value={x.menuName}
                onChange={(e) => handleInputChange(e, i)}
              />

              <div>
                {roleData.map((item, i) => {
                  return (
                    <>
                      <input
                        type="checkbox"
                        id={item._id}
                        name={item?.roleName}
                        value={item._id}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setRoleId((val) => {
                              return [...val, item._id];
                              // return { ...val, [item._id]: false }
                            });
                          } else if (e.target.checked === false) {
                            setRoleId((val) => {
                              return val.filter((id1) => id1 !== item._id);
                              // return { ...val, [item._id]: true }
                            });
                          }
                          // console.log(e.target.checked, "testtest")
                          // // setRoleId(e.target.value)
                          // // setRoleId(e.target.value)
                        }}
                      />
                      <label for={item?.roleName}> {item?.roleName}</label>
                    </>
                  );
                })}
              </div>
              <p>
                --------------------------------------------------------------------------------------------
              </p>

              <div className="btn-box">
                {inputList.length !== 1 && (
                  <button className="mr10" onClick={() => handleRemoveClick(i)}>
                    Remove
                  </button>
                )}
                {inputList.length - 1 === i && (
                  <button onClick={handleAddClick}>Add</button>
                )}
              </div>
            </div>
          );
        })}
        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
      </div>
    );
  }

  // For menu name
  const handleOnChnage = (e) => {
    const { name, value } = e.target;
    setName(value);
    // setinputValue({ ...inputValue, [name]: value });
  };

  const submitDetails = async () => {
    // if (validateForm()) {
    setLoading(true);

    let Body = {
      name: name,
      menu: inputList,
    };
    console.log("body", Body);
    await ApiPost(`menu/create`, Body)
      .then(async (res) => {
        console.log("response", res);
        if (res.data && res.data.payload && res.data.payload) {
          setOpen(false);
        } else {
          setMsg(res.data.message);
          setLoading(false);
          setOpen(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
    // }
  };

  return (
    <>
      {/* <div
        id="kt_subheader"
        className={`subheader    ${layoutProps.subheaderCssClasses}`}
      ></div> */}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <div>
          <label for="name" className="textcolor spaceforselect">
            Enter Name of Menu
          </label>
          <input
            type="text"
            id="name"
            onChange={(e) => handleOnChnage(e)}
            value={name}
            name="name"
            placeholder="Enter Name of Menu"
            className="number-style"
            min="1"
          />
        </div>

        {/* <div>

          {roleData.map((item, i) => {
            return (
              <>
                <input type="checkbox" id={item._id} name={item?.roleName} value={item._id} onChange={(e) => {
                  if (e.target.checked === true) {
                    setRoleId((val) => {
                      return [...val, item._id]
                      // return { ...val, [item._id]: false }
                    })
                  }
                  else if (e.target.checked === false) {

                    setRoleId((val) => {
                      return val.filter((id1) =>
                        id1 !== item._id
                      )
                      // return { ...val, [item._id]: true }
                    })
                  }
                  console.log(e.target.checked, "testtest")
                  // setRoleId(e.target.value)
                  // setRoleId(e.target.value)
                }

                } />
                <label for={item?.roleName}> {item?.roleName}</label>
              </>
            )
          })} */}

        {/* <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
          <label for="vehicle2"> I have a car</label>
          <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />
          <label for="vehicle3"> I have a boat</label>
          <input type="submit" value="Submit" /> */}
        {/* </div> */}

        {/* {MultipleCourse()} */}
        {/* <Menu /> */}
        {/* <Menu /> */}
        {/* <Menu2 getCourses={courses => setMenu(courses)} /> */}
        <Menu getCourses={(courses) => setMenu(courses)} />

        <div>
          <button
            onClick={(e) => {
              e.preventDefault();
              submitDetails(e);
            }}
          >
            Submit Menu
          </button>
        </div>
      </Dialog>
    </>
  );
}
