import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { getUserInfo } from "../../../utils/user.util";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import moment from "moment";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { toast } from "react-toastify";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContentRouter = () => {
  const [userReport, setUserReport] = useState();
  const [filteredReport, setFilteredReport] = useState();
  const [openCreate, setOpenCreate] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState();
  const [RowId, setRowId] = useState();
  console.log("filteredReport",filteredReport);

  const [open, setOpen] = React.useState(false);

  const handleClose1 = () => {
    setOpen(false);
  };
  const greathandleClose = () => {
    setgreatShow(false);
  };

  const [rowDataMusic, setRowDataMusic] = useState();
  const [rowDataProduct, setRowDataProduct] = useState();
  const [musicProduct, setMusicOrProduct] = useState("music");
  const [greatshow, setgreatShow] = useState(false);
  const [status, setstatus] = useState();
  const [statusid, setstatusid] = useState();
  const handleForMore = async (music, product) => {
    setOpen(true);
    console.log(music);
    console.log(product);
    setRowDataMusic(music);
    setRowDataProduct(product);
  };

  useEffect(() => {
    getUserReports();
  }, []);

  const handleClickOpen = (id) => {
    setRowId(id);
    setOpenCreate(true);
  };
  const handleCloseCreate = () => {
    setOpenCreate(false);
    // settitleupdate("");
    // setdescriptionupdate("");
  };

  const getUserReports = async () => {
    await ApiGet("content/get-content")
      .then((res) => {
        console.log("121212121212", res.data.payload.Cms);
        setUserReport(res.data.payload.Cms);
        setFilteredReport(res.data.payload.Cms);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setFilteredReport(() =>
      userReport.filter(
        (item) =>
          item?.artistName.toLowerCase().includes(value) ||
          item?.realName.toLowerCase().includes(value)
      )
    );
  };

  const handelUpdateDetails = async () => {
    // if (!formUpdateValidator()) {
    //   console.log("ERROR PUT", errors);
    //   return;
    // }

    // setLoading(true);

    let data = {
      isActive: status,
    };

    await ApiPut(`content/update-content?cmsId=${statusid}`, data)
      .then((res) => {
        //   window.location.reload();
        setgreatShow(false);
        getUserReports();
        console.log(res.data.payload.updatedCms.isActive, "nbfncdscd");

        toast.success(
          ` ${
            res.data.payload.updatedCms.isActive == true ? "Active" : "Deactive"
          } Successfully`
        );
        // console.log(res);
      })
      .catch((err) => {
        setgreatShow(false);
        toast.error(err);

        console.log(err);
      });
  };

  const handlealtermessage = (id, status) => {
    setgreatShow(true);
    setstatus(status);
    setstatusid(id);
  };

  const [errorDescriptions, seterrorDescriptions] = useState();
  const handleAddFaq = async (id) => {
    // setLoading(true);

    if (!description) {
      //  console.log("vijaycasa")
      seterrorDescriptions("please Enter Descriptions");
      return;
    }

    let x = document.getElementById("vehicle1").checked;

    console.log("x22222222", x);

    const data = {
      title: title,
      description: description,
      isActive: false,
      checkedData: x,
    };

    await ApiPut(`content/update-content?cmsId=${RowId}`, JSON.stringify(data))
      .then((res) => {
        console.log("policy", res);
        seterrorDescriptions("");
        setOpenCreate(false);
        getUserReports();
        console.log(res, "datatataa");

        // setLoading(false);
        // setTitle("");
        // setDescription("");
        // setOpenCreate(false);
        // setDataChange([...dataChange, "FAQ add"]);
        toast.success(
          `${res.data.payload.updatedCms.title} Updated Successfully`
        );
      })
      .catch((err) => {
        // setLoading(false);
        seterrorDescriptions("");

        console.log(err);
      });
  };
  let i = 0;
  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Date",
      selector: "createdAt",
      cell: (row) => {
        let d = new Date(row.createdAt);
        let month = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let updatedate = ` ${
          month[d.getMonth()]
        } ${d.getDate()},${d.getFullYear()} `;
        return <div>{updatedate}</div>;
      },
      sortable: true,
    },

    {
      name: "Title",
      selector: "title",
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => {
        return <> {row.isActive == true ? "Active" : "Deactive"}</>;
      },
      selector: (row) => row.isActive,
      sortable: true,
    },
    {
      name: "Last Modified",
      cell: (row) => {
        return (
          <>
            <div>
              {moment(row.updatedAt).utc().local().add(1,'minutes').format("MMM Do YYYY, h:mm a")}
            </div>
          </>
        );
      },
      // selector: (row) => row.lastLogin,
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div className="cursor-pointer mr-3"></div>
              <div
                className="cursor-pointer mt-3"
                onClick={() => {
                  handleClickOpen(row._id);
                  setDescription(row.description);
                  setTitle(row.title);
                }}
              >
                <CreateIcon />
              </div>
            </div>

            {row.isActive == false && (
              <div className="cursor-pointer ml-5 mt-1">
                <Button
                  onClick={() => {
                    handlealtermessage(row._id, true);
                  }}
                  style={{
                    border: "none",
                    height: "35px",
                    paddingLeft: "21px",
                    paddingRight: "21px",
                  }}
                >
                  Active
                </Button>
              </div>
            )}

            {row.isActive == true && (
              <div className="cursor-pointer ml-5 mt-1">
                <Button
                  onClick={() => {
                    handlealtermessage(row._id, false);
                  }}
                  style={{
                    backgroundColor: "red",
                    border: "none",
                    height: "35px",
                  }}
                >
                  Deactive
                </Button>
              </div>
            )}
          </>
        );
      },
    },
  ];
  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <>
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Release Note</h2>
            </div>
            {/* <div className="col">
              <input
                type="text"
                className={`form-control form-control-lg form-control-solid `}
                name="title"
                placeholder="Search Music"
                onChange={(e) => handleSearch(e)}
              />
            </div> */}
          </div>
          <DataTable
            noHeader="true"
            columns={columns}
            data={filteredReport}
            customStyles={customStyles}
          />
        </div>
      </div>

      <Modal show={greatshow} onHide={greathandleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure want to {status == true ? "Active" : "Deactive"} this
          C.M.S ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={greathandleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => handelUpdateDetails()}>
            {status == true ? "Active" : "Deactive"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          <div className="d-flex justify-content-center mb-10">
            {/* <div className="mr-5">
              <Button
                className="bg-primary"
                onClick={() => {
                  setMusicOrProduct("music");
                }}
              >
                Musics
              </Button>
            </div>
            <div className="ml-5">
              {" "}
              <Button
                className="bg-primary"
                onClick={() => {
                  setMusicOrProduct("product");
                }}
              >
                Products
              </Button>
            </div> */}
          </div>
        </List>
      </Dialog>

      <Dialog
        fullScreen
        open={openCreate}
        onClose={handleCloseCreate}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseCreate}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          <div className="form ml-30 ">
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Title Name
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    disabled
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {/* {errors["title"]}
                  <h5 className="text-danger pt-2">{titleupdate}</h5> */}
                </span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                All user Clear this content
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Description
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onChange={(event, editor) => {
                      setDescription(editor.getData());
                    }}
                  />
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {/* {errors["description"]} */}
                  {errorDescriptions}
                  {/* <h5 className="text-danger pt-2">{descriptionupdate}</h5> */}
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <button
                onClick={() => {
                  handleAddFaq();
                }}
                className="btn btnColor mr-2"
              >
                <span>Update {title}</span>
                {/* {loading && (
                  <span className="mx-3 spinner spinner-white"></span>
                )} */}
              </button>
            </div>
          </div>
        </List>
      </Dialog>
    </>
  );
};

export default ContentRouter;
