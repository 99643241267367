import React, { useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DataTable, { defaultThemes } from "react-data-table-component";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Dropdown } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import { Modal } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Legends.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Product = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setSubmitValue(false);
    setUpdateProduct(true);
    setImage("");
    setDesc("");
    setTitle("");
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);

  const [allProduct, setAllProduct] = useState();
  const [dataChange, setDataChange] = useState([]);
  const [pid, setPid] = useState();
  const [size, setSize] = useState();
  const [size1, setSize1] = useState([]);
  const [price, setPrice] = useState();
  const [type, setType] = useState();
  const [title, setTitle] = useState();
  const [image, setImage] = useState("");
  const [desc, setDesc] = useState();
  const [updateProduct, setUpdateProduct] = useState(false);
  const [submitValue, setSubmitValue] = useState(false);
  const [errors, setErrors] = useState({});
  const [OpenImages, setOpenImages] = useState();
  // const [errors] = useState([]);
  let userInfo = getUserInfo();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id) => {
    setShow(true);
    setPid(id);
  };

  useEffect(() => {
    getProductData();
  }, [dataChange]);

  const formValidator = () => {
    let isValid = true;

    if (!title) {
      setErrors((cv) => {
        return { ...cv, title: "Please Enter  Name" };
      });
      isValid = false;
    }

    if (!image) {
      setErrors((cv) => {
        return { ...cv, image: "Please Enter icon" };
      });
      isValid = false;
    }
    if (!desc) {
      setErrors((cv) => {
        return { ...cv, desc: "Please Enter description" };
      });
      isValid = false;
    }

    // if (!createrData.phone) {
    //   setErrors((cv) => {
    //     return { ...cv, phone: "Please Enter phonenumber" };
    //   });
    //   isValid = false;
    // }

    return isValid;
  };

  const getProductData = async () => {
    await ApiGet("legend")
      .then((res) => {
        console.log("vaidhab---------------------", res.data.payload);
        setAllProduct(res.data.payload);
        setUserReport(res.data.payload);
        setFilteredReport(res.data.payload);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleDeleteProduct = async () => {
    // const data = {
    //   id: id,
    // };
    await ApiDelete(`legend/${pid}`)
      .then((res) => {
        handleClose();
        setDataChange([...dataChange, "Music delete"]);
        //console.log(res.data.data);
      })
      .catch((err) => {
        console.log("err");
      });
  };
  const submitProduct = async () => {
    if (!formValidator()) {
      console.log("ERROR PUT", errors);
      return;
    }
    setLoading(true);
    const formData = new FormData();

    formData.append("legendsName", JSON.stringify(title));
    formData.append("profileImage", image);
    formData.append("legendsDescription", desc);

    // const data = {
    //   legendsName: title,
    //   legendsIcon: image,
    //   legendsDescription: desc,
    // };

    await ApiPost("legend/create", formData)
      .then((res) => {
        setOpen(false);
        setUpdateProduct(false);
        setDataChange([...dataChange, "Product Added"]);

        setLoading(false);
        //console.log(res);
        setSize();
        setPrice();
        setType();
        setTitle();
        setImage();
        toast.success("Legend Add successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        console.log(err);
      });
  };

  const handleForUpdateProduct = async (id) => {
    setOpen(true);
    setPid(id);
    setSubmitValue(true);

    await ApiGet(`legend/id=${id}`)
      .then((res) => {
        //console.log(res.data.data);
        //setAllMusic(res.data.data);
        setTitle(res.data.data.title);
        setDesc(res.data.data.description);
        setPrice(res.data.data.price);
        setSize1(res.data.data.size);
        setType(res.data.data.type);
        setOpenImages(res.data.data.legendsIcon);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getNewDate = (date) => {
    var d = new Date(date);
    var month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var newDate = `${month[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    return newDate;
  };

  const handleUpdateProduct = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("legendsName", title);
    formData.append("profileImage", image);
    formData.append("legendsDescription", desc);

    // const data = {
    //   legendsName: title,
    //   legendsIcon: image,
    //   legendsDescription: desc,
    // };

    await ApiPut(`legend/id=${pid}`, formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        setOpen(false);
        setUpdateProduct(false);
        setDataChange([...dataChange, "Music Updated"]);
        setLoading(false);

        //console.log(res);
        setTitle();
        setDesc();
        setPrice();
        setSize1();
        setType();
        toast.success("legend Update successfully!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        console.log(err);
      });
  };
  let i = 0;
  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "legends Name",
      selector: "legendsName",
      sortable: true,
    },
    {
      name: "Legends Description",
      selector: "legendsDescription",
      wrap: true,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => {
        return (
          <>
            <div className="p-3">
              <img
                className="max-w-90px"
                src={row.legendsIcon}
                alt={row.title}
              />
            </div>
          </>
        );
      },
      wrap: true,
    },

    {
      name: "Update date",
      cell: (row) => {
        return <>{getNewDate(row.updatedAt)}</>;
      },
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer mr-3"
                onClick={() => {
                  handleShow(row._id);
                }}
              >
                <DeleteIcon />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setUpdateProduct(true);

                  handleForUpdateProduct(row._id);
                  setTitle(row.legendsName);
                  setDesc(row.legendsDescription);
                  setImage(row.legendsIcon);
                }}
              >
                <CreateIcon />
              </div>
            </div>
          </>
        );
      },
      sortable: true,
    },
  ];
  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const handleSize = () => {
    setSize("");
    console.log(size);
    setSize1([...size1, size]);
  };
  console.log(size1);

  const [userReport, setUserReport] = useState();
  const [filteredReport, setFilteredReport] = useState();

  const handleSearch = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setFilteredReport(() =>
      userReport.filter(
        (item) =>
          item?.legendsDescription?.toLowerCase().includes(value) ||
          item?.legendsName?.toLowerCase().includes(value)
        // item?.phone?.toLowerCase().includes(value)
      )
    );
  };

  console.log('DATAAAAAA', filteredReport, submitValue);

  return (
    <>
      <ToastContainer />
      <div>
        {/*  */}
        <div className="card p-1">
          <div className="p-2">
            <div className="row mb-4 pr-3">
              <div className="col d-flex justify-content-between">
                <h2 className="pl-3 pt-2">All Legends</h2>
              </div>
              <div className="col">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    placeholder="Search Legends"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              <div className="cus-medium-button-style button-height">
                <button
                  className="btn btnColor"
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  Add Legends
                </button>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={filteredReport}
            customStyles={customStyles}
            pagination
            style={{
              marginTop: "-3rem",
            }}
          />
        </div>
        {/*  */}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete Legend?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancle
          </Button>
          <Button variant="danger" onClick={() => handleDeleteProduct()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          {updateProduct && updateProduct === true ? (
            <div className="form ml-30 ">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  legends Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="legendsName"
                      value={title && title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        setErrors({ ...errors, title: "" });
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["title"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  legends Icon{" "}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid `}
                      name="legendsIcon"
                      // value={image && image}
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                        setErrors({ ...errors, image: "" });
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["image"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  legends Description
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="legendsDescription"
                      value={desc && desc}
                      onChange={(e) => {
                        setDesc(e.target.value);
                        setErrors({ ...errors, desc: "" });
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["desc"]}
                  </span>
                </div>
              </div>
              {image.type && image.name ? (
                <>
                  <img
                    style={{
                      alignItems: "center",
                      height: "100px",
                      width: "100px",
                    }}
                    src={image && URL?.createObjectURL(image)}
                  />
                </>
              ) : (
                <>
                  <img
                    style={{
                      alignItems: "center",
                      height: "100px",
                      width: "100px",
                    }}
                    src={image}
                  />
                </>
              )}
              {console.log("imageimage", image, OpenImages)}

              <div className="d-flex align-items-center justify-content-center">
                {submitValue && submitValue === true ? (
                  <button
                    onClick={(e) => {
                      handleUpdateProduct();
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Update Legends</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      submitProduct(e);
                    }}
                    className="btn btnColor mr-2"
                  >
                    <span>Add Legends</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </List>
      </Dialog>
    </>
  );
};

export default Product;
