import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { Content, ContentRoute, LayoutSplashScreen } from "../_metronic/layout";

import { getUserInfo } from "../../src/utils/user.util";
import User from "../../src/_metronic/components/User/User";
import FAQ from "../../src/_metronic/components/FAQ/FAQ";
import Messages from "../../src/_metronic/components/FAQ/Messages";
import Contact from "../../src/_metronic/components/Contact/Contact";
import CMS from "../../src/_metronic/components/CMS/Cms";
import Allfilterdata from "../../src/_metronic/components/Allfilterdata/Allfilterdata";
// import DashboardPage from "../_metronic/components/Deshbord/deshbord";
import DashboardPage from "../_metronic/components/Deshbord/deshboard1";
import Chat from "../_metronic/components/chat-app/Chat";
import Sitesetting from "../_metronic/components/Site setting/Sitesetting";
import Legends from "../_metronic/components/Legends/Legends";
import ContentRouter from "../_metronic/components/Content/ContentRouter";
import AbuseQuestion from "../_metronic/components/Abuse-Question/Abuse";
import AbuserUser from "../_metronic/components/Abuse-User/Abuseuser";
import AbuseAnswer from "../_metronic/components/Abuse-Answer/AbuseAnswer";
import Sendmail from "../_metronic/components/SendMail/Sendmail";
import Payment from "../_metronic/components/Payment/Payment";
import DropDown from "../_metronic/components/dropdown/DropDown";
import Experience from "../_metronic/components/experience/Experience";
import Organization from "../_metronic/components/Organization/Organization";
import FrequentlyAskedQuestions from "../_metronic/components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";

import Company from "../_metronic/components/Company/Company";
// import ViewQuiz from "../_metronic/components/ViewQuiz/ViewQuiz";
import Filter from "../_metronic/components/TotalFilter/filter";
import VerificationStatus from "../_metronic/components/VerificationStatus/VerificationStatus";
export default function BasePage() {
  const userInfo = getUserInfo();
  console.log("userInfo", userInfo);

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />

          <ContentRoute exact path="/dashboard" component={DashboardPage} />
          <ContentRoute exact path="/user" component={User} />
          <ContentRoute exact path="/organization" component={Organization} />
          <ContentRoute exact path="/Company" component={Company} />
          <ContentRoute exact path="/faq" component={FAQ} />
          <ContentRoute exact path="/contact" component={Contact} />
          <ContentRoute exact path="/cms" component={CMS} />
          <ContentRoute
            exact
            path="/allfilterdata/:id"
            component={Allfilterdata}
          />
          <ContentRoute exact path="/chat" component={Chat} />
          <ContentRoute exact path="/Filters" component={Filter} />
          <ContentRoute exact path="/sitesetting/:id" component={Sitesetting} />
          <ContentRoute exact path="/legends" component={Legends} />
          <ContentRoute exact path="/messages" component={Messages} />
          <ContentRoute exact path="/content" component={ContentRouter} />
          <ContentRoute exact path="/abuse/:id" component={AbuseQuestion} />
          <ContentRoute exact path="/abuseuser" component={AbuserUser} />
          <ContentRoute exact path="/abuseanswer/:id" component={AbuseAnswer} />
          <ContentRoute exact path="/sendmail" component={Sendmail} />
          <ContentRoute exact path="/frequently-asked-questions" component={FrequentlyAskedQuestions} />
          <ContentRoute exact path="/payment" component={Payment} />
          <ContentRoute exact path="/DropDown" component={DropDown} />
          <ContentRoute exact path="/Experience" component={Experience} />
          <ContentRoute
            exact
            path="/Verification-status"
            component={VerificationStatus}
          />

          {/* <Redirect to="error/error-v6" /> */}
        </Switch>
      </Suspense>
    </>
  );
}
