import React, { useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DataTable, { defaultThemes } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
// import { getUserInfo } from "../../../utils/user.util";
import { Modal } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { toString } from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Role = (props) => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [roleData, setRoleData] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [pid, setPid] = useState();

  const [updateProduct, setUpdateProduct] = useState(false);
  const [submitValue, setSubmitValue] = useState(false);

  const [allfiltertitle, setallfiltertitle] = useState();
  const [propsid, setpropsid] = useState();

  const [roleNameUpdate, setRoleNameUpdate] = useState();
  const [optional, setOptional] = useState(false);

  const [descriptionUpdate, setDescriptionUpdate] = useState();

  const [updateDetails, setUpdateDetails] = useState(false);

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [greatshow, setgreatShow] = useState(false);
  const [status, setstatus] = useState();
  const [statusid, setstatusid] = useState();

  // const [errors] = useState([]);

  const [errors, setErrors] = useState({});

  // console.log(userInfo.user.id);
  const [show, setShow] = useState(false);

  // const handleSearch = (e) => {
  //   console.log("eeeee", e.target.value);
  //   var value = e.target.value.toLowerCase();
  //   setFilteredReport(() =>
  //   roleData.filter(
  //       (item) =>
  //         item?.optionName.toLowerCase().includes(value)

  //     )
  //   );
  // };

  const handleClickOpen = () => {
    setOpen(true);
    setSubmitValue(false);
    setUpdateProduct(true);
    setUpdateDetails(false);
  };

  const handleClose1 = () => {
    seterrorOptions("");

    setOpen(false);
    setErrors({});
  };

  const handleClose = () => {
    seterrorOptions("");
    setShow(false);
    setErrors({});
  };

  const handleShow = (id) => {
    setShow(true);
    setPid(id);
  };

  const greathandleClose = () => {
    seterrorOptions("");
    setRoleNameUpdate();
    setOptional(false);
    setgreatShow(false);
  };

  const handlealtermessage = (id, status) => {
    setgreatShow(true);
    setstatus(status);
    setstatusid(id);
  };

  useEffect(() => {
    if (props.match.params.id) {
      rolegetApi(props.match.params.id);
      setpropsid(props.match.params.id);
    }
  }, [props.match.params.id, dataChange]);

  const rolegetApi = async (id) => {
    setIsLoaderVisible(true);
    await ApiGet(`filter/get-filter?id=${id}&admin=admin`)
      .then((res) => {
        console.log("filterdatdta", res.data.payload);
        setRoleData(res?.data?.payload?.filter[0]?.options);
        setUserReport(res?.data?.payload?.filter[0]?.options);

        setFilteredReport(res?.data?.payload?.filter[0]?.options);

        setallfiltertitle(res?.data?.payload?.filter[0]?.name);
        setLoading(false);

        console.log("roleadd", res?.data?.payload?.filter[0]?.options);
        setIsLoaderVisible(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoaderVisible(false);
      });
  };

  const handleDeleteManagerInfo = async () => {
    await ApiPut(`filter/delete-filter?filterId=${propsid}&optionId=${pid}`)
      .then((res) => {
        console.log("resdatdta", res);
        setDataChange([...dataChange]);
        setLoading(false);
        handleClose();
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err);
        console.log("err");
      });
  };

  const handleForUpdateManager = async (id) => {
    setPid(id);
    setSubmitValue(true);

    setUpdateProduct(false);

    setOpen(true);
    setUpdateDetails(true);

    // setRoleNameUpdate(temp[0].roleName);
    // setDescriptionUpdate(temp[0].description);
  };

  const handelUpdateDetails = async () => {
    // if (!formUpdateValidator()) {
    //   console.log("ERROR PUT", errors);
    //   return;
    // }

    setLoading(true);

    let data = {
      optionId: statusid,
      status: status,
    };

    await ApiPut(`filter/update-filter?filterId=${propsid}`, data)
      .then((res) => {
        //   window.location.reload();
        console.log(res, "payloaduser");
        setOpen(false);
        setErrors({});
        // window.location.reload();
        setUpdateProduct(false);
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        setgreatShow(false);
        toast.success(res.data.message);

        // toast.success("Role Updated Successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        setgreatShow(false);
        toast.error(err);

        console.log(err);
      });
  };

  const [errorOptions, seterrorOptions] = useState();
  const handelEditdetails = async () => {
    if (!roleNameUpdate) {
      seterrorOptions(` Please Enter ${allfiltertitle} Name `);
      return;
    }

    console.log("roleNameUpdate", roleNameUpdate);

    setLoading(true);
    const data = {
      optionId: pid,
      optionName: roleNameUpdate,
      optional: optional,
    };

    await ApiPut(`filter/update-filter?filterId=${propsid}`, data)
      .then((res) => {
        //   window.location.reload();
        console.log(res, "payloaduser");
        setOpen(false);
        setErrors({});
        // window.location.reload();
        seterrorOptions("");
        setUpdateProduct(false);
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        setgreatShow(false);
        toast.success(res.data.message);

        // toast.success("Role Updated Successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        setgreatShow(false);
        toast.error(err);

        console.log(err);
      });
  };

  let i = 0;
  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "80px",
    },

    {
      name: `${allfiltertitle} Name`,
      selector: "optionName",
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => {
        // return <>{row?.status?.toString()}</>;
        return (
          <>
            {row?.status == false && "Deactive"}

            {row?.status == true && "Active"}
          </>
        );
      },
      wrap: true,
      selector: (row) => row?.status,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer mt-3"
                onClick={() => {
                  handleForUpdateManager(row._id);
                  setRoleNameUpdate(row.optionName);
                  setOptional(row.optional == null ? false : row.optional);
                }}
              >
                <CreateIcon />
              </div>
              {/* <div
                className="cursor-pointer mt-3"
                onClick={() => {
                  handleShow(row._id);
                }}
              >
                <DeleteIcon />
              </div> */}

              <>
                {row.status == false && (
                  <div className="cursor-pointer ml-5 mt-1">
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        handlealtermessage(row._id, true);
                      }}
                      style={{
                        border: "none",
                        height: "35px",
                        paddingLeft: "21px",
                        paddingRight: "21px",
                      }}
                    >
                      Active
                    </button>
                  </div>
                )}

                {row.status == true && (
                  <div className="cursor-pointer ml-5 mt-1">
                    <Button
                      onClick={() => {
                        handlealtermessage(row._id, false);
                      }}
                      style={{
                        backgroundColor: "red",
                        border: "none",
                        height: "35px",
                      }}
                    >
                      Deactive
                    </Button>
                  </div>
                )}
              </>

              {/* {active && (

           
   
 ) && (

  <div
  className="cursor-pointer"

>
 <button>Dactive</button>
</div>

 )} */}

              <div></div>
            </div>
          </>
        );
      },
    },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const [createrData, setCreaterdata] = useState({});

  const handleChange = (e) => {
    let { name, value } = e.target;

    setCreaterdata((prevState) => ({
      ...prevState,

      [name]: value,
      optionId: pid,
    }));

    setErrors({ ...errors, [name]: "" });
  };

  const formValidator = () => {
    let isValid = true;
    console.log(createrData, "createrDatacreaterData");
    if (!createrData.optionName) {
      setErrors((cv) => {
        return { ...cv, optionName: "Please Enter Option Name" };
      });
      isValid = false;
    }

    return isValid;
  };

  const formUpdateValidator = () => {
    let isValid = true;

    if (!roleNameUpdate) {
      setErrors((cv) => {
        return { ...cv, roleNameUpdate: "Please Enter Role Name" };
      });
      isValid = false;
    }

    if (!descriptionUpdate) {
      setErrors((cv) => {
        return { ...cv, descriptionUpdate: "Please Enter Description" };
      });
      isValid = false;
    }

    return isValid;
  };

  console.log("vijayvaa", createrData);

  // const handleFind = async () => {
  //   for (let i = 0; i < 100; i++) {
  //     // const element = array[i];
  //     const data = {
  //       optionName: 1 + i,
  //     };
  //     await ApiPut(
  //       `filter/add-filter-option?filterId=${"62ecb8aca7555b59ac1a5535"}`,
  //       data
  //     )
  //       .then(async (res) => {
  //         // setDataChange([...dataChange, "Details Added"]);
  //         // setLoading(false);
  //         // setOpen(false);
  //         // // toast.success(res.data.message);
  //         // toast.success(`${allfiltertitle} added successfully`);
  //         // setCreaterdata({});
  //       })
  //       .catch((error) => {
  //         // setgreatShow(false);

  //         // setLoading(false);
  //         toast.error(error.message);
  //       });
  //   }
  // };

  // useEffect(async () => {
  //   // for (let i = 0; i < 50; i++) {
  //   // const element = array[i];
  //   handleFind();
  //   // }
  // }, []);

  const submitDetails = async () => {
    if (!formValidator()) {
      console.log("ERROR POST", errors);
      return;
    }

    setLoading(true);

    const Data = {
      ...createrData,
      optional: optional == null ? false : optional,
    };

    await ApiPut(`filter/add-filter-option?filterId=${propsid}`, Data)
      .then(async (res) => {
        setDataChange([...dataChange, "Details Added"]);
        setLoading(false);

        setOpen(false);
        // toast.success(res.data.message);
        toast.success(`${allfiltertitle} added successfully`);

        setCreaterdata({});
      })
      .catch((error) => {
        setgreatShow(false);

        setLoading(false);
        toast.error(error.message);
      });
  };

  const [userReport, setUserReport] = useState();
  const [filteredReport, setFilteredReport] = useState();

  const handleSearch = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setFilteredReport(() =>
      userReport.filter((item) =>
        item?.optionName?.toLowerCase().includes(value)
      )
    );
  };

  return (
    <>
      <div>
        <div className="card p-1">
          <div className="p-2 mb-2">
            <div className="row mb-4 pr-3">
              <div className="col d-flex justify-content-between">
                <h2 className="pl-2 pt-2">{allfiltertitle}</h2>
              </div>
              <div className="col">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    placeholder={`Search ${allfiltertitle}`}
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              <div className="cus-medium-button-style button-height">
                <button
                  className="btn btnColor"
                  onClick={() => {
                    setUpdateDetails(false);
                    handleClickOpen();
                  }}
                >
                  Add {allfiltertitle}
                </button>
              </div>
            </div>

            <DataTable
              columns={columns}
              data={filteredReport}
              pagination
              customStyles={customStyles}
              style={{
                marginTop: "-3rem",
              }}
              progressPending={isLoaderVisible}
              progressComponent={
                <Loader type="Puff" color="#334D52" height={30} width={30} />
              }
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete {allfiltertitle}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="danger" onClick={() => handleDeleteManagerInfo()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={greatshow} onHide={greathandleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure want to {status == true ? "Active" : "Deactive"} this{" "}
          {allfiltertitle} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={greathandleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => handelUpdateDetails()}>
            {status == true ? "Active" : "Deactive"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          {updateProduct && updateProduct === true ? (
            <div className="form ml-30 ">
              {/* Account Number */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {allfiltertitle} Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      placeholder={`${allfiltertitle} Name`}
                      className={`form-control form-control-lg form-control-solid `}
                      name="optionName"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["optionName"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Profile-optional-field
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        placeholder={`multiSelect Name`}
                        // className={`form-control form-control-lg form-control-solid `}
                        name="optionName"
                        // value={multiSelect}
                        checked={optional}
                        onChange={(e) => {
                          setOptional(e.target.checked);
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    submitDetails(e);
                  }}
                  className="btn btnColor mr-2"
                >
                  <span>Submit Details</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          ) : null}
        </List>
        <List>
          {updateDetails && updateDetails === true ? (
            <div className="form ml-30 ">
              {/* First Name */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {allfiltertitle} Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      placeholder={`${allfiltertitle} Name`}
                      className={`form-control form-control-lg form-control-solid `}
                      name="optionName"
                      value={roleNameUpdate}
                      onChange={(e) => {
                        setRoleNameUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleNameUpdate: "" };
                        });
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {/* {errors["roleNameUpdate"]} */}
                    {errorOptions}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Profile-optional-field
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        placeholder={`multiSelect Name`}
                        // className={`form-control form-control-lg form-control-solid `}
                        name="optionName"
                        // value={multiSelect}
                        checked={optional}
                        onChange={(e) => {
                          setOptional(e.target.checked);
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                {submitValue && submitValue === true ? (
                  <button
                    onClick={(e) => {
                      handelEditdetails();
                    }}
                    className="btn btnColor mr-2"
                  >
                    <span>Update Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      submitDetails(e);
                    }}
                    className="btn btnColor mr-2"
                  >
                    <span>Submit Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </List>
      </Dialog>
    </>
  );
};

export default Role;
