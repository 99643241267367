import React, { useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DataTable, { defaultThemes } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import { Modal } from "react-bootstrap";
import { Dialog } from "@material-ui/core";
import { List } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Slide } from "@material-ui/core";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { Tooltip } from "@material-ui/core";
import CsvDownload from "react-json-to-csv";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Payment = (props) => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [pid, setPid] = useState();
  const [updateProduct, setUpdateProduct] = useState(false);
  const [submitValue, setSubmitValue] = useState(false);
  const [roleName, setRoleName] = useState();
  const [description, setDescription] = useState();
  const [roleNameUpdate, setRoleNameUpdate] = useState();
  const [roleUpdate, setRoleupdate] = useState();
  const [descriptionUpdate, setDescriptionUpdate] = useState();
  const [msg, setMsg] = useState("");
  const [updateDetails, setUpdateDetails] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [idForEditType, setIdForEditType] = useState();
  const [paymentData, setPaymentData] = useState();
  const [filteredReport, setFilteredReport] = useState();
  const [UserEmail, setUserEmail] = useState();
  const [errors, setErrors] = useState({});
  let userInfo = getUserInfo();
  const [createrData, setCreaterdata] = useState({});
  const [costUpdate, setCostUpdate] = useState();
  const [validatityUpdate, setValidityUpdate] = useState();
  const [show, setShow] = useState();
  const [idforDelete, setIdForDelete] = useState();
  const [planTypeUpdate, setPlanTypeUpdate] = useState({});
  const [planType, setPlanType] = useState();
  const [userType, setUserType] = useState();
  const [donationCsvAdmin, setDonationCsvAdmin] = useState([]);
  const [dataCSVAdmin, setDataCSVAdmin] = useState([]);

  const [pricingPlan, setPricingPlan] = useState();

  const handleClickOpen = () => {
    setOpen(true);
    setSubmitValue(false);
    setUpdateProduct(true);
  };

  const handleClose1 = () => {
    setOpen(false);
    setErrors({});
    setShow(false);
  };

  useEffect(() => {
    GetPayment();
  }, [dataChange]);

  const GetPayment = async () => {
    setIsLoaderVisible(true);

    // UserData
    const userData = JSON.parse(localStorage.getItem("userinfo"));
    // UserData

    await ApiGet(`plan`)
      .then((res) => {
        console.log("GETPLAN", res);
        setRoleData(res?.data?.payload?.allPlan);
        setDataCSVAdmin(res?.data?.payload?.allPlan);
        setFilteredReport(res?.data?.payload?.allPlan);
        setPaymentData(res?.data?.payload?.allPlan);
        console.log("roleadd", res.data);
        setIsLoaderVisible(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoaderVisible(false);
      });
  };

  useEffect(() => {
    if (donationCsvAdmin) {
      donationCsvAdmin.map((donation) => {
        let data = {
          PlanName: donation?.planName,
          UserType: donation?.userType,
          Date: moment(donation?.createdAt).utc().format("MMM Do YYYY, h:mm a"),
          Description: donation?.planDescription,
          Plancost: donation?.preLaunch,
          LastModified: donation?.planCost,
          PlanType: donation?.type,
          LastModified: moment(donation.updatedAt)
            .utc()
            .local()
            .format("MMM Do YYYY, h:mm a"),
        };
        setDataCSVAdmin((currVal) => [...currVal, data]);
      });
    }
    console.log("UsertCsvReport", donationCsvAdmin);
  }, [donationCsvAdmin]);

  const DeletePayment = async () => {
    await ApiDelete()
      .then((res) => {
        setShow(false);
        toast.success("Payment Deleted successfully");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleDeleteManagerInfo = async () => {
    await ApiDelete(`role/id=${pid}`)
      .then((res) => {
        setDataChange([...dataChange, "Payment delete"]);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const handleForUpdateManager = async (id) => {
    setOpen(true);
    setPid(id);
    setSubmitValue(true);
    let temp = roleData.filter((data) => {
      return data._id === id;
    });

    console.log("DDSDSDSDSDSD", temp[0].userType);
    setPlanType(temp[0].type);
    setPricingPlan(temp[0].type);
    setUserType(temp[0].userType);
    setRoleNameUpdate(temp[0].planName);
    setDescriptionUpdate(temp[0].planDescription);
    setCostUpdate(temp[0].planCost);
    setValidityUpdate(temp[0].validity);
    setPlanTypeUpdate(temp[0].userType);
  };
  console.log("planTypeUpdateplanTypeUpdateplanTypeUpdate", planTypeUpdate);
  const handelUpdateDetails = async () => {
    if (!formUpdateValidator()) {
      console.log("ERROR PUT", errors);
      return;
    }

    setLoading(true);

    let data = {
      planName: roleNameUpdate,
      planDescription: descriptionUpdate,
      planCost: costUpdate,
      validity: validatityUpdate,
      type: planTypeUpdate,
      preLaunch: planType,
      userType: userType,
    };

    await ApiPut(`plan/${idForEditType}`, data)
      .then((res) => {
        setOpen(false);
        setErrors({});
        setUpdateProduct(false);
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        toast.success("Plan updated successfully");

        // toast.success("Role Updated Successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  let i = 0;
  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "10%",
    },
    {
      name: "Date",
      selector: "createdAt",
      cell: (row) => {
        return (
          <div>{moment(row.createdAt).utc().format("MMM Do YYYY, h:mm a")}</div>
        );
      },
      sortable: true,
    },
    {
      name: "Plan name",
      selector: "planName",
      sortable: true,
    },

    {
      name: "User Type",
      selector: "userType",
      sortable: true,
    },

    {
      name: "Launch",
      selector: "preLaunch",
      sortable: true,
    },

    {
      name: "Description",
      selector: "planDescription",

      sortable: true,
    },
    {
      name: "Plan cost",
      selector: "planCost",
      sortable: true,
    },
    {
      name: "Plan Type",
      selector: "type",
      sortable: true,
    },
    {
      name: "Last Modified",
      cell: (row) => {
        return (
          <>
            <div>
              {moment(row.updatedAt)
                .utc()
                .local()
                .format("MMM Do YYYY, h:mm a")}
            </div>
          </>
        );
      },
      selector: (row) => row.lastLogin,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div
                  className="cursor-pointer mr-0 mt-3"
                  onClick={() => {
                    setUpdateProduct(false);
                    setUpdateDetails(true);
                    setPlanType(row?.preLaunch);
                    setPricingPlan(row?.type);
                    setUserType(row?.userType);
                    handleForUpdateManager(row._id);
                    setIdForEditType(row?._id);
                    setRoleNameUpdate(row.planName);
                    setCostUpdate(row.planCost);
                    setValidityUpdate(row.validity);
                    setPlanTypeUpdate(row.userType);
                  }}
                >
                  <CreateIcon />
                </div>

                {/* <div
              className="cursor-pointer"
              onClick={(e) => {
                console.log("delete", e.target);
                setIdForDelete(row?._id);
                setShow(true);
              }}
            >
              <Tooltip title="Erinnerung löschen" arrow>
                <DeleteIcon />
              </Tooltip>
            </div> */}
              </div>
            </div>
          </>
        );
      },
      wrap: true,

      sortable: true,
    },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setCreaterdata((prevState) => ({
      ...prevState,

      [name]: value,
    }));

    setErrors({ ...errors, [name]: "" });
  };

  console.log("ASSASASASASA", createrData?.type);

  const formValidator = () => {
    let isValid = true;
    console.log("createrDatacreaterData", createrData?.planName);
    // if (!createrData.planName) {
    //   setErrors((cv) => {
    //     return { ...cv, planName: "Please Enter plan name" };
    //   });
    //   isValid = false;
    // }

    if (!createrData.preLaunch) {
      setErrors((cv) => {
        return { ...cv, preLaunch: "Please select plan type" };
      });
      isValid = false;
    }

    if (!createrData.type) {
      setErrors((cv) => {
        return { ...cv, type: "Please select type" };
      });
      isValid = false;
    }

    if (!createrData.userType) {
      setErrors((cv) => {
        return { ...cv, userType: "Please select user type" };
      });
      isValid = false;
    }

    if (!createrData.planDescription) {
      setErrors((cv) => {
        return { ...cv, planDescription: "Please Enter description" };
      });
      isValid = false;
    }

    if (!createrData.planCost) {
      setErrors((cv) => {
        return { ...cv, planCost: "Please Enter cost" };
      });
      isValid = false;
    }

    if (!createrData.validity) {
      setErrors((cv) => {
        return { ...cv, validity: "Please Enter validity" };
      });
      isValid = false;
    }
    return isValid;
  };

  const formUpdateValidator = () => {
    let isValid = true;

    if (!roleNameUpdate || roleNameUpdate == "Plan") {
      setErrors((cv) => {
        return { ...cv, name: "Please Enter  Name" };
      });
      isValid = false;
    }

    return isValid;
  };

  const submitDetails = async () => {
    if (!formValidator()) {
      console.log("ERROR POST", errors);
      return;
    }

    setLoading(true);

    await ApiPost(`plan`, createrData)
      .then(async (res) => {
        console.log("add plan", res);

        if (res.data && res.data.payload && res.data.payload) {
          setUpdateProduct(false);
          setLoading(false);
          setDataChange([...dataChange, "Details Added"]);
          setRoleName();
          setDescription();
          setCreaterdata({});
          setOpen(false);
          toast.success(res.data.message);
        } else {
          setMsg(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  // const handleSearch = (e) => {
  //   console.log("eeeee", e.target.value);
  //   var value = e.target.value?.toLowerCase();
  //   setFilteredReport(() =>
  //     paymentData?.filter(
  //       (item) =>
  //         item?.planName?.toLowerCase().includes(value)||
  //         item?.planDescription?.toLowerCase().includes(value)||
  //         item?.planCost?.toLowerCase().includes(value)

  //     )
  //   );
  // };

  const handleSearch = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setFilteredReport(() =>
      paymentData?.filter(
        (item) =>
          item?.planName?.toLowerCase().includes(value) ||
          item?.planDescription?.toLowerCase().includes(value) ||
          item?.planCost?.toLowerCase().includes(value)
      )
    );
  };

  return (
    <>
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Payments</h2>
            </div>
            {/* <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="title"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div> */}
            <div className="cus-medium-button-style button-height">
              {/* <button
                className="btn btnColor"
                onClick={() => {
                  // setUpdateDetails(false);
                  handleClickOpen();
                }}
              >
                Add Plan
              </button> */}
              <CsvDownload
                className={``}
                data={dataCSVAdmin}
                filename="payment.csv"
                style={{
                  //pass other props, like styles
                  backgroundColor: "#F64E60",
                  borderRadius: "6px",
                  border: "1px solid #fff",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "#FFFFFF",
                  fontSize: "12px",
                  padding: "10px 18px",
                  textDecoration: "none",
                  position: "right",
                }}
              >
                Export to Csv
              </CsvDownload>
            </div>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={filteredReport}
          pagination
          customStyles={customStyles}
          style={{
            marginTop: "-3rem",
          }}
          progressPending={isLoaderVisible}
          progressComponent={
            <Loader type="Puff" color="#334D52" height={30} width={30} />
          }
        />
      </div>

      {/* delete model */}
      <Modal show={show} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            {/*Warnung!*/}Warning!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you Sure delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              DeletePayment();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* end delete model */}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          {updateProduct && updateProduct === true ? (
            <div className="form ml-30 ">
              {/* Account Number */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Plan Duration
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      name="planName"
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={createrData?.planName}
                    >
                      <option value={undefined}>Select Duration </option>
                      <option value={"Annually"}>Annually</option>
                      <option value={"Monthly"}>Monthly</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["planName"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Plan Type
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    {/* <input
                      type="text"
                      autoComplete="off"
                      placeholder="Plan name"
                      className={`form-control form-control-lg form-control-solid `}
                      name="planName"
                      value={roleName}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    /> */}

                    <select
                      name="preLaunch"
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={roleName}
                    >
                      <option value={undefined}>Select Plan</option>
                      <option value={"standard"}>Standard</option>
                      <option value={"prelaunch"}>Pre-Launch</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["preLaunch"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Plan Description
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Description"
                      className={`form-control form-control-lg form-control-solid `}
                      name="planDescription"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["planDescription"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Plan Cost
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="number"
                      autoComplete="off"
                      placeholder="plan cost"
                      className={`form-control form-control-lg form-control-solid `}
                      name="planCost"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["planCost"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Plan Validity
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="number"
                      autoComplete="off"
                      placeholder="plan validity"
                      className={`form-control form-control-lg form-control-solid `}
                      name="validity"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["validity"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  User Type
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      name="type"
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <option value={undefined}>select</option>
                      <option value={"user"}>User</option>
                      <option value={"institution"}>Institution</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["type"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Pricing-Plan
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      name="userType"
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      disabled={createrData?.type ? false : true}
                      value={createrData.userType}
                    >
                      <option value={undefined}>select</option>
                      {createrData?.type == "user" && (
                        <option value={"individual"}>Individual</option>
                      )}

                      {createrData?.type == "user" && (
                        <option value={"client"}>Client Co</option>
                      )}

                      {createrData?.type == "institution" && (
                        <option value={"institution"}>Institution</option>
                      )}
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["userType"]}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    !loading && submitDetails(e);
                  }}
                  className="btn btnColor mr-2"
                >
                  <span>Submit Details</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          ) : null}
        </List>
        <List>
          {updateDetails && updateDetails === true ? (
            <div className="form ml-30 ">
              {/* First Name */}

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Plan Type
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    {/* <input
                      type="text"
                      autoComplete="off"
                      placeholder="Plan name"
                      className={`form-control form-control-lg form-control-solid `}
                      name="planName"
                      value={roleName}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    /> */}

                    <select
                      name="preLaunch"
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        setPlanType(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, planType: "" };
                        });
                      }}
                      value={planType}
                    >
                      <option value={undefined}>Select Plan</option>
                      <option value={"Basic"}>Basic</option>
                      <option value={"Premium"}>Premium</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["preLaunch"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Plan Description:
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="planDescription"
                      value={descriptionUpdate}
                      onChange={(e) => {
                        setDescriptionUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleUpdate: "" };
                        });
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["planDescription"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Plan Cost:
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="planCost"
                      value={costUpdate}
                      onChange={(e) => {
                        setCostUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleUpdate: "" };
                        });
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["planCost"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Plan Validity:
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="validity"
                      value={validatityUpdate}
                      onChange={(e) => {
                        setValidityUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleUpdate: "" };
                        });
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["validity"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">User</label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      name="type"
                      value={planTypeUpdate}
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        setPlanTypeUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleUpdate: "" };
                        });
                      }}
                    >
                      <option value={undefined}>select</option>
                      <option value={"Individual"}>Individual</option>
                      <option value={"Business"}>Business</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["validity"]}
                  </span>
                </div>
              </div>

              {/* <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Pricing-Plan
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      name="userType"
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        setUserType(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, userType: "" };
                        });
                      }}
                      disabled={planTypeUpdate ? false : true}
                      value={userType}
                    >
                      <option value={undefined}>select</option>
                      {planTypeUpdate == "user" && (
                        <option value={"individual"}>Individual</option>
                      )}

                      {planTypeUpdate == "user" && (
                        <option value={"client"}>Client Co</option>
                      )}

                      {planTypeUpdate == "institution" && (
                        <option value={"institution"}>Institution</option>
                      )}
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["userType"]}
                  </span>
                </div>
              </div> */}

              <div className="d-flex align-items-center justify-content-center">
                {submitValue && submitValue === true ? (
                  <button
                    onClick={(e) => {
                      handelUpdateDetails();
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Update Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      submitDetails(e);
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Submit Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </List>
      </Dialog>
    </>
  );
};

export default Payment;
