import React, { useEffect, useState } from "react";
import { ApiGet } from "../../../helpers/API/ApiData";
import { getUserInfo } from "../../../utils/user.util";
import User from "../../../_metronic/components/User/User";
import FAQ from "../../../_metronic/components/FAQ/FAQ";
import { Line } from "react-chartjs-2";
import Counter from "react-number-counter";
import DataTable, { defaultThemes } from "react-data-table-component";
import Loader from "react-loader-spinner";

function Deshbord1({ className }) {
  let userInfo = getUserInfo();
  const [artistData, setArtistData] = useState();
  const [adminData, setAdminData] = useState();
  const [selectedTable, setSelectedTable] = useState("Users");
  const [selectedArtistTable, setArtistSelectedTable] = useState("Music");
  const [affiliatesData, setAffiliatesData] = useState();
  const [artistAffiliateData, setArtistAffiliatesData] = useState();
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isLoaderVisibleForUser, setIsLoaderVisibleForUser] = useState(false);
  const [userapicall, setUserapicall] = useState(false);
  const [organization, setOrganization] = useState();
  const [totalcompany, setTotalcompany] = useState();
  const [Independent, setIndependent] = useState();
  //for try to set data in dashboard
  const [QuestionCount, setQuestionCount] = useState();
  const [QuestionTodayCount, setQuestionTodayCount] = useState();
  const [totalMonthName, settotalMonthName] = useState();
  const [CurrentMonth, setCurrentMonth] = useState();
  const [lastMonth, setLastMonth] = useState();
  const [lastThreeMonth, setLastThreeMonth] = useState();
  const [totalYear, setTotalYear] = useState();
  const [userCont, setuserCont] = useState();
  const [UserTodaysCount, setUserTodaysCount] = useState();
  const [questionProfileaccess, setQuestionProfileaccess] = useState();
  const [questionWithoutProfileaccess, setQuestionwithoutProfileaccess] =
    useState();
  const [totalmonth, setTotalMonth] = useState();

  console.log("questionProfileaccess", questionProfileaccess);

  useEffect(() => {
    console.log(totalmonth, "totalmonth");
  }, [totalmonth]);

  const MonthNamemapping = totalmonth?.map((data) => data?._id);
  const totaluserMap = totalmonth?.map((data) => data?.users);
  const YearMappign = totalYear?.map((data) => data?._id);
  const YearTotal = totalYear?.map((data) => data?.users);
  const data = {
    labels: MonthNamemapping,
    datasets: [
      {
        label: "Total current Month Registration",
        data: totaluserMap,
        fill: true,
        // data: [33, 25, 35, 51, 54, 76],

        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: "Total Current Month Registration",
        data: YearTotal,
        fill: false,
        borderColor: "#742774",
      },
    ],

    datasets: [
      {
        label: "Total Current Month Registration",
        data: totaluserMap,
        fill: true,
        // data: [33, 25, 35, 51, 54, 76],

        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      // {
      //   label: "Total year Registration",
      //   data: YearTotal,
      //   fill: false,
      //   borderColor: "#742774",
      // },
    ],
  };

  const mapdata = [
    { country: "cn", value: 1389618778 }, // china
    { country: "in", value: 1311559204 }, // india
    { country: "us", value: 331883986 }, // united states
    { country: "id", value: 264935824 }, // indonesia
    { country: "pk", value: 210797836 }, // pakistan
    { country: "br", value: 210301591 }, // brazil
    { country: "ng", value: 208679114 }, // nigeria
    { country: "bd", value: 161062905 }, // bangladesh
    { country: "ru", value: 141944641 }, // russia
    { country: "mx", value: 127318112 }, // mexico
  ];

  useEffect(() => {
    rolegetApi();
    getFaqData();
  }, []);

  // Api count

  const [questioonMessage, setquestioonMessage] = useState();
  const getFaqData = async () => {
    await ApiGet("question/get-question")
      .then((res) => {
        console.log("sdfsdfsdfsfd", res);
        setQuestionCount(res?.data?.payload?.questioncount);
        setQuestionTodayCount(res?.data?.payload?.todaysCount);
        setQuestionProfileaccess(res?.data?.payload?.profileaccess);
        setQuestionwithoutProfileaccess(
          res?.data?.payload?.withoutprofileaccess
        );
        setquestioonMessage(res?.data?.payload?.questions);

        console.log("pppppppp", res.data.payload);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // get response

  const FoundData = questioonMessage?.map((item) => {
    if (item?.response == 0) {
      return item;
    }
  });

  const foundWithDataFuntion = FoundData?.filter(function (element) {
    return element !== undefined;
  });

  const FoundWithData = questioonMessage?.map((item) => {
    if (item?.response !== 0) {
      return item;
    }
  });

  const foundWithMessage = FoundWithData?.filter(function (element) {
    return element !== undefined;
  });

  const ProfileAccess = questioonMessage?.map((item) => {
    if (item?.displayProfile == false) {
      return item;
    }
  });

  const ProFileWithDataFuntion = ProfileAccess?.filter(function (element) {
    return element !== undefined;
  });

  const WithOutProfileAccess = questioonMessage?.map((item) => {
    if (item?.displayProfile == true) {
      return item;
    }
  });

  const WithOutProfileAccessFuntion = WithOutProfileAccess?.filter(function (
    element
  ) {
    return element !== undefined;
  });

  const rolegetApi = async () => {
    await ApiGet("user")
      .then((res) => {
        console.log("sdfadfdfdaf", res);
        setUserapicall(true);
        setOrganization(res.data.payload.TotalOrganization);
        setTotalcompany(res.data.payload.TotalCompany);
        setIndependent(res.data.payload.IndependentUsers);
        setCurrentMonth(res.data.payload.ThisMonthUsers);
        setLastMonth(res.data.payload.LastMonthUsers);
        setLastThreeMonth(res.data.payload.LastThreeMonthUsers);
        setUserTodaysCount(res.data.payload.todaysCount);
        setuserCont(res?.data?.payload?.count);
        setTotalMonth(res?.data?.payload?.totalmonth);
        setTotalYear(res?.data?.payload?.totalYear);
        // data.payload.
      })
      .catch((error) => {});
  };

  useEffect(() => {
    console.log(userCont, "---------------==================");
  }, [userCont]);

  let i = 0;
  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "10%",
    },

    {
      name: "Subject",
      cell: (row) => {
        return <>{row?._id}</>;
      },
      selector: (row) => row?._id,
      sortable: true,
    },
    {
      name: "User",
      cell: (row) => {
        return <>{row?.count}</>;
      },
      wrap: true,
      selector: (row) => row?.count,
      width: "30%",
    },
  ];

  const columns1 = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "80px",
    },

    {
      name: "Subject",
      cell: (row) => {
        return <>{row[0]?.name}</>;
      },
      selector: (row) => row[0]?.name,
      sortable: true,
    },
    {
      name: "Total Question Asked",
      cell: (row) => {
        return <>{row[1]?.questionByUser}</>;
      },
      wrap: true,
      selector: (row) => row[1]?.questionByUser,
      sortable: true,
    },

    {
      name: "Total Answers",
      cell: (row) => {
        return <>{row[2]?.answerByuser}</>;
      },
      wrap: true,
      selector: (row) => row[2]?.answerByuser,
      sortable: true,
    },
  ];
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const [filterData, setfilterData] = useState();
  const [UserfilterData, setUserfilterData] = useState();

  useEffect(() => {
    Top5subject();
    TopUser();
  }, []);
  const Top5subject = async () => {
    setIsLoaderVisible(true);
    await ApiGet("filter/")
      .then((res) => {
        setIsLoaderVisible(false);
        console.log("topfiveSub", res.data);
        setfilterData(res.data.payload.topSubjects.slice(0, 5));
      })
      .catch((err) => {
        setIsLoaderVisible(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    console.log("filter data manaable array", filterData);
  }, [filterData]);

  //   let element;
  //   for (let i = 0; i < 5; i++) {
  //     element = filterData[i];
  //   }

  //   console.log(element, "element");

  const TopUser = async () => {
    setIsLoaderVisibleForUser(true);
    await ApiGet("user/topUser")
      .then((res) => {
        console.log("top5users", res.data);
        setIsLoaderVisibleForUser(false);
        setUserfilterData(res.data.payload.topUsers.slice(0, 5));
      })
      .catch((err) => {
        setIsLoaderVisibleForUser(false);
        console.log("err", err);
      });
  };

  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* Header */}
      <div
        className="card-header border-0 py-1 px-1 m-5"
        style={{ backgroundColor: "#e61952" }}
      >
        <div className="card-body p-0 position-relative overflow-hidden">
          {/* Stat */}
          <div
            // id="kt_mixed_widget_1_chart"
            className="card-rounded-bottom"
            style={{ height: "30px" }}
          >
            {/* <h2 className="card-title font-weight-bolder text-white pt-2 pl-6">
              Dashboard
            </h2> */}
          </div>
          {/* {userInfo?.user.role === "Admin" ? ( */}
          <>
            <div className="my-5 mx-5">
              <div className="card-spacer">
                <div
                  className="carder-box"
                  style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "repeat(3,1fr)",
                  }}
                >
                  <div className="bg-light-warning px-6 py-8 rounded-xl">
                    <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                      {/* <CountUp end={totalMusic} /> */}
                      {/* {userCont ? userCont :"Loadning..."} */}
                      {userCont && (
                        <Counter
                          start={100}
                          end={userCont && userCont}
                          delay={1}
                          className={"randomClass"}
                        />
                      )}
                    </span>
                    <a
                      href="#"
                      className="text-warning font-weight-bold font-size-h6"
                      onClick={() => setSelectedTable("Users")}
                    >
                      Total Users
                    </a>
                  </div>

                  <div className="bg-light-success px-6 py-8 rounded-xl">
                    <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                      {/* <CountUp end={totalMusic} /> */}
                      {/* {userCont ? userCont :"Loadning..."} */}
                      {CurrentMonth && (
                        <Counter
                          start={100}
                          end={CurrentMonth && CurrentMonth}
                          delay={1}
                          className={"randomClass"}
                        />
                      )}
                    </span>
                    <a
                      href="#"
                      className="text-warning font-weight-bold font-size-h6"
                      onClick={() => setSelectedTable("Users")}
                    >
                      Total Current Month Users
                    </a>
                  </div>

                  <div className="bg-light-warning px-6 py-8 rounded-xl">
                    <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                      {/* <CountUp end={totalMusic} /> */}
                      {/* {userCont ? userCont :"Loadning..."} */}
                      {lastMonth && (
                        <Counter
                          start={100}
                          end={lastMonth && lastMonth}
                          delay={1}
                          className={"randomClass"}
                        />
                      )}
                    </span>
                    <a
                      href="#"
                      className="text-warning font-weight-bold font-size-h6"
                      onClick={() => setSelectedTable("Users")}
                    >
                      Total Previous Month Users
                    </a>
                  </div>

                  <div className="bg-light-warning px-6 py-8 rounded-xl">
                    <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                      {/* <CountUp end={totalMusic} /> */}
                      {/* {userCont ? userCont :"Loadning..."} */}
                      {lastThreeMonth && (
                        <Counter
                          start={100}
                          end={lastThreeMonth && lastThreeMonth}
                          delay={1}
                          className={"randomClass"}
                        />
                      )}
                    </span>
                    <a
                      href="#"
                      className="text-warning font-weight-bold font-size-h6"
                      onClick={() => setSelectedTable("Users")}
                    >
                      Total Three Month Users
                    </a>
                  </div>

                  <div className="bg-light-warning px-6 py-8 rounded-xl">
                    <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                      {/* <CountUp end={totalMusic} /> */}
                      {/* {userCont ? userCont :"Loadning..."} */}
                      {organization && (
                        <Counter
                          start={100}
                          end={organization && organization}
                          delay={1}
                          className={"randomClass"}
                        />
                      )}
                    </span>
                    <a
                      href="#"
                      className="text-warning font-weight-bold font-size-h6"
                      onClick={() => setSelectedTable("Users")}
                    >
                      Total organization Users
                    </a>
                  </div>

                  <div className="bg-light-warning px-6 py-8 rounded-xl">
                    <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                      {/* <CountUp end={totalMusic} /> */}
                      {/* {userCont ? userCont :"Loadning..."} */}
                      {totalcompany && (
                        <Counter
                          start={100}
                          end={totalcompany && totalcompany}
                          delay={1}
                          className={"randomClass"}
                        />
                      )}
                    </span>
                    <a
                      href="#"
                      className="text-warning font-weight-bold font-size-h6"
                      onClick={() => setSelectedTable("Users")}
                    >
                      Total Company Users
                    </a>
                  </div>

                  <div className="bg-light-warning px-6 py-8 rounded-xl">
                    <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                      {/* <CountUp end={totalMusic} /> */}
                      {/* {userCont ? userCont :"Loadning..."} */}
                      {Independent && (
                        <Counter
                          start={100}
                          end={Independent && Independent}
                          delay={1}
                          className={"randomClass"}
                        />
                      )}
                    </span>
                    <a
                      href="#"
                      className="text-warning font-weight-bold font-size-h6"
                      onClick={() => setSelectedTable("Users")}
                    >
                      Total Independent Users
                    </a>
                  </div>

                  <div className="bg-light-danger px-6 py-8 rounded-xl">
                    <span className="text-danger font-weight-bold font-size-h3 d-block my-2">
                      {/* {UserTodaysCount ? UserTodaysCount : "Loadning..."} */}

                      {userapicall
                        ? UserTodaysCount && (
                            <Counter
                              start={100}
                              end={UserTodaysCount && UserTodaysCount}
                              delay={10}
                              className={"randomClass"}
                            />
                          )
                        : ""}
                    </span>
                    <a
                      href="#"
                      className="text-danger font-weight-bold font-size-h6 mt-2"
                      // onClick={() => setSelectedTable("TodayRegisteration")}
                    >
                      Today's Registration
                    </a>
                  </div>
                  <div className="bg-light-success px-6 py-8 rounded-xl">
                    <span className="text-success font-weight-bold font-size-h3 d-block my-2">
                      {/* <CountUp end={totalDownload && totalDownload} /> */}
                      {userapicall
                        ? QuestionCount && (
                            <Counter
                              start={100}
                              end={QuestionCount && QuestionCount}
                              delay={10}
                              className={"randomClass"}
                            />
                          )
                        : ""}
                    </span>
                    <a
                      href="#"
                      className="text-success font-weight-bold font-size-h6 mt-2 mr-7"
                      onClick={() => setSelectedTable("TotalQuestions")}
                    >
                      Total Questions
                    </a>
                  </div>
                  <div className="bg-light-success px-6 py-8 rounded-xl">
                    <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                      {/* <CountUp end={totalMusic} /> */}

                      {userapicall
                        ? QuestionTodayCount && (
                            <Counter
                              start={100}
                              end={QuestionTodayCount && QuestionTodayCount}
                              delay={10}
                              className={"randomClass"}
                            />
                          )
                        : ""}
                    </span>
                    <a
                      href="#"
                      className="text-warning font-weight-bold font-size-h6"
                      // onClick={() => setSelectedTable("TodaysQuestions")}
                    >
                      Today's Questions
                    </a>
                  </div>
                  <div className="bg-light-warning px-6 py-8 rounded-xl">
                    <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                      {/* <CountUp end={totalMusic} /> */}
                      {/* {WithOutProfileAccessFuntion ?  WithOutProfileAccessFuntion?.length : "Loadning..."} */}

                      {userapicall
                        ? questionProfileaccess && (
                            <Counter
                              start={100}
                              end={
                                questionProfileaccess && questionProfileaccess
                              }
                              delay={10}
                              className={"randomClass"}
                            />
                          )
                        : ""}
                    </span>
                    <a
                      href="#"
                      className="text-warning font-weight-bold font-size-h6"
                      // onClick={() =>
                      //   setSelectedTable("QuestionWithProfileAccess")
                      // }
                    >
                      Question With Profile Access
                    </a>
                  </div>
                  <div className="bg-light-danger px-6 py-8 rounded-xl">
                    <span className="text-danger font-weight-bold font-size-h3 d-block my-2">
                      {/* <CountUp end={totalPurchase && totalPurchase} /> */}
                      {/* {ProFileWithDataFuntion ?  ProFileWithDataFuntion?.length : "Loadning..."} */}

                      {userapicall
                        ? questionWithoutProfileaccess && (
                            <Counter
                              start={100}
                              end={
                                questionWithoutProfileaccess &&
                                questionWithoutProfileaccess
                              }
                              delay={10}
                              className={"randomClass"}
                            />
                          )
                        : ""}
                    </span>
                    <a
                      href="#"
                      className="text-danger font-weight-bold font-size-h6 mt-2"
                      // onClick={() =>
                      //   setSelectedTable("QuestionWithoutProfileAccess")
                      // }
                    >
                      Question Without Profile Access
                    </a>
                  </div>
                  {/* <div className="col bg-light-success px-6 py-8 rounded-xl">
                  <span className="text-success font-weight-bold font-size-h3 d-block my-2">
                    {foundWithMessage && (
                      <Counter
                        start={100}
                        end={foundWithMessage?.length}
                        delay={10}
                        className={"randomClass"}
                      />
                    )}
                  </span>
                  <a
                    href="#"
                    className="text-success font-weight-bold font-size-h6 mt-2 mr-7"
                    onClick={() => setSelectedTable("QuestionWithMessaging")}
                  >
                    Question With Messaging
                  </a>
                </div>
                <div className="col bg-light-success px-6 py-8 rounded-xl ml-5">
                  <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                    {foundWithDataFuntion && (
                      <Counter
                        start={100}
                        end={foundWithDataFuntion?.length}
                        delay={10}
                        className={"randomClass"}
                      />
                    )}
                  </span>
                  <a
                    href="#"
                    className="text-warning font-weight-bold font-size-h6"
                    onClick={() => setSelectedTable("QuestionWithoutMessaging")}
                  >
                    Question Without Messaging
                  </a>
                </div> */}
                  {/* <div className="col bg-light-danger px-6 py-8 rounded-xl">
                                    <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                                        {7}
                                    </span>
                                    <a
                                        href="#"
                                        className="text-warning font-weight-bold font-size-h6"
                                        onClick={() => setSelectedTable("QuestionWithoutMessaging")}
                                    >
                                        Question Without Messaging
                                    </a>
                                </div> */}
                </div>
              </div>
            </div>
            {/* <div className="my-5 mx-5">
              {
                selectedTable === "Users" ? (
                  <User />
                ) : selectedTable === "TotalQuestions" ? (
                  <FAQ />
                ) : selectedTable === "TodayRegisteration" ? (
                  <User />
                ) : selectedTable === "TodaysQuestions" ? (
                  <FAQ />
                ) : selectedTable === "QuestionWithProfileAccess" ? (
                  <FAQ />
                ) : selectedTable === "QuestionWithoutProfileAccess" ? (
                  <FAQ />
                ) : selectedTable === "QuestionWithMessaging" ? (
                  <FAQ />
                ) : selectedTable === "QuestionWithoutMessaging" ? (
                  <FAQ />
                ) : (
                  <></>
                )
                // selectedTable === "Artists" ? (
                //   <ArtistsReport />
                // ) : selectedTable === "Products" ? (
                //   <ProductReport />
                // ) : selectedTable === "PurchasedMusic" ? (
                //   <PurchasedMusic />
                // ) : selectedTable === "DownloadedMusic" ? (
                //   <DownloadedMusic />
                // ) : (
                //   <></>
                // )
              }
            </div> */}
          </>
          {/* <div className="resize-triggers">
                        <div className="expand-trigger">
                            <div style={{ width: "411px", height: "461px" }} />
                        </div>
                        <div className="contract-trigger" />
                    </div> */}
        </div>
      </div>

      {MonthNamemapping && (
        <div className="row clearfix m-5">
          <div className="col-md-12 col-lg-8">
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <h2>
                    <strong>Total Current Month Registration</strong>
                  </h2>
                </div>

                <div className="card ">
                  <div className="body text-center">
                    <div className="App">
                      <Line data={data} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="row card-header border-0  py-1 px-1 m-5"
        style={{ backgroundColor: "rgb(230, 25, 82)" }}
      >
        <div className="col-lg-6">
          <div className="card-spacer">
            <div className="row m-5">
              <div className="col bg-light-warning px-6 py-8 rounded-xl mr-5">
                <h2>Top 5 Users</h2>

                {isLoaderVisibleForUser ? (
                  <div
                    className="d-flex justify-content-center"
                    // style={{ width: "480px", height: "300px" }}
                  >
                    <Loader
                      type="Puff"
                      color="#334D52"
                      height={30}
                      width={30}
                    />
                  </div>
                ) : (
                  <DataTable
                    columns={columns1}
                    data={UserfilterData}
                    customStyles={customStyles}
                    style={{
                      marginTop: "-3rem",
                    }}
                    // progressPending={isLoaderVisibleForUser}
                    // progressComponent={
                    //   <Loader type="Puff" color="#334D52" height={30} width={30} />
                    // }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card-spacer">
            <div className="row m-5">
              <div className="col bg-light-warning px-6 py-8 rounded-xl mr-5">
                <h2>Top 5 Subject</h2>
                {isLoaderVisible ? (
                  <div
                    className="d-flex justify-content-center"
                    // style={{ width: "480px", height: "300px" }}
                  >
                    <Loader
                      type="Puff"
                      color="#334D52"
                      height={30}
                      width={30}
                    />
                  </div>
                ) : (
                  <DataTable
                    columns={columns}
                    data={filterData}
                    customStyles={customStyles}
                    style={{
                      marginTop: "-3rem",
                      height: "285px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deshbord1;
