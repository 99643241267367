// const protocol = "http";
// const host = "localhost:8088/api/v1";

// const protocol = "https"; //live
// const host = "leaderbridge-backend.onrender.com/api/v1"; // for live

const protocol = "https";
const host = "api.leadership.NET/api/v1";

// const protocol = "https"; //live
// const host = "api.leaderbridge.rejoicehub.com/api/v1"; // for devlopment

//
const port = "";
const trailUrl = "";

const hostUrl = `${protocol}://${host}${port ? ":" + port : ""}`;
const endpoint = `${protocol}://${host}${port ? ":" + port : ""}${trailUrl}`;

console.log("URL", hostUrl);

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
};
