import React, { useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { getUserInfo } from "../../../utils/user.util";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import renderHTML from "react-render-html";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderScroll from "./Loader";
import EndMsg from "./Endmessag";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FAQ = () => {
  let userInfo = getUserInfo();
  const [openCreate, setOpenCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allFaq, setAllFaq] = useState([]);
  const [errors] = useState([]);
  const [show, setShow] = useState(false);
  const [greatshow, setgreatShow] = useState(false);
  const [faqId, setFaqId] = useState();
  const [title, setTitle] = useState();
  const [status, setstatus] = useState();
  const [statusid, setstatusid] = useState();
  const [description, setDescription] = useState("");
  const [dataChange, setDataChange] = useState([]);
  const [openUpdate, setOpenUpdate] = useState();
  const [titleupdate, settitleupdate] = useState("");
  const [statusupdate, setstatusupdate] = useState();
  const [descriptionupdate, setdescriptionupdate] = useState("");

  const [items, setItems] = useState([]);

  const [hasMore, sethasMore] = useState(true);

  const [page, setpage] = useState(2);
  const [loaderHas, setLoaderHas] = useState(false);

  const handleCloseCreate = () => {
    setOpenCreate(false);
    settitleupdate("");
    setdescriptionupdate("");
  };

  const handleClose = () => {
    setShow(false);
  };

  const greathandleClose = () => {
    setgreatShow(false);
  };

  useEffect(() => {
    getFaqData();
  }, []);

  const handleUpdateClose = () => {
    setstatusupdate("");
    setOpenUpdate(false);
  };

  //////////////////////////////////////////////////////////////////  get FAQ data//////////////////////////////////////////////////

  // pagenation add
  const getFaqData = async () => {
    // if (search) {
    //   // setItems([]);
    //   await ApiGet(`contact/getcontact?search=${search}`)
    //     .then((res) => {
    //       console.log(res.data.payload, "WWWWWWWWW");
    //       // setAllFaq(res.data.payload.ContactType);
    //       // setItems(res.data.payload.ContactType);
    //       // setUserReport(res.data.payload.ContactType);
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // } else {
    await ApiGet("contact/getcontact?page=1&limit=20")
      .then((res) => {
        console.log(res.data.payload, "userdatdatd");
        setAllFaq(res.data.payload.ContactType);
        setItems(res.data.payload.ContactType);
        setUserReport(res.data.payload.ContactType);
      })
      .catch((err) => {
        console.log("err", err);
      });
    // }
  };

  const fetchComments = async () => {
    const res = await ApiGet(`contact/getcontact?page=${page}&limit=20`);

    const data = await res.data.payload.ContactType;
    console.log("getQuestion", res.data.payload.ContactType);
    // setUserReport(res?.data?.payload?.questions);
    console.log("pppppppp", res);
    // setIsLoaderVisible(false);

    return data;
  };

  const fetchData = async () => {
    const commentsFormServer = await fetchComments();

    console.log(commentsFormServer, "commentsFormServer");
    setItems([...items, ...commentsFormServer]);
    setUserReport([...items, ...commentsFormServer]);
    if (commentsFormServer.length === 0 || commentsFormServer.length < 20) {
      sethasMore(false);
    }
    setpage(page + 1);
  };

  ////////////////////////////////////////////////////////////////// delete Policy data///////////////////////////////////////////////

  const handleDataDelete = async () => {
    setLoaderHas(true);
    await ApiDelete(`contact/deletecontact/id=${faqId}`)
      .then((res) => {
        handleClose();
        getFaqData();
        setLoaderHas(false);
      })
      .catch((err) => {
        setLoaderHas(false);
        console.log("err");
      });
  };

  //////////////////////////////////////////////////////////////////  add Policy data//////////////////////////////////////////////////  check and uncomment all the function to add update delete

  //////////////////////////////////////////////////////////////////  update Policy data//////////////////////////////////////////////// check and uncomment all the function to add update delete

  const handleForUpdateFaq = async (data) => {
    console.log(data);
    setTitle(data.title);
    setDescription(data.description);
    setFaqId(data._id);
  };
  const handleUpdateFaq = async () => {
    setLoading(true);
    if (title === "" && description == "") {
      settitleupdate(" please Enter Question ");
      setdescriptionupdate(" please Enter Answer  ");
      setLoading(false);
    } else if (title === "") {
      settitleupdate(" please Enter Question ");
      setLoading(false);
    } else if (description === "") {
      setdescriptionupdate("please Enter Answer  ");
      setLoading(false);
    } else {
      const FaqData = {
        title: title,
        description: description,
        id: faqId,
      };
      await ApiPut("faq/update-faq-content", FaqData)
        .then((res) => {
          setLoading(false);
          setOpenUpdate(false);
          getFaqData();
          toast.success(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          toast.success(err);
          console.log(err);
        });
    }
  };

  const handlealtermessage = (id, status) => {
    setgreatShow(true);
    setstatus(status);
    setstatusid(id);
  };

  const [userReport, setUserReport] = useState();
  const [filteredReport, setFilteredReport] = useState();
  const [search, setSearch] = useState("");

  const handleSearch = async (e) => {
    // console.log("eeeee", e.target.value);
    setSearch(e.target.value);
    var value = e.target.value.toLowerCase();
    // setItems(() =>
    //   userReport.filter(
    //     (item) =>
    //       item?.email?.toLowerCase().includes(value) ||
    //       item?.message?.toLowerCase().includes(value) ||
    //       item?.subject?.toLowerCase().includes(value) ||
    //       item?.name?.toLowerCase().includes(value)
    //   )
    // );

    if (value) {
      // setInterval(async () => {
      await ApiGet(`contact/getcontact?search=${value}`)
        .then((res) => {
          console.log(res.data.payload, "WWWWWWWWW");
          // setAllFaq(res.data.payload.ContactType);
          setItems(res.data.payload.SearchData);
          // setUserReport(res.data.payload.ContactType);
        })
        .catch((err) => {
          console.log("err@@", err);
        });
      // }, 1000);
    } else {
      getFaqData();
    }
  };

  // reply functions
  const [replyDataStore, setreplyDataStore] = useState();
  const [ContactName, setContactName] = useState();
  const [replyEmailDataStore, setreplyEmailDataStore] = useState();
  const Replaydata = (data) => {
    console.log(data, "rplaydata");
    setOpenCreate(true);
    setreplyDataStore(data._id);
    setreplyEmailDataStore(data.email);
    setContactName(data.name);
  };

  const HandleSeacrh = () => {};

  // update status

  const handelUpdateDetails = async () => {
    // if (!formUpdateValidator()) {
    //   console.log("ERROR PUT", errors);
    //   return;
    // }

    setLoading(true);

    await ApiPut(`contact/updatestatus/id=${statusid}&status=${status}`)
      .then((res) => {
        //   window.location.reload();

        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        setgreatShow(false);

        // toast.success(res.data.message);

        toast.success(
          `${
            res.data.payload.updateUser.status == true ? "Active" : "Deactive"
          }d`
        );
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);

        console.log(err);
      });
  };

  // input value
  const [createrData, setCreaterdata] = useState({});
  const [eerrors, setEerrors] = useState({});

  const handleChange = (e) => {
    let { name, value } = e.target;

    setCreaterdata((prevState) => ({
      ...prevState,

      [name]: value,
      id: replyDataStore,
      email: replyEmailDataStore,
      name: ContactName,
    }));

    setEerrors({ ...eerrors, [name]: "" });
  };
  const formValidator = () => {
    let isValid = true;

    if (!createrData.subject) {
      setEerrors((cv) => {
        return { ...cv, subject: "Please Enter  Name" };
      });
      isValid = false;
    }

    if (!createrData.message) {
      setEerrors((cv) => {
        return { ...cv, message: "Please Enter email" };
      });
      isValid = false;
    }

    return isValid;
  };
  // replay Fetch api
  const handlesubmit = async (id) => {
    if (!formValidator()) {
      console.log("ERROR POST", eerrors);
      return;
    }
    setLoading(true);

    await ApiPost("contact/emailcontact", createrData)
      .then((res) => {
        console.log("policy", res);
        setLoading(false);
        setOpenCreate(false);
        getFaqData();
        // setLoading(false);
        // setTitle("");
        // setDescription("");
        // setOpenCreate(false);
        // setDataChange([...dataChange, "FAQ add"]);
        console.log(res, "newnewnewnewne");
        toast.success(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const HandleOpenCreate = (data) => {
    setOpenUpdate(true);
    setstatusupdate(data);
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <ToastContainer />

      <InfiniteScroll
        dataLength={items?.length} //This is important field to render the next data
        next={fetchData}
        hasMore={hasMore}
        loader={!loading && <LoaderScroll />}
        // endMessage={<EndMsg />}
      >
        <div>
          <div className="card p-5">
            <div className="  mb-5">
              <div className="d-flex justify-content-between pl-3 pt-2">
                <h3>Contact Us</h3>
                <div className="col-5 ">
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    placeholder="Search Contact"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>

              <div className="pt-4">
                {/* //////////////////////////////////////////////// Component ////////////////////////////////////////////////*/}

                <div className=" ">
                  {items.length == 0 && (
                    <div className="p-10 d-flex justify-content-center my-10 font-weigth-bold">
                      <p>There are no records to display</p>
                    </div>
                  )}

                  {items.length !== 0 &&
                    items?.map((data, key) => {
                      return (
                        <div className="pb-2 pt-2 " key={key}>
                          <div className="card body p-4 shadow p-3 mb-5 bg-white rounded">
                            <div className="row row-cols-2 m-1 h3">
                              <strong className="pt-2">
                                Contact-Us : {++key}
                              </strong>
                              <div className="d-flex justify-content-end">
                                <div className="d-flex ">
                                  <div
                                    className="cursor-pointer mt-2"
                                    onClick={() => {
                                      setShow(true);
                                      setFaqId(data._id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </div>

                                  {/* <div className="cursor-pointer">
                                  {data.status === false && (
                                    <div className="cursor-pointer ml-5 ">
                                      <Button
                                        className="bg-primary"
                                        onClick={() => {
                                          handlealtermessage(data._id, true);
                                        }}
                                        style={{
                                          border: "none",
                                          height: "35px",
                                          // marginBottom: "10px",
                                          //  paddingLeft: "21px",
                                          //  paddingRight: "21px",
                                        }}
                                      >
                                        Active
                                      </Button>
                                    </div>
                                  )}

                                  {data.status === true && (
                                    <div className="cursor-pointer ml-5">
                                      <Button
                                        className="bg-danger"
                                        onClick={() => {
                                          handlealtermessage(data._id, false);
                                        }}
                                        style={{
                                          border: "none",
                                          height: "35px",
                                        }}
                                      >
                                        Deactive
                                      </Button>
                                    </div>
                                  )}
                                </div> */}
                                  {/* <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setOpenUpdate(true);
                                    handleForUpdateFaq(data);
                                  }}
                                >
                                  <CreateIcon />
                                </div> */}
                                </div>
                              </div>
                            </div>
                            <hr className="m-2" />
                            <div>
                              <div className="row row-cols-2 m-1">
                                <div className="col-4 p-0">
                                  <strong>Name :</strong>
                                </div>
                                <div className="col-8">{data?.name}</div>
                              </div>

                              <div className="row row-cols-2 m-1">
                                <div className="col-4 p-0">
                                  <strong>Email :</strong>
                                </div>
                                <div className="col-8">{data?.email}</div>
                              </div>

                              <div className="row row-cols-2 m-1">
                                <div className="col-4 p-0">
                                  <strong>Company :</strong>
                                </div>
                                <div className="col-8">{data?.company}</div>
                              </div>

                              <div className="row row-cols-2 m-1">
                                <div className="col-4 p-0">
                                  <strong>Phone number :</strong>
                                </div>
                                <div className="col-8">{data?.number}</div>
                              </div>

                              <div className="row row-cols-2 m-1">
                                <div className="col-4 p-0">
                                  <strong>Extension :</strong>
                                </div>
                                <div className="col-8">{data?.extension}</div>
                              </div>
                              {data.status == true ? (
                                <div className="row row-cols-2 m-1">
                                  <div className="col-4 p-0">
                                    <strong>Status :</strong>
                                  </div>
                                  <div className="col-8">active</div>
                                </div>
                              ) : (
                                <div className="row row-cols-2 m-1">
                                  <div className="col-4 p-0">
                                    <strong>Status :</strong>
                                  </div>
                                  <div className="col-8">Deactive</div>
                                </div>
                              )}
                              <div className="row row-cols-2 m-1">
                                <div className="col-4 p-0">
                                  <strong>Extension :</strong>
                                </div>
                                <div className="col-8">
                                  {moment(data?.createdAt)
                                    .utc()
                                    .local()
                                    .format("MMM Do YYYY, h:mm a")}
                                </div>
                              </div>
                              {/* <div className="row row-cols-2 m-1">
                              <div className="col-2">
                                <strong>Description</strong>
                              </div>
                              <div className="col-10">
                                {renderHTML(data?.description)
                                  ? renderHTML(data?.description)
                                  : ""}
                              </div>
                            </div> */}
                              <hr className="m-2" />
                              <div className="row row-cols-2 m-1">
                                <div className="col-4 p-0">
                                  <strong>Message :</strong>
                                </div>
                                <div className="col-8 justify-content-between">
                                  {data?.message}
                                </div>
                              </div>
                            </div>

                            <div>
                              {/* <div className={`${data.reply == true ? "" : ""}`}>
                              <div className="row pt-2 ">
                                <div className="col-5 ">
                                  <strong 
                                  style={{ marginLeft: "4px" }}
                                  >
                                    Message :
                                  </strong>
                                </div>
                                <div className="col-7 ">{data?.message}</div>
                               </div>
                            </div> */}
                              {data.reply == false ? (
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="btn btnColor"
                                    onClick={(e) => Replaydata(data)}
                                  >
                                    <i
                                      className="fas fa-reply"
                                      style={{ color: "white" }}
                                    ></i>{" "}
                                    Reply
                                  </button>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="btn  btnGreen"
                                    onClick={(e) => HandleOpenCreate(data)}
                                  >
                                    <i
                                      className="fas fa-reply"
                                      style={{ color: "white" }}
                                    ></i>{" "}
                                    View
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* //////////////////////////////////////////////// Component end ////////////////////////////////////////////////*/}
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to delete this query ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => handleDataDelete()}>
              Delete
              {loaderHas && (
                <span className="mx-3 spinner spinner-white"></span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={greatshow} onHide={greathandleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure want to {status == true ? "Active" : "Deactive"} this
            Contact ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={greathandleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => handelUpdateDetails()}>
              {status == true ? "Active" : "Deactive"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Dialog
          fullScreen
          open={openCreate}
          onClose={handleCloseCreate}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseCreate}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>

          <List>
            <div className="form ml-30 ">
              {/* Account Number */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Subject
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Subject"
                      className={`form-control form-control-lg form-control-solid `}
                      name="subject"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {eerrors["subject"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Message
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <textarea
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Message"
                      className={`form-control form-control-lg form-control-solid `}
                      name="message"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      style={{ height: "228px" }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {eerrors["message"]}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handlesubmit(e);
                  }}
                  className="btn btnColor mr-2"
                >
                  <span>Submit Details</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          </List>
        </Dialog>

        <Dialog
          fullScreen
          open={openUpdate}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleUpdateClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>

          <List>
            <div className="form ml-30 ">
              {/* Account Number */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Subject
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Subject"
                      className={`form-control form-control-lg form-control-solid `}
                      name="subject"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      disabled
                      value={statusupdate?.adminsubject}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {eerrors["subject"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Message
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <textarea
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Message"
                      className={`form-control form-control-lg form-control-solid `}
                      name="message"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      disabled
                      value={statusupdate?.adminmessage}
                      style={{ height: "228px" }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {eerrors["message"]}
                  </span>
                </div>
              </div>

              {/* <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handlesubmit(e);
                  }}
                  className="btn btnColor mr-2"
                >
                  <span>Submit Details</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              </div> */}
            </div>
          </List>
        </Dialog>
      </InfiniteScroll>
    </>
  );
};

export default FAQ;
