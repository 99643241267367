import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

// import EmailEditor from "../../../src";
import EmailEditor from "react-email-editor";
import sample from "./sample.json";
import leader from "./leader.json";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import { Router, useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import DataTable, { defaultThemes } from "react-data-table-component";
import Loader from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: lightgrey;
  color: #000;
  padding: 0 12px;
  display: flex;
  align-items: center;
  max-height: 70px;

  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
  }

  button {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    border: 0px;
    border-radius: 6px;
    max-width: 200px;
    cursor: pointer;
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Sendmail = (props) => {
  const emailEditorRef = useRef(null);
  const router = useHistory();

  const [html, setHtml] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const [Popbox, setPopbox] = React.useState(false);
  const [filteredReport, setFilteredReport] = useState();
  const [userReport, setUserReport] = useState();
  const [roleData, setRoleData] = useState();
  const [checkBox, setCheckBox] = useState([]);

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design) => {
      console.log("saveDesign", design);

      localStorage.setItem("design", JSON.stringify(design));
      toast.success("Design Saved Successfully");
    });
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml");
      // setHtml(html);
      //   alert("Output HTML has been logged in your developer console.");
    });
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
  };

  const onLoad = () => {
    console.log("onLoad");

    emailEditorRef.current.editor.addEventListener(
      "design:loaded",
      onDesignLoad
    );

    const DDD = JSON.parse(localStorage.getItem("design"));

    if (DDD) {
      emailEditorRef.current.editor.loadDesign(DDD);
    } else {
      emailEditorRef.current.editor.loadDesign(leader);
    }
  };

  const SendMail = async () => {
    // if (!formUpdateValidator()) {
    //   console.log("ERROR PUT", errors);
    //   return;
    // }

    if (checkBox.length === 0) {
      toast.error("Please select at least one user");
      return;
    }

    setLoader(true);

    exportHtml();

    let Data = {
      html: html,
      users: checkBox,
    };

    await ApiPost(`user/send-mail`, Data)
      .then((res) => {
        setLoader(false);
        //   window.location.reload();
        toast.success("Mail sent successfully");
        setPopbox(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err);
        console.log(err);
      });
  };

  const onReady = () => {
    console.log("onReady");
  };

  const HandleBox = (e) => {
    // console.log("HandleBox", e);
    setPopbox(true);
  };

  const handleProfileClose = () => {
    setPopbox(false);
    // setErrors({});
  };

  useEffect(() => {
    rolegetApi();
  }, []);

  const rolegetApi = async () => {
    setIsLoaderVisible(true);
    await ApiGet("user/all-users")
      .then((res) => {
        setRoleData(res.data.payload?.users);
        setFilteredReport(res.data.payload?.users);
        setUserReport(res.data.payload?.users);
        console.log("roleadd", res.data);
        setIsLoaderVisible(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoaderVisible(false);
      });
  };
  const handleSearch = (e) => {
    // console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setFilteredReport(() =>
      userReport.filter(
        (item) =>
          item?.name?.toLowerCase().includes(value) ||
          item?.email?.toLowerCase().includes(value)
        // item?.phone?.toLowerCase().includes(value)
      )
    );
  };

  let i = 0;
  const columns = [
    {
      name: (
        <input
          type="checkbox"
          checked={roleData?.length === checkBox?.length ? true : false}
          onChange={(e) => {
            Allselect(e);
          }}
        />
      ),
      cell: (row, index) => {
        return (
          <div>
            <input
              type="checkbox"
              checked={
                checkBox?.find((ss) => ss._id === row?._id) ? true : false
              }
              onChange={(e) => {
                handleCheckBox(row);
                // console.log("eeeee", e.target.checked);
              }}
            />
          </div>
        );
      },
      width: "5%",
    },

    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "5%",
    },

    {
      name: "Date",
      selector: "createdAt",
      cell: (row) => {
        // let d = new Date(row.createdAt);
        // let month = [
        //   "Jan",
        //   "Feb",
        //   "Mar",
        //   "Apr",
        //   "May",
        //   "Jun",
        //   "Jul",
        //   "Aug",
        //   "Sep",
        //   "Oct",
        //   "Nov",
        //   "Dec",
        // ];
        // let updatedate = ` ${
        //   month[d.getMonth()]
        // } ${d.getDate()},${d.getFullYear()} `;
        // return <div>{updatedate}</div>;
        return (
          <div>{moment(row.createdAt).utc().format("MMM Do YYYY, h:mm a")}</div>
        );
      },
      sortable: true,
    },
    {
      name: "Full Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",

      sortable: true,
    },

    {
      name: "Last Modified",
      cell: (row) => {
        return (
          <>
            <div>
              {moment(row.updatedAt)
                .utc()
                .local()
                .format("MMM Do YYYY, h:mm a")}
            </div>
          </>
        );
      },
      selector: (row) => row.lastLogin,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row?.status == false && "Deactive"}

            {row?.status == true && "Active"}
          </>
        );
      },
      selector: (row) => row.status,
      sortable: true,
    },

    // {
    //   name: "Mailbox",
    //   cell: (row) => {
    //     console.log("row@@@@@@", row?.isSubmit);
    //     return (
    //       <>
    //         {row?.isSubmit == "No" && "NO"}

    //         {row?.isSubmit == "Yes" && "YES"}

    //         {row?.isSubmit == false && "Mail Not send"}
    //       </>
    //     );
    //   },
    //   selector: (row) => row.isSubmit,
    //   sortable: true,
    // },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  // const handleCheckBox = (row) => {
  //   console.log("handleCheckBox", e.target.checked);
  //   setCheckBox(() => [...checkBox, row]);
  // };

  const handleCheckBox = (row) => {
    if (checkBox?.find((ss) => ss?._id === row?._id)) {
      setCheckBox((curVal) => curVal.filter((cv) => cv?._id !== row?._id));
    } else {
      setCheckBox((curVal) => [...curVal, row]);
    }
  };

  const Allselect = (e) => {
    e.preventDefault();
    if (checkBox?.length === roleData?.length) {
      setCheckBox([]);
    } else {
      setCheckBox(roleData);
    }
  };

  return (
    <>
      <ToastContainer />
      <Container>
        {/* <React.StrictMode> */}
        <div>
          <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
          ;
        </div>
        {/* </React.StrictMode> */}

        <Bar>
          <h1 style={{ display: "flex", alignItems: "center", margin: "0" }}>
            Mail Box
          </h1>

          <button onClick={saveDesign}>Save Design</button>
          {/* <button onClick={exportHtml}>Export HTML</button> */}
          <button
            // onClick={() => loader && SendMail()}
            onClick={(e) => HandleBox(e)}
          >
            <span>Mail Box</span>

            {/* {loader && <span className="mx-3 spinner spinner-white"></span>} */}
          </button>
        </Bar>
      </Container>

      <Dialog
        fullScreen
        open={Popbox}
        onClose={handleProfileClose}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleProfileClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          <div>
            <div className="card p-1">
              <div className="p-2 mb-2">
                <div className="row mb-4 pr-3">
                  <div className="col d-flex justify-content-between">
                    <h2 className="pl-3 pt-2">Total Users</h2>
                  </div>
                  <div className="col">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        name="title"
                        placeholder="Search user"
                        onChange={(e) => handleSearch(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={filteredReport}
                pagination
                customStyles={customStyles}
                style={{
                  marginTop: "-3rem",
                }}
                progressPending={isLoaderVisible}
                progressComponent={
                  <Loader type="Puff" color="#334D52" height={30} width={30} />
                }
              />
            </div>
          </div>
          <Container>
            {/* <React.StrictMode> */}

            {/* </React.StrictMode> */}

            <Bar>
              <h1
                style={{ display: "flex", alignItems: "center", margin: "0" }}
              >
                Send mail to users
              </h1>

              {/* <button onClick={saveDesign}>Save Design</button> */}
              {/* <button onClick={exportHtml}>Export HTML</button> */}
              <button
                onClick={() => !loader && SendMail()}
                // onClick={(e) => HandleBox(e)}
              >
                <span>Send mail to users</span>

                {loader && <span className="mx-3 spinner spinner-white"></span>}
              </button>
            </Bar>
          </Container>
        </List>
      </Dialog>
    </>
  );
};

export default Sendmail;
