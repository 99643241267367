import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import Logo from "../../../../_metronic/_assets/logo.png";
import ImgLogo from "./favicon.png";

export function Brand() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath
        .get
        // uiService.config
        // "aside.self.minimize.toggle"
        (),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  return (
    <div
      className={`brand flex-column-auto ${layoutProps.brandClasses}`}
      id="kt_brand"
    >
      <Link to="/dashboard" className="brand-logo">
        <img alt="logo" src={Logo} width="100%" />
      </Link>

      {layoutProps.asideSelfMinimizeToggle && (
        <>
          <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
            <span className="svg-icon svg-icon-xl">
              <img src={ImgLogo} height="30px" width="30px" />
            </span>
          </button>
        </>
      )}
    </div>
  );
}
