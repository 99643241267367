import React, { useCallback, useState } from "react";
import moment from "moment";
import "./profile.scss";
import DataTable, { defaultThemes } from "react-data-table-component";
import Loader from "react-loader-spinner";
import ImageViewer from "react-simple-image-viewer";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import zIndex from "@material-ui/core/styles/zIndex";
import axios from "axios";
import { ApiDelete } from "../../../helpers/API/ApiData";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
function Profile({ profile, rolegetApi, handleProfileClose }) {
  console.log("prodiledsvsdfv", profile);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [pid, setPid] = useState();
  const [image, setImages] = useState([]);
  let images = [];

  const handleShow = (id) => {
    setShow(true);
    setPid(id);
  };

  const openImageViewer = useCallback((row, index) => {
    // images = [row.document_image_front];

    setImages([row]);

    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const DeleteDoucment = async (e) => {
    e.preventDefault();
    setLoading(true);

    await ApiDelete(`user/verification-document/id=${pid}`)
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        handleClose();
        handleProfileClose();
        rolegetApi();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const handleClose = () => {
    setShow(false);
    setPid();
    // setErrors({});
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "5%",
    },

    {
      name: "Country",
      selector: "Country",
      sortable: true,
      width: "140px",
    },

    {
      name: "DocumentType",
      selector: "DocumentType",
      sortable: true,
      width: "140px",
    },

    {
      name: "user image",
      width: "20%",
      cell: (row, index) => {
        return (
          <>
            <img
              src={row.user_image}
              style={{ width: "60%", height: "60%", cursor: "pointer" }}
              className="img-fluid"
              onClick={() => openImageViewer(row.user_image, index)}
            />
          </>
        );
      },
    },

    {
      name: "document_image_front",
      width: "20%",

      cell: (row, index) => {
        return (
          <>
            <img
              src={row.document_image_front}
              className="img-fluid"
              onClick={() => openImageViewer(row.document_image_front, index)}
              style={{ width: "60%", height: "60%", cursor: "pointer" }}
            />

            {/* {isViewerOpen && (
              <ImageViewer
                src={[row.document_image_front]}
                currentIndex={0}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )} */}
          </>
        );
      },
    },

    {
      name: "document_image_back",
      width: "20%",
      cell: (row, index) => {
        return (
          <>
            <img
              src={row.document_image_back}
              className="img-fluid"
              onClick={() => openImageViewer(row.document_image_back, index)}
              style={{ width: "60%", height: "60%", cursor: "pointer" }}
            />

            {/* {isViewerOpen && (
              <ImageViewer
                src={[row.document_image_back]}
                currentIndex={0}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )} */}
          </>
        );
      },
    },
  ];
  return (
    <>
      {isViewerOpen && (
        <ImageViewer
          src={image}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          // className="img-fluid"
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          onImageLoad={(event) => {
            event.target.style.WebkitTransform = "scaleX(-1)";
            event.target.style.transform = "scaleX(-1)";
          }}
        />
      )}
      <div className="card shadow-lg p-3 mb-5 bg-white rounded">
        <div class="container emp-profile">
          <form method="post">
            <div class="row">
              <div class="col-md-4">
                <div className="profile-containerForUser">
                  <div className="profile-grid">
                    <div className="profile-grid-items">
                      <div className="profile-same-box-design">
                        <div className="profile-image-center-align">
                          <div className="profile-image-show">
                            {profile?.profileImage !== null &&
                            profile?.profileImage !== undefined ? (
                              <img src={profile?.profileImage} />
                            ) : (
                              <img
                                src={
                                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAMFBMVEXU1NT////Y2Nj7+/va2trm5ubz8/Pf39/29vbe3t7j4+P8/Pzt7e3Z2dn09PTp6enlgXfuAAAEj0lEQVR4nO2dCZarOgxEMVPCkGT/u31N8+mEEIIHVUmf47sC6ghNRhZFkclkMplMJpPJZDKZTCaTyWQymUwmk8lsKLuu75sf+r7rSu2niaNrxrZyK6p2bDrt5wqibtrB7TC0Ta39fH6Uj+ueiIXrw/5r1rdHKmbaXvtJv9JUxxL+PKbRfto9yhAZsxSTb1gfKONXir0XrPb0jXdaYyHssRtujxge2s/+wu0w4H7jetN+/oU+2hz/GcWIp4xpMiZGbQ0TkV6+ptVWUZR3CR3O3ZVTSpnk5q9cVZWUEUlwj0pRiZw9JhRtIuQfC3ctHSLx6hWl2PWQ1uGcSrlykdfh3IWvQzJgPVEIXeIOMkN3kwajwzlyA1wmFrz7DNyXS6Di3YNaCXc4Hc4xDyNFS5N3rjwdPVKHc7yGEWoQokkgOf0VVn4HG4RmEmjImuEELmAOWeDkEki1uKZi6ADH3hlGBAaVvWsYRTCsXHxlwOuAJ5EZfCoBdOqfwHfv8Gw4A8+JJUeHc+j+iuQieCeB9ervoHt3Qn0yg65SKOlwAp0SCYXWDLrcYulwDquDFn3R8bfmCcGORBC6wwVsl3gaIbTEjk7tlPZwBtsknsYip/GR0wg5TR45TYlynqKR1LLjm/bT9COk0yD8edBpDh9OcxzEClv4DwukYxT8px5S/Yv/QEJyEsJECiUlMr7rUg5NGcNOlHeLMutEqFI4c3SEuEUaq4HnRMpn9oLg7qy5RtxA4wxvrBFcy/PmsTHDywvMIWaol1Anf4F1CnE2s4Ae1JGv7sPaEvZNPpS/868r1JBkMijcQYaUXCqXXQFuonTVVTwGcyPvE2mH17tS2Yk6/KC4/KWTvOKqusSmFlNSKS9/kFKiraMobiJKKgN7HySuUOteZv8jOTOaWPkwcUl6vSqFC7p7lAmHdq2N12ohdjeKlZ0oT25RnjIaiFYbuuDwdbW6ke4S5CqtISff0Hi7ymB24VlR9mNQGK7G3lbA+qVsonaL3I1tb/PdBfgJO/sB67A3aks1qpe+P1xE1tXctSPYRW6bk6aUXnYJkpazyFnjT4qGVW6Qr9QtvfaKX8z4HfLaxph1n74Q14KmtFE+sFqttMbWB07zSxmhwx9H1KxLx+CqJXVtqT/YZp42vjwBDMS0i7ozKEeRXS/pA+YkVe4Lgj+IM3oNHQglOjrklWjpkFYi+a0wWIngcaSePX6ViNkEOzDnoUQoCvPzxztC+YR2P2wfkclscl3yGYFqhbbR5TvJZ/fEW8bfSQzC2gHrSWLoMuDoC0kOb8RBZhLcBDOAGUvC4KZ6JlwTPSlI7dB9iOzibb1YE5Evl6GItRAVuYi7XPyJOOyykwpfiUiLJmrFLcHVI/pCWCzBF8mMGiTYJFYNEmwSswYJNMnNrEF+TBLy4dewQYJMYtdDJgK8xFy1uMa/djSZ1J943xInLpqLw/frtcGyd41nEUzcVxqLn7sbd/UJP3c31ql/wqt7Jy7+i8en5zV1lrWHzxmX8E8OMXj8OvF/ELMmjuOWyTOHLcenEOaz4cxxTjRd+D7Z/KDkH+MbT03dnEr6AAAAAElFTkSuQmCC"
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="profile-head">
                  {profile?.name && (
                    <>
                      <h1>{profile?.name}</h1>
                      <p class="proile-rating">
                        <span> QuestionCounts : {profile?.questionCount}</span>
                        <span className="ml-3">
                          {" "}
                          AsnwerCounts : {profile?.answerCount}
                        </span>
                      </p>
                    </>
                  )}

                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Profile Details
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="profile-work">
                  <p style={{ fontSize: "14pt" }}>Top Subjects</p>

                  {profile?.subject?.length == 0 ||
                  profile?.subject == undefined ||
                  profile?.subject == null ? (
                    <span>No subjects</span>
                  ) : (
                    <>
                      <div>
                        {profile?.subject?.map((data) => (
                          <ul>
                            <li>
                              <b>{data}</b>
                            </li>
                          </ul>
                        ))}
                      </div>

                      {/* {} */}
                      {/* <p>
                      {profile?.subject && profile?.subject[0]},
                      {profile?.subject && profile?.subject[1]},
                      {profile?.subject && profile?.subject[2]},
                      {profile?.subject && profile?.subject[3]},
                    </p> */}
                    </>
                  )}
                  {/* [9].subject[0] */}
                </div>
              </div>
              <div class="col-md-8">
                <div class="tab-content profile-tab" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    {profile?.name && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>Username</label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.name}</p>
                        </div>
                      </div>
                    )}
                    {profile?.otherLink && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>Otherlink</label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.otherLink}</p>
                        </div>
                      </div>
                    )}
                    {profile?.email && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>Email</label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.email}</p>
                        </div>
                      </div>
                    )}
                    {profile?.organizationWebsite && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>OrganizationWebsite</label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.organizationWebsite}</p>
                        </div>
                      </div>
                    )}
                    {profile?.region && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>Region</label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.region}</p>
                        </div>
                      </div>
                    )}
                    {/* {profile?.currentRole && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>CurrentRole</label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.currentRole}</p>
                        </div>
                      </div>
                    )} */}

                    {profile?.createdAt && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>CreatedAt</label>
                        </div>
                        <div class="col-md-8">
                          <p>
                            :{" "}
                            {moment(profile?.createdAt).format("MMMM Do YYYY")}{" "}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* 
                    {profile?.countryOfOrigin &&
                      profile?.countryOfOrigin[0] && (
                        <div class="row">
                          <div class="col-md-4">
                            <label>countryOfOrigin </label>
                          </div>
                          <div class="col-md-8">
                            <p>: {profile?.countryOfOrigin[0]}</p>
                          </div>
                        </div>
                      )}

                    {profile?.countryOfResidence && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>countryOfResidence </label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.countryOfResidence}</p>
                        </div>
                      </div>
                    )}

                    {profile?.employeeNumber && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>EmployeeNumber </label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.employeeNumber}</p>
                        </div>
                      </div>
                    )}

                    {profile?.gender && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>Gender </label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.gender}</p>
                        </div>
                      </div>
                    )} */}

                    {profile?.lastLogin && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>LastModified </label>
                        </div>
                        <div class="col-md-8">
                          <p>
                            :{" "}
                            {moment(profile?.lastLogin).format("MMMM Do YYYY")}
                          </p>
                        </div>
                      </div>
                    )}
                    {profile?.organizationEmail && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>OrganizationEmail </label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.organizationEmail}</p>
                        </div>
                      </div>
                    )}

                    {profile?.organizationName && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>organizationName </label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.organizationName}</p>
                        </div>
                      </div>
                    )}
                    {profile?.linkedinProfile && (
                      <div class="row">
                        <div class="col-md-4">
                          <label>LinkedIn profile </label>
                        </div>
                        <div class="col-md-8">
                          <p>: {profile?.linkedinProfile}</p>
                        </div>
                      </div>
                    )}

                    {profile?.filter?.map((item) => {
                      return (
                        <div class="row">
                          <div class="col-md-4">
                            <label>{item.name}</label>
                          </div>
                          <div class="col-md-8">
                            <p>
                              {item.options
                                .map((item) => item.optionName)
                                .join(" ,")}{" "}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {(profile.message || profile.text) && (
              <div className="deactivate-reasonbox">
                {profile.message && (
                  <div className="d-flex">
                    <div
                      className=""
                      style={{ minWidth: "300px", maxWidth: "300px" }}
                    >
                      <b>Reason of Deactivate Account : </b>
                    </div>
                    <div>{profile.message}</div>
                  </div>
                )}
                {profile.text && (
                  <div className="d-flex">
                    <div
                      className=""
                      style={{ minWidth: "300px", maxWidth: "300px" }}
                    >
                      <b>Improvement suggestions from users : </b>
                    </div>
                    <div>{profile.text}</div>
                  </div>
                )}

                {/* {profile.message && <h1>Deactive-Reason -: {profile.message}</h1>}
  {profile.text && (
    <h1>Improvement suggestions from users :{profile.text}</h1>
  )} */}
              </div>
            )}
          </form>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>User Document</h1>

          <div
            className="cursor-pointer ml-5 mt-1"
            onClick={() => handleShow(profile?.verification[0]?._id)}
          >
            <ReactTooltip id="checkUser" place="top" effect="solid">
              Delete document from user
            </ReactTooltip>
            <button
              className="btn-danger btn"
              data-tip
              data-for="checkUser"
              style={{
                border: "none",
                display: "flex",
                whiteSpace: "nowrap",
              }}
            >
              Delete
            </button>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={profile?.verification}
          // pagination
          customStyles={customStyles}
          style={{
            marginTop: "-3rem",
          }}
          // progressPending={isLoaderVisible}
          progressComponent={
            <Loader type="Puff" color="#334D52" height={30} width={30} />
          }
        />
      </div>

      <Modal show={show} onHide={handleClose} style={{ zIndex: "99999999" }}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete this Document?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="danger" onClick={(e) => DeleteDoucment(e)}>
            {!loading ? "Delete" : "Delete"}
            {loading && <span className="mx-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Profile;
