import React from "react";

function EndMessag() {
  return (
    <>
      <p style={{ textAlign: "center" }}>
        <b>Yay! You have seen it all</b>
      </p>
    </>
  );
}

export default EndMessag;
