import React, { useEffect, useState } from 'react';
import DataTable, { defaultThemes } from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { Container, Draggable } from "react-smooth-dnd";

import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

import { DragContent } from '../Common/DragContent';

import {
    ApiDelete,
    ApiGet,
    ApiPost,
    ApiPut,
} from "../../../helpers/API/ApiData";

import "./FrequentlyAskedQuestions.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const FaqPositionInput = ({
    row,
    setFaqsEdit
}) => {
    return (
        <>
            <input 
                className="frequently-asked-questions-position-input" 
                value={row?.position}
                autoFocus={row?.focus}
                onChange={(e) => {
                    const value = e.target.value;
                    setFaqsEdit(faqsEdit => {
                        return faqsEdit?.map(faq => {
                            if (faq?._id === row?._id) {
                                return {
                                    ...faq,
                                    position: value,
                                    focus: true
                                }
                            } else {
                                return {
                                    ...faq,
                                    focus: false
                                }
                            }
                        })
                    })
                }}
            >
            </input>
        </>
    )
}

const FrequentlyAskedQuestions = ({

}) => {

    // Styles
    const customStyles = {
        header: {
          style: {
            minHeight: "56px",
          },
        },
        headRow: {
          style: {
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: defaultThemes.default.divider.default,
          },
        },
        headCells: {
          style: {
            "&:not(:last-of-type)": {
              borderRightStyle: "solid",
              borderRightWidth: "1px",
              borderRightColor: defaultThemes.default.divider.default,
            },
          },
        },
        cells: {
          style: {
            "&:not(:last-of-type)": {
              borderRightStyle: "solid",
              borderRightWidth: "1px",
              borderRightColor: defaultThemes.default.divider.default,
            },
          },
        },
    };
    // Styles


    // Local state
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [positionsEditing, setPositionsEditing] = useState(false);
    // const [submitValue, setSubmitValue] = useState(false);
    const [faqs, setFaqs] = useState([]);
    const [faqsEdit, setFaqsEdit] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filteredFaqs, setFilteredFaqs] = useState([]);

    // Form data
    const [formState, setFormState] = useState({
        question: ``,
        answer: ``,
        position: ``,
        _id: ``,
        state: ``
    });
    // Form data
    // Local state

    const columns = [
        // {
        //   name: "S.No.",
        //   cell: (row, index) => index + 1,
        //   width: "80px",
        // },
        {
          name: "Question",
          selector: "Question",
          sortable: true,
          cell: (row) => {
            return (
                <>
                    <p>{row.question}</p>
                </>
            )
          }
        },
        {
          name: "Answer",
          selector: "Answer",
          wrap: true,
          sortable: true,
          cell: (row) => {
            return (
                <>
                    <p>{row.answer}</p>
                </>
            )
          }
        },   
        {
            name: "Position",
            selector: "Position",
            wrap: true,
            sortable: true,
            cell: (row) => {
              return (
                  <>
                    {positionsEditing ? 
                    <FaqPositionInput 
                        row={row}
                        setFaqsEdit={setFaqsEdit}
                    />
                     : 
                    <p>
                        {row?.position}
                    </p>} 
                  </>
              )
            }
        },    
        // {
        //   name: "Image",
        //   cell: (row) => {
        //     return (
        //       <>
        //         <div className="p-3">
        //           <img
        //             className="max-w-90px"
        //             src={row.legendsIcon}
        //             alt={row.title}
        //           />
        //         </div>
        //       </>
        //     );
        //   },
        //   wrap: true,
        // },
    
        // {
        //   name: "Update date",
        //   cell: (row) => {
        //     return <>{getNewDate(row.updatedAt)}</>;
        //   },
        //   sortable: true,
        // },
        {
          name: "Actions",
          cell: (row) => {
            return (
              <>
                <div className="d-flex justify-content-between">
                  <div
                    className="cursor-pointer mr-3"
                    onClick={() => {
                        handleDeleteFAQ(row._id);
                    }}
                  >
                    <DeleteIcon />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                        setOpen(true);
                        setFormState(formState => {
                            return {
                                ...formState,
                                question: row?.question,
                                answer: row?.answer,
                                position: row?.position,
                                _id: row?._id,
                                state: `editing`
                            }
                        });
                    //   setUpdateProduct(true);
    
                    //   handleForUpdateProduct(row._id);
                    //   setTitle(row.legendsName);
                    //   setDesc(row.legendsDescription);
                    //   setImage(row.legendsIcon);
                    }}
                  >
                    <CreateIcon />
                  </div>
                </div>
              </>
            );
          },
          sortable: true,
        },
    ];


    // Handlers

    const drop = (dropResult, from) => {
        // console.log("AAAAAAAAAAAAQ", graphWeight);
    
        // event.preventDefault();
        // const { removedIndex, addedIndex, payload } = dropResult;
    
        // console.log('DRAGGGGGGGED', removedIndex, addedIndex, from);
    
        // if (removedIndex !== null && addedIndex !== null) {
        //   // Update the state with the new order
        //   const newItems = [...allSubjectList];
        //   const draggedItem = newItems[removedIndex];
        //   newItems.splice(removedIndex, 1);
        //   newItems.splice(addedIndex, 0, draggedItem);
        //   // setAllSubjectList(newItems);
        //   const FinalValue = newItems.map((item, index) => {
        //     let Data = newItems?.length - index;
        //     return {
        //       ...item,
        //       weight: Data,
        //     };
        //   });
    
        //   const DataFind = {
        //     ...allFilter,
        //   };
        //   DataFind.options = FinalValue;
        //   setAllFilter(DataFind);
        //   setAllSubjectList(FinalValue);
    
        //   const updatedArray2 = dropSaveGraph.map((obj2) => {
        //     const matchingObj1 = FinalValue.find((obj1) => obj1._id === obj2._id);
        //     return matchingObj1 ? { ...obj2, weight: matchingObj1.weight } : obj2;
        //   });
    
        //   setDropSaveGraph(updatedArray2);
        //   setChangeValue(false);
        // }
    };


    const handleSearch = (e) => {
        console.log("eeeee", e.target.value);
        const value = e.target.value.toLowerCase();
        if (value) {
            const filteredFaqsTemp = [...faqs];
            const newFilteredFaqs = filteredFaqsTemp?.filter(faq => {
                return (faq?.question?.toLowerCase()?.includes(value));
            });
            setFilteredFaqs(newFilteredFaqs);
            setIsFiltered(true);
        } else {
            setFilteredFaqs([]);
            setIsFiltered(false);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
        setFormState(formState => {
            return {
                ...formState,
                question: ``,
                answer: ``,
                position: ``,
                _id: ``,
                state: `creating`
            }
        })
        // setSubmitValue(false);
        // setUpdateProduct(true);
        // setImage("");
        // setDesc("");
        // setTitle("");
    };

    const handleClose1 = () => {
        setOpen(false);
    };


    const updateFormField = (field, value) => {
        setFormState(formState => {
            return {
                ...formState,
                [field]: value
            }
        })
    }

    const dragStart = (event, index) => {
        event.dataTransfer.setData("text/plain", index);
    };

    const dragOver = (event) => {
        event.preventDefault();
    };


    const updateFaqPositionsHandler = () => {
        const positionsSeenSoFar = [];

        for (let i = 0; i < faqsEdit?.length; i++) {
            if (positionsSeenSoFar.includes(faqsEdit[i]?.position)) {
                toast.error(`You have duplicate position values`, { theme: "colored" });
                return;
            } else {
                positionsSeenSoFar.push(faqsEdit[i]?.position);
            }
        }
        
        const data = faqsEdit?.map(faq => {
            return {
                _id: faq?._id,
                position: Number(faq?.position)
            }
        })

        setLoading(true);

        ApiPut("frequently-asked-questions", {
            faqs: data
        })
        .then(res => {
            if (res?.data?.result) {
                const faqsEditTemp = [...faqsEdit]?.sort(function(a, b) {
                    if (a?.position <= b?.position) return -1;
                    if (a?.position > b?.position) return 1;
                    return 0;
                });
                
                setFaqs(faqsEditTemp);
                toast.success("FAQ positions updated successfully", { theme: "colored" });
            }
        })
        .catch(err => {
            toast.error(err?.message ? err?.message : err, { theme: "colored" });
        })
        .finally(() => {
            setLoading(false);
            setPositionsEditing(false);
        })
    }


    const updateFaqsHandler = (res) => {
        const newFaq = res?.data?.payload;
        let indexOfNewFaq = -1;

        for (let i = 0; i < faqs?.length; i++) {
            if (newFaq?.position <= faqs[i]?.position) {
                indexOfNewFaq = i;
                break;
            }
        }

        if (indexOfNewFaq === -1) indexOfNewFaq = faqs?.length;
        const newFaqsTemp = [...faqs];
        newFaqsTemp.splice(indexOfNewFaq, 0, newFaq);
        setFaqs(newFaqsTemp); 
    }


    // API requests
    const fetchFAQs = () => {
        ApiGet("frequently-asked-questions/get-all-faq-admin")
        .then(res => {
            console.log('FETCHREQ', res);
            setFaqs(res?.data?.payload?.faqs);
        })
        .catch(err => {
            console.log(err);
            toast.error(err?.message ? err?.message : err, { theme: "colored" });
        })
    }



    const submitFAQ = async () => {
        // if (!formValidator()) {
        //   console.log("ERROR PUT", errors);
        //   return;
        // }
        // setLoading(true);
        // // const formData = new FormData();
    
        // // formData.append("legendsName", JSON.stringify(title));
        // // formData.append("legendsIcon", JSON.stringify(image));
        // // formData.append("legendsDescription", JSON.stringify(desc));
    
        const data = {
          question: formState?.question,
          answer: formState?.answer,
          position: Number(formState?.position)
        };
    
        ApiPost("frequently-asked-questions/add-faq", data)
        .then((res) => {

            console.log('RESPONSE', res);
            // setUpdateProduct(false);

            updateFaqsHandler(res);

            // setLoading(false);
            // //console.log(res);
            // setSize();
            // setPrice();
            // setType();
            // setTitle();
            // setImage();
            toast.success("FAQ added successfully", { theme: "colored" });
        })
        .catch((err) => {
            toast.error(err?.message ? err?.message : err, { theme: "colored" });
            console.log('ERROR', err);
        })
        .finally(() => {
            setFormState(formState => {
                return {
                    ...formState,
                    question: ``,
                    answer: ``,
                    position: ``,
                    _id: ``
                }
            })
            setLoading(false);
            setOpen(false);
        });
    };


    const handleUpdateFAQ = async () => {
        setLoading(true);
        // const formData = new FormData();
        // formData.append("legendsName", title);
        // formData.append("legendsIcon", image);
        // formData.append("legendsDescription", desc);
    
        const data = {
          question: formState.question,
          answer: formState.answer
        };
    
        ApiPut(`frequently-asked-questions/id=${formState?._id}`, data)
        .then((res) => {

            console.log('RESULLLLLLT', res);

            if (res?.data?.result) {
                // updateFaqsHandler(res);
                setFaqs(faqs => {
                    return faqs?.map(faq => {
                        if (faq?._id === res?.data?.payload?._id) {
                            return {
                                ...res?.data?.payload
                            }
                        } else return faq;
                    })
                })

                toast.success("FAQ updated successfully!", { theme: "colored" });
            }
            // setUpdateProduct(false);
            // setDataChange([...dataChange, "Music Updated"]);
            // setLoading(false);

            // //console.log(res);
            // setTitle();
            // setDesc();
            // setPrice();
            // setSize1();
            // setType();
        })
        .catch((err) => {
            console.log('ERRORRRRR', err);
            toast.error(err?.message ? err?.message : err, { theme: "colored" });
            // console.log(err);
        })
        .finally(() => {
            setFormState(formState => {
                return {
                    ...formState,
                    question: ``,
                    answer: ``,
                    position: ``,
                    _id: ``
                }
            })
            setOpen(false);
            setLoading(false);
        });
    };


    const handleDeleteFAQ = async (id) => {
        ApiDelete(`frequently-asked-questions/id=${id}`)
        .then(res => {
            console.log('DELETERESPONSE', res);

            if (res?.data?.result) {
                setFaqs(faqs => {
                    return faqs?.filter(faq => {
                        return (faq?._id !== res?.data?.payload?._id);
                    })
                });

                toast.success("FAQ deleted successfully!", { theme: "colored" });
            }
        })
        .catch(err => {
            toast.error(err?.message ? err?.message : err, { theme: "colored" });
        })
    }
    // API requests 

    // Handlers

    // Effects
    useEffect(() => {
        fetchFAQs();
    }, []);
    // Effects

    return (
        <>
            <ToastContainer />
            <div>
                <div className="card p-1">
                <div className="p-2">
                <div className="row mb-4 pr-3">
                    <div className="col d-flex justify-content-between">
                    <h2 className="pl-3 pt-2">All FAQs</h2>
                    </div>
                    <div className="col">
                    <div>
                    <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        name="title"
                        placeholder="Search FAQs"
                        onChange={(e) => handleSearch(e)}
                    />
                    </div>
                    </div>
                    <div className="cus-medium-button-style button-height">
                    <button
                        className="btn btnColor"
                        onClick={() => {
                            handleClickOpen();
                        }}
                    >
                        Add FAQ
                    </button>
                    </div>
                </div>
                </div>

                {!positionsEditing ? <button style={{
                    alignSelf: 'flex-end'
                }} 
                onClick={() => {
                    setPositionsEditing(true);
                    setFaqsEdit([...faqs])
                }}
                className="btn btnColor"
                >Edit Positions</button> : null}

                <DataTable
                    columns={columns}
                    data={!isFiltered ? (positionsEditing ? faqsEdit : faqs) : filteredFaqs}
                    customStyles={customStyles}
                    // pagination
                    style={{
                        marginTop: "-3rem",
                    }}
                />

                {positionsEditing ? <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                    alignSelf: 'flex-end'
                }}>
                    <button onClick={() => {
                        setPositionsEditing(false)
                        setFaqsEdit([]);
                    }}
                    className="btn btnColor-reverse"
                    >Cancel</button>
                    <button onClick={() => {
                        updateFaqPositionsHandler();
                    }}
                    className="btn btnColor"
                    >
                        <Loader
                            type="Oval"
                            color="#F6FBFF"
                            height={25}
                            width={25}
                            visible={loading}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "0px 7px",
                            }}
                        />
                        {!loading ? <span>
                            Save
                        </span> : null} 
                    </button>
                </div> : null}

                </div>
            </div>




            <Dialog
                fullScreen
                open={open}
                onClose={handleClose1}
                TransitionComponent={Transition}
            >
                <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose1}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                </Toolbar>

                <List>
                {open ? (
                    <div className="form ml-30 ">
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                                Question
                            </label>
                            <div className="col-lg-9 col-xl-6">
                            <div>
                                <input
                                    type="text"
                                    className={`form-control form-control-lg form-control-solid `}
                                    name="question"
                                    value={formState.question}
                                    onChange={(e) => {
                                        updateFormField(`question`, e.target.value)
                                        // setTitle(e.target.value);
                                        // setErrors({ ...errors, title: "" });
                                    }}
                                />
                            </div>
                            <span
                                style={{
                                color: "red",
                                top: "5px",
                                fontSize: "12px",
                                }}
                            >
                                {/* {errors["title"]} */}
                            </span>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                                Answer{" "}
                            </label>
                            <div className="col-lg-9 col-xl-6">
                            <div>
                                <input
                                    type="text"
                                    className={`form-control form-control-lg form-control-solid `}
                                    name="answer"
                                    value={formState.answer}
                                    onChange={(e) => {
                                        updateFormField(`answer`, e.target.value)
                                        // setImage(e.target.value);
                                        // setErrors({ ...errors, image: "" });
                                    }}
                                />
                            </div>
                            <span
                                style={{
                                color: "red",
                                top: "5px",
                                fontSize: "12px",
                                }}
                            >
                                {/* {errors["image"]} */}
                            </span>
                            </div>
                        </div>

                        {formState?.state !== `editing` ? <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                                Position{" "}
                            </label>
                            <div className="col-lg-9 col-xl-6">
                            <div>
                                <input
                                    type="text"
                                    className={`form-control form-control-lg form-control-solid `}
                                    name="position"
                                    value={formState.position}
                                    onChange={(e) => {
                                        updateFormField(`position`, e.target.value)
                                        // setImage(e.target.value);
                                        // setErrors({ ...errors, image: "" });
                                    }}
                                />
                            </div>
                            <span
                                style={{
                                color: "red",
                                top: "5px",
                                fontSize: "12px",
                                }}
                            >
                                {/* {errors["image"]} */}
                            </span>
                            </div>
                        </div> : null}

                        {open ? <div className="d-flex align-items-center justify-content-center">
                            {formState?.state === `editing` ? (
                            <button
                                onClick={(e) => {
                                    handleUpdateFAQ();
                                }}
                                className="btn btn-success mr-2"
                            >
                                <span>Update FAQ</span>
                                {loading && (
                                <span className="mx-3 spinner spinner-white"></span>
                                )}
                            </button>
                            ) : (
                            <button
                                onClick={(e) => {
                                    submitFAQ(e);
                                }}
                                className="btn btnColor mr-2"
                            >
                                <span>Submit</span>
                                {loading && (
                                <span className="mx-3 spinner spinner-white"></span>
                                )}
                            </button>
                            )}
                        </div> : null}
                    </div>
                ) : null}
                </List>
            </Dialog>
        </>
    )
}


export default FrequentlyAskedQuestions;