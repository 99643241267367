/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
// import { toAbsoluteUrl, checkIsActive } from "../../../../";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { getUserInfo } from "../../../../../utils/user.util";

import { ApiGet } from "../../../../../helpers/API/ApiData";
import { toast } from "react-toastify";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const [RolefilterData, setRolefilterData] = useState([]);

  const userInfo = getUserInfo();

  console.log("userInfo", userInfo);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  useEffect(() => {
    getRoleList();
  }, []);

  const getRoleList = () => {
    ApiGet("graph/get-graph")
      .then((res) => {
        setRolefilterData(res?.data?.payload);
        console.log("ROLE", res?.data?.payload);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      {userInfo?.user?.userType == "Admin" ||
      userInfo?.user?.userType == "admin" ? (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Dashboard*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/dashboard",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/dashboard.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/user", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/user">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/user.svg")} />
              </span>
              <span className="menu-text">User management</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/organization", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/organization">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/organization.svg")}
                />
              </span>
              <span className="menu-text">Organization</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/Company", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Company">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/company.svg")} />
              </span>
              <span className="menu-text">Company</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive(
              "/faq",
              false
            )} ${getMenuItemActive("/messages", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/faq">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/question.svg")}
                />
              </span>
              <span className="menu-text">Question details</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/contact", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/contact">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Contact.svg")} />
              </span>
              <span className="menu-text">Contact form data</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/cms", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/cms">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">C.M.S</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/Content", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Content">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/note.svg")} />
              </span>
              <span className="menu-text">Release Note</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/legends", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/legends">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">Legends</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive(
              "/abuse/1",
              false
            )} ${getMenuItemActive("/abuseuser", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/abuse/1">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Home/abuse-question.svg"
                  )}
                />
              </span>
              <span className="menu-text">Reported questions</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive(
              "/abuseanswer/1",
              false
            )} ${getMenuItemActive("/abuseanswer/1", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/abuseanswer/1">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/abuse-answer.svg")}
                />
              </span>
              <span className="menu-text">Reported Answers</span>
            </NavLink>
          </li>

          {/* <li
            className={`menu-item ${getMenuItemActive(
              "/sendmail",
              false
            )} ${getMenuItemActive("/sendmail", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/sendmail">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">Send mail</span>
            </NavLink>
          </li> */}

          <li
            className={`menu-item ${getMenuItemActive("/payment", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/payment">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/pricing.svg")} />
              </span>
              <span className="menu-text">Payment</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive(
              "/frequently-asked-questions",
              false
            )} ${getMenuItemActive("/frequently-asked-questions", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/frequently-asked-questions">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">FAQs</span>
            </NavLink>
          </li>

          {/* <li
            className={`menu-item ${getMenuItemActive(
              "/Verification-status",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Verification-status">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/pricing.svg")} />
              </span>
              <span className="menu-text">Verification-status</span>
            </NavLink>
          </li> */}

          <li
            className={`menu-item ${getMenuItemActive("/Filters", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Filters">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/pricing.svg")} />
              </span>
              <span className="menu-text">Filters</span>
            </NavLink>
          </li>

          {/* <li
            className={`menu-item ${getMenuItemActive("/DropDown", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/DropDown">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">DropDown</span>
            </NavLink>
          </li> */}
          {/* <li
            className={`menu-item ${getMenuItemActive("/Experience", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Experience">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">Experience</span>
            </NavLink>
          </li> */}

          {/* <li
            className={`menu-item ${getMenuItemActive(`/allfilterdata`, false)}`}
            aria-haspopup="true"
            >
            <NavLink className="menu-link" to={`/allfilterdata`}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">master setting</span>
            </NavLink>
          </li> */}

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/reports",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/reports"
              onClick={() => getRoleList()}
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/home/master-setting.svg"
                  )}
                />
              </span>
              <span className="menu-text">Master setting</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                {/* Inputs */}
                {/*begin::2 Level*/}
                {RolefilterData.map((item) => {
                  return (
                    <>
                      {item.name === "Site Setting" ? (
                        <li
                          className={`menu-item menu-item-submenu  ${getMenuItemActive(
                            `/sitesetting/${item._id}`,
                            false
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to={`/sitesetting/${item._id}`}
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">{item.name}</span>
                          </NavLink>
                        </li>
                      ) : (
                        <li
                          className={`menu-item menu-item-submenu  ${getMenuItemActive(
                            `/allfilterdata/${item._id}`,
                            false
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to={`/allfilterdata/${item._id}`}
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">{item.name}</span>
                          </NavLink>
                        </li>
                      )}
                    </>
                  );
                })}

                {/*end::1 Engage*/}

                {/* Inputs */}
                {/*begin::2 Level*/}
              </ul>
            </div>
          </li>
        </ul>
      ) : (
        ""
      )}
    </>
  );
}
