import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";

const NEW_CHAT_MESSAGE_EVENT = "new-message";
const SOCKET_SERVER_URL = "https://api.leaderbridge.rejoicehub.com/";

const useChat = (roomId) => {
  const [messages, setMessages] = useState([]);
  const socketRef = useRef();
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));

  console.log("userData1234", userInfo);
  useEffect(() => {
    console.log("roomIdIn", roomId);
    socketRef.current = socketIOClient(SOCKET_SERVER_URL);
    console.log("SOCKET_SERVER_URL", SOCKET_SERVER_URL);

    socketRef.current.emit("connection", { roomId: roomId });
    socketRef.current.emit("join", { roomId: roomId });
    socketRef.current.on("history", function (event) {
      console.log("allChats", event);
      setMessages((messages) => [...event.chats]);
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);

  const sendMessage = (messageBody) => {
    socketRef.current.on(NEW_CHAT_MESSAGE_EVENT, function (event) {
      console.log("event", event);
      messages.push(event);
      setMessages([...messages]);
    });
    socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, {
      roomId: roomId,
      message: messageBody,
      sender: userInfo && userInfo?.user && userInfo?.user?._id,
      type: "string",
    });
  };

  return {
    messages,
    sendMessage,
  };
};

export default useChat;
