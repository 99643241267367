import React, { useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DataTable, { defaultThemes } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import { Modal } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import moment from "moment";
import Profile from "./Profile";
import ReactTooltip from "react-tooltip";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const organigationRoleList = [
  "Assistant Director",
  "Assistant Vice President",
  "Associate Vice President",
  "Chief Executive Officer",
  "Chief Financial Officer",
  "Chief Operating Officer",
  "Co-Founder",
  "Director",
  "Executive Vice President",
  "Founder",
  "Manager",
  "Partner",
  "President",
  "Product Manager",
  "Senior Director",
  "Senior Vice President",
  "Vice President",
  "Other",
];

const OrganizationUser = (props) => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [pid, setPid] = useState();
  const [updateProduct, setUpdateProduct] = useState(false);
  const [submitValue, setSubmitValue] = useState(false);
  const [initData, setInitdata] = useState();
  const [pwdErr, setPwdErr] = useState("");
  const [status, setstatus] = useState();
  const [statusid, setstatusid] = useState();
  const [data, setData] = useState({});

  const [roleName, setRoleName] = useState();
  const [description, setDescription] = useState();
  const [active, setactive] = useState(true);

  const [roleNameUpdate, setRoleNameUpdate] = useState();
  const [roleUpdate, setRoleupdate] = useState();
  const [descriptionUpdate, setDescriptionUpdate] = useState();
  const [msg, setMsg] = useState("");

  const [updateDetails, setUpdateDetails] = useState(false);

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [Persondata, setPersondata] = useState();

  // const [errors] = useState([]);
  const [userReport, setUserReport] = useState();
  const [filteredReport, setFilteredReport] = useState();
  const [UserEmail, setUserEmail] = useState();
  const [errors, setErrors] = useState({});
  let userInfo = getUserInfo();
  // console.log(userInfo.user.id);
  const [show, setShow] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setSubmitValue(false);
    setUpdateProduct(true);
  };
  useEffect(() => {
    console.log(props.profile);
  });
  const handleClose1 = () => {
    setOpen(false);
    setErrors({});
  };

  const [profile, setProfile] = useState(false);

  const handleProfileClose = () => {
    setProfile(false);
    setErrors({});
  };

  const handleClose = () => {
    setShow(false);
    setErrors({});
  };

  const handleShow = (id) => {
    setShow(true);
    setPid(id);
  };

  useEffect(() => {
    rolegetApi();
  }, [dataChange]);

  const rolegetApi = async () => {
    setIsLoaderVisible(true);
    await ApiGet(
      `user/all-users-organization?organizationId=${props?.profile?.OrgRanDomID}`
    )
      .then((res) => {
        setRoleData(res.data.payload?.users);
        setFilteredReport(res.data.payload?.users);
        setUserReport(res.data.payload?.users);
        console.log("roleadd", res.data);
        setIsLoaderVisible(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoaderVisible(false);
      });
  };

  const handleDeleteManagerInfo = async () => {
    await ApiDelete(`role/id=${pid}`)
      .then((res) => {
        setDataChange([...dataChange, "Payment delete"]);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const handleForUpdateManager = async (id) => {
    setOpen(true);
    setPid(id);
    setSubmitValue(true);
    let temp = roleData.filter((data) => {
      return data._id === id;
    });
    setRoleNameUpdate(temp[0].roleName);
    setDescriptionUpdate(temp[0].description);
  };

  const handelUpdateDetails = async () => {
    if (!formUpdateValidator()) {
      console.log("ERROR PUT", errors);
      return;
    }

    setLoading(true);

    let data = {
      name: roleNameUpdate,
      phone: descriptionUpdate,
      email: UserEmail,
      currentRole: roleUpdate,
    };

    await ApiPut(`user`, data)
      .then((res) => {
        console.log("dd", res.data.payload.updateByAdmin);
        setData(res.data.payload.updateByAdmin);
        setOpen(false);
        setErrors({});
        setUpdateProduct(false);
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        toast.success("User updated successfully");

        // toast.success("Role Updated Successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handlealtermessage = (id, status) => {
    setShow(true);
    setstatus(status);
    setstatusid(id);
  };

  const [profileData, setProfileData] = useState();

  const ProfileFuntions = (data) => {
    console.log("DataLeader-Bridge", data?.message);
    if (data && data) {
      setProfileData(data);
      setProfile(true);
    }
  };

  const handelUpdatestatusDetails = async () => {
    setLoading(true);
    await ApiPut(`user/update-status/id=${statusid}&status=${status}`)
      .then((res) => {
        setOpen(false);
        setErrors({});
        setUpdateProduct(false);
        setDataChange([...dataChange, "Role Updated"]);
        setLoading(false);
        setShow(false);
        console.log(res.data.payload.updateUser.status, "payloaduser");
        toast.success(
          `${
            res.data.payload.updateUser.status == true ? "Active" : "Deactive"
          }d`
        );

        // toast.success("Role Updated Successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);

        console.log(err);
      });
  };

  let i = 0;
  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
      width: "5%",
    },
    {
      name: "Date",
      selector: "createdAt",
      cell: (row) => {
        // let d = new Date(row.createdAt);
        // let month = [
        //   "Jan",
        //   "Feb",
        //   "Mar",
        //   "Apr",
        //   "May",
        //   "Jun",
        //   "Jul",
        //   "Aug",
        //   "Sep",
        //   "Oct",
        //   "Nov",
        //   "Dec",
        // ];
        // let updatedate = ` ${
        //   month[d.getMonth()]
        // } ${d.getDate()},${d.getFullYear()} `;
        // return <div>{updatedate}</div>;
        return (
          <div>{moment(row.createdAt).utc().format("MMM Do YYYY, h:mm a")}</div>
        );
      },
      sortable: true,
    },
    {
      name: "Full Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Role",
      selector: "currentRole",
      sortable: true,
    },
    {
      name: "Last Modified",
      cell: (row) => {
        return (
          <>
            <div>
              {moment(row.updatedAt)
                .utc()
                .local()
                .format("MMM Do YYYY, h:mm a")}
            </div>
          </>
        );
      },
      selector: (row) => row.lastLogin,
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row?.status == false && "Deactive"}

            {row?.status == true && "Active"}
          </>
        );
      },
      selector: (row) => row.status,
      sortable: true,
    },

    // {
    //   name: "Mailbox",
    //   cell: (row) => {
    //     console.log("row@@@@@@", row?.isSubmit);
    //     return (
    //       <>
    //         {row?.isSubmit == "No" && "NO"}

    //         {row?.isSubmit == "Yes" && "YES"}

    //         {row?.isSubmit == false && "Mail Not send"}
    //       </>
    //     );
    //   },
    //   selector: (row) => row.isSubmit,
    //   sortable: true,
    // },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <ReactTooltip id="registerTip" place="top" effect="solid">
                  User profile
                </ReactTooltip>
                <h4
                  style={{ cursor: "pointer" }}
                  className="mb-0 mt-3 mr-4"
                  onClick={() => ProfileFuntions(row)}
                >
                  <i data-tip data-for="registerTip" class="fas fa-user"></i>
                </h4>
                <div
                  className="cursor-pointer mr-0 mt-3"
                  onClick={() => {
                    setUpdateProduct(false);
                    setUpdateDetails(true);
                    handleForUpdateManager(row._id);
                    setDescriptionUpdate(row.phone);
                    setRoleNameUpdate(row.name);
                    setUserEmail(row.email);
                    setRoleupdate(row.currentRole);
                  }}
                >
                  <CreateIcon />
                </div>
              </div>

              {row.status == false && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    onClick={() => {
                      handlealtermessage(row._id, true);
                    }}
                    className="btn btn-success"
                  >
                    <span
                      style={{
                        border: "none",
                        display: "flex",
                        whiteSpace: "nowrap",
                        // height: "35px",
                        // paddingLeft: "21px",
                        // paddingRight: "21px",
                      }}
                    >
                      Active
                    </span>
                  </button>
                </div>
              )}

              {row.status == true && (
                <div className="cursor-pointer ml-5 mt-1">
                  <button
                    className="btn-danger btn"
                    onClick={() => {
                      handlealtermessage(row._id, false);
                    }}
                    style={{
                      border: "none",
                      display: "flex",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Deactive
                  </button>
                </div>
              )}
            </div>
          </>
        );
      },
      wrap: true,

      sortable: true,
    },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const [createrData, setCreaterdata] = useState({});

  const handleChange = (e) => {
    let { name, value } = e.target;

    setCreaterdata((prevState) => ({
      ...prevState,

      [name]: value,
    }));

    setErrors({ ...errors, [name]: "" });
  };

  const formValidator = () => {
    let isValid = true;

    if (!createrData.name) {
      setErrors((cv) => {
        return { ...cv, name: "Please Enter  Name" };
      });
      isValid = false;
    }
    if (!createrData.email?.trim()) {
      setErrors((cv) => {
        return { ...cv, email: "* Please enter email" };
      });
      isValid = false;
    } else if (
      createrData.email?.trim() &&
      !createrData.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrors((cv) => {
        return { ...cv, email: "* Please enter valid email" };
      });
      isValid = false;
    }

    // if (!createrData.phone) {
    //   setErrors((cv) => {
    //     return { ...cv, phone: "Please Enter phonenumber" };
    //   });
    //   isValid = false;
    // }

    if (!createrData.password) {
      setErrors((cv) => {
        return { ...cv, password: "* Please enter password" };
      });
      isValid = false;
    } else if (
      !createrData.password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      )
    ) {
      isValid = false;
      setErrors((cv) => {
        return {
          ...cv,
          password:
            "Your password must have at least one capital letter,one small letter, one numeric character, one special character, and should be at least 8 characters long.",
        };
      });
    }

    return isValid;
  };

  const formUpdateValidator = () => {
    let isValid = true;

    if (!roleNameUpdate) {
      setErrors((cv) => {
        return { ...cv, name: "Please Enter  Name" };
      });
      isValid = false;
    }

    // if (!descriptionUpdate) {
    //   setErrors((cv) => {
    //     return { ...cv, phone: "Please Enter phonenumber" };
    //   });
    //   isValid = false;
    // }

    return isValid;
  };

  const submitDetails = async () => {
    if (!formValidator()) {
      console.log("ERROR POST", errors);
      return;
    }

    setLoading(true);

    console.log("createrData", createrData);

    await ApiPost(`user/registration`, createrData)
      .then(async (res) => {
        if (res.data && res.data.payload && res.data.payload) {
          setUpdateProduct(false);
          setLoading(false);
          setDataChange([...dataChange, "Details Added"]);
          setRoleName();
          setDescription();
          setCreaterdata({});
          setOpen(false);
          toast.success(res.data.message);
        } else {
          setMsg(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const handleSearch = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();
    setFilteredReport(() =>
      userReport.filter(
        (item) =>
          item?.name?.toLowerCase().includes(value) ||
          item?.email?.toLowerCase().includes(value)
        // item?.phone?.toLowerCase().includes(value)
      )
    );
  };

  return (
    <>
      <div>
        {/* <div className="card p-1">
          <div className="p-2 mb-2">
            <div class="p-2 d-flex bd-highlight">
              <div class="flex-grow-1 bd-highlight">
                <div className="pl-2 pt-2 headerTitle">User Reports</div>
              </div>
              <div class="bd-highlight col-4">
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="title"
                  placeholder="Search user"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
              <div class="bd-highlight">
                <button
                  className="btn btnColor"
                  onClick={() => {
                    setUpdateDetails(false);
                    handleClickOpen();
                  }}
                >
                  Add User
                </button>
              </div>
            </div>

            
          </div>
        </div> */}

        <div className="card p-1">
          <div className="p-2 mb-2">
            <div className="row mb-4 pr-3">
              <div className="col d-flex justify-content-between">
                <h2 className="pl-3 pt-2">Organization User details</h2>
              </div>
              <div className="col">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    placeholder="Search user"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              <div className="cus-medium-button-style button-height">
                <button
                  className="btn btnColor"
                  onClick={() => {
                    setUpdateDetails(false);
                    handleClickOpen();
                  }}
                >
                  Add User
                </button>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={filteredReport}
            pagination
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={
              <Loader type="Puff" color="#334D52" height={30} width={30} />
            }
          />
        </div>
      </div>
      <Modal
        show={show}
        style={{ zIndex: "999999", position: "absolute" }}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {status == true ? "Active" : "Deactive"} this
          User?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="danger" onClick={() => handelUpdatestatusDetails()}>
            {status == true && !loading ? "Active" : "Deactive"}
            {loading && <span className="mx-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          {updateProduct && updateProduct === true ? (
            <div className="form ml-30 ">
              {/* Account Number */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Full Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Full name"
                      className={`form-control form-control-lg form-control-solid `}
                      name="name"
                      value={roleName}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Email
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Email"
                      className={`form-control form-control-lg form-control-solid `}
                      name="email"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["email"]}
                  </span>
                </div>
              </div>

              {/* <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Phone number
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="number"
                      autoComplete="off"
                      placeholder="Phone Number"
                      className={`form-control form-control-lg form-control-solid `}
                      name="phone"

                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["phone"]}
                  </span>
                </div>
              </div> */}

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  password
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="password"
                      autoComplete="off"
                      placeholder="password"
                      className={`form-control form-control-lg form-control-solid `}
                      name="password"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["password"]}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    !loading && submitDetails(e);
                  }}
                  className="btn btnColor mr-2"
                >
                  <span>Submit Details</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          ) : null}
        </List>
        <List>
          {updateDetails && updateDetails === true ? (
            <div className="form ml-30 ">
              {/* First Name */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Full name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="name"
                      value={roleNameUpdate}
                      onChange={(e) => {
                        setRoleNameUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleNameUpdate: "" };
                        });
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Current Role
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      name="currentRole"
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        setRoleupdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleUpdate: "" };
                        });
                      }}
                    >
                      <option value={roleUpdate}>
                        {roleUpdate ? roleUpdate : "Select Organization Role"}
                      </option>
                      {organigationRoleList.map((item, index) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                    {/* <span style={{ color: "red" }}>
                      {verificationError["currentRole"]}
                    </span> */}
                    {/* <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="name"
                      value={roleUpdate}
                      onChange={(e) => {
                        setRoleupdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, roleUpdate: "" };
                        });
                      }}
                    /> */}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>
              {/* swift code */}
              {/* <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  phone
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="number"

                      min="10"
                      className={`form-control form-control-lg form-control-solid `}
                      name="phone"
                      value={descriptionUpdate}
                      onChange={(e) => {
                        setDescriptionUpdate(e.target.value);
                        setErrors((cv) => {
                          return { ...cv, descriptionUpdate: "" }
                        })
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["phone"]}
                  </span>
                </div>
              </div> */}

              <div className="d-flex align-items-center justify-content-center">
                {submitValue && submitValue === true ? (
                  <button
                    onClick={(e) => {
                      handelUpdateDetails();
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Update Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      submitDetails(e);
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Submit Details</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </List>
      </Dialog>
      {/* user Profile  */}
      {/* user Profile end */}
      <Dialog
        fullScreen
        open={profile}
        onClose={handleProfileClose}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleProfileClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          <Profile profile={profileData} />
        </List>
      </Dialog>
    </>
  );
};

export default OrganizationUser;
